import React, { useRef, useState } from "react";
import { Cookies } from "react-cookie";
import { Div, FlexRow, FlexColumn, Divider, Spacer } from "../../../core/Containers";
import { LBCModal } from "../../../components/Modals/LBCModal";
import { useLocation, useNavigate } from "react-router-dom";
import { AiOutlineDownload } from "react-icons/ai";
import { Button, RawButton, RawDropdown } from "../../../core/Buttons";
import { ColumnHeader } from "../../../components/Tables/ColumnHeader";
import {
  RecurrenceOptionsRemittance,
  FilterOptionsRemittance,
} from "../../../constants/Dropdowns";
import { Text } from "../../../core/Text";
import { ImSpinner2 } from "react-icons/im";
import moment from "moment";
import { Pagination } from "../../../components/Tables/Pagination";
import { RemittanceDetailsReportsDataTableRow } from "../../../components/Tables/RemittanceHistorySummary";

import { RemittanceCorpDataRow } from "../../../components/Tables/ReportsDataRow";
import { RemittanceCorpColumns } from "../../../constants/ConstantsData";
import { RemittanceCorpColumnsNew } from "../../../constants/ConstantsData";

import axiosCAPApi from "../../../api/axiosCAPApi";
import axiosCAPApiPrivate from "../../../api/axiosCAPApiPrivate";
import FileSaver from "file-saver";
import { sum } from "lodash";

const cookies = new Cookies();

export const Remittance = ({
  reportFilter,
  reportDateFrom,
  reportDateTo,
}) => {
  const navigate = useNavigate();
  const dataFetchedRef = useRef(false);
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const actionQuery = query.get("option");

  const [sortBy, setSort] = React.useState("SHIPMENTS BY LAST STATUS");
  const [filter, setFilter] = React.useState("Transaction Date");
  const [timeline, setTimeline] = React.useState("Daily");

  const [loading, setLoading] = React.useState(false);
  const [selectedList, setSelectedList] = React.useState([]);
  const [reportGenerated, setReportGenerated] = useState(false);

  const [currentPage, setCurrentPage] = React.useState(1);
  const [articlesPerPage] = React.useState(5);
  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const [remittanceCorpList, setRemittanceCorpList] = React.useState([]);
  const [remittanceCorpListFilter, setRemittanceCorpListFilter] =
    React.useState([]);
  const newList = remittanceCorpListFilter.slice(
    indexOfFirstArticle,
    indexOfLastArticle
  );

  const [shipperAcctName, setShipperAcctName] = React.useState("");
  const [shipperAcctNo, setShipperAcctNo] = React.useState("");

  //const [filter, setFilter] = React.useState([]);
  const [dateFrom, setDateFrom] = React.useState(
    moment(new Date()).format("yyyy-MM-DD")
  );
  const [dateTo, setDateTo] = React.useState(
    moment(new Date()).format("yyyy-MM-DD")
  );

  const [isToastOpen, setToastOpen] = React.useState(false);
  const [toastKind, setToastKind] = React.useState("");
  const [toastTitle, setToastTitle] = React.useState("");
  const [toastDesc, setToastDesc] = React.useState("");
  const [remittedStatusColumn, setRemittedStatusColumn] = React.useState();
  const [sortList, setSortList] = React.useState([]);
  const newItems = sortList.slice(indexOfFirstArticle, indexOfLastArticle);
  const [RemittedExcelList, setRemittedExcelList] = React.useState([]);
  const [UnRemittedExcelList, setUnRemittedExcelList] = React.useState([]);
  const [SummaryRemittanceReport, setSummaryRemittanceReport] = React.useState([]);

  React.useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    var _shipperAcct = cookies.get("shipperAcct", { path: "/" });
    var _shipperAcctSplit = _shipperAcct ? _shipperAcct.split("|") : [];
    var _shipperAcctName = _shipperAcctSplit[0]
      ? _shipperAcctSplit[0].toString().trim()
      : "";
    var _shipperAcctNo = _shipperAcctSplit[1]
      ? _shipperAcctSplit[1].toString().trim()
      : "";

    setShipperAcctName(_shipperAcctName);
    setShipperAcctNo(_shipperAcctNo);

    //setFilter(reportFilter);
    setDateFrom(
      reportDateFrom !== ""
        ? moment(reportDateFrom).format("yyyy-MM-DD")
        : moment(new Date()).format("yyyy-MM-DD")
    );
    setDateTo(
      reportDateTo !== ""
        ? moment(reportDateTo).format("yyyy-MM-DD")
        : moment(new Date()).format("yyyy-MM-DD")
    );

    if (reportDateFrom !== "" && reportDateTo !== "") {
      GenerateRemittanceReport(
        _shipperAcctNo,
        moment(reportDateFrom).format("yyyy-MM-DD"),
        moment(reportDateTo).format("yyyy-MM-DD")
      );
    }
  }, []);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // const getGetRemittanceCorpDaily = () => {
  //   setLoading(true);

  //   Auth.validateToken()
  //     .then(async (response) => {
  //       return await response.data;
  //     })
  //     .then((data) => {
  //       if (data.Code === "403") {
  //         Auth.refreshToken()
  //           .then(async (response) => {
  //             return await response.data;
  //           })
  //           .then(async (data) => {
  //             if (data.Code === "200") {
  //               const userInfo = cookies.get("userInfo");
  //               const newToken = data.Data;
  //               userInfo.token = newToken;
  //               cookies.set("userInfo", userInfo, { path: "/" });
  //               cookies.set("accessToken", newToken, { path: "/" });

  //               procGetRemittanceCorpDaily();
  //             } else {
  //               setToastKind("error");
  //               setToastTitle("Remittance Status Failed!");
  //               setToastDesc(data.message);
  //               setTimeout(() => {
  //                 setLoading(false);
  //               }, 1000);
  //               setTimeout(() => {
  //                 setToastOpen(true);
  //               }, 1500);
  //               setTimeout(() => {
  //                 setToastOpen(false);
  //               }, 3000);
  //             }
  //           })
  //           .catch((error) => {
  //             setToastKind("error");
  //             setToastTitle("Remittance Status Failed!");
  //             setToastDesc(error.message);
  //             setTimeout(() => {
  //               setLoading(false);
  //             }, 1000);
  //             setTimeout(() => {
  //               setToastOpen(true);
  //             }, 1500);
  //             setTimeout(() => {
  //               setToastOpen(false);
  //             }, 3000);
  //           });
  //       } else {
  //         procGetRemittanceCorpDaily();
  //       }
  //     })
  //     .catch((error) => {
  //       setToastKind("error");
  //       setToastTitle("Remittance Status Failed!");
  //       setToastDesc(error.message);
  //       setTimeout(() => {
  //         setLoading(false);
  //       }, 1000);
  //       setTimeout(() => {
  //         setToastOpen(true);
  //       }, 1500);
  //       setTimeout(() => {
  //         setToastOpen(false);
  //       }, 3000);
  //     });
  // };

  // Get Export Data for Remittance Data
  const GetRemittanceData = async () => {
    const status = "Remitted";

    // Validate inputs
    if (!status || !shipperAcctNo || !dateFrom || !dateTo) {
      console.error("Required fields are missing.");
      setLoading(false);
      return;
    }

    try {
      const dataRes = await axiosCAPApiPrivate.post("/api/GetRemittanceNewReportDetail", {
        CustomerAccountNo: shipperAcctNo,
        Column: status,
        FromDate: dateFrom,
        ToDate: dateTo,
      });

      const data = dataRes.data;

      if (data.Code === "200") {
        console.log("Remittance Data: ", data.Data);
        setRemittedExcelList(data.Data);
      } else {
        console.error("Error: ", data.Message);
      }
    } catch (error) {
      console.error("Request failed: ", error);
    } finally {
        setLoading(false);
    }
  };

  // Get Export Data for UnRemittance Data
  const GetUnRemittanceData = async () => {
    const status = "UnRemitted";

    // Validate inputs
    if (!status || !shipperAcctNo || !dateFrom || !dateTo) {
      console.error("Required fields are missing.");
      setLoading(false);
      return;
    }

    try {
      const dataRes = await axiosCAPApiPrivate.post("/api/GetRemittanceNewReportDetail", {
        CustomerAccountNo: shipperAcctNo,
        Column: status,
        FromDate: dateFrom,
        ToDate: dateTo,
      });

      const data = dataRes.data;
      if (data.Code === "200") {
        console.log("UnRemittance: ", data.Data); 
        setUnRemittedExcelList(data.Data);           
      } else {
        console.error("Error: ", data.Message);
      }
    } catch (error) {
      console.error("Request failed: ", error);
    } finally {
        setLoading(false);
    }
  };

  const GetSummaryRemittanceReport = async () => {
    try {
      const response = await axiosCAPApiPrivate.post("/api/GetRemittanceCorpDaily", {
        CustomerAccountNo: shipperAcctNo,
        FromDate: dateFrom,
        ToDate: dateTo,
      });

      const data = response.data;

      if (data.Code === "200") {
        setLoading(false);
        // setRemittedExcelList(data.Data);
      }
      setLoading(false);
    } catch (error) {
      setToastKind("error");
      setToastTitle("Remittance Status Failed!");
      setToastDesc(error.message);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setTimeout(() => {
        setToastOpen(true);
      }, 1500);
      setTimeout(() => {
        setToastOpen(false);
      }, 3000);
    }
  };

  // New Code Gilbert
  const getRemittedReport = async (column) => {
    setRemittedStatusColumn(column);
    setLoading(true);
    

    const statusMap = {
      "Remitted": "Remitted",
      "UnRemitted": "UnRemitted",
      "TotalData": "TotalData",
    };

    // const status = "Remitted";

    const status = statusMap[column] || "";

    // Validate inputs
    if (!status || !shipperAcctNo || !dateFrom || !dateTo) {
      console.error("Required fields are missing.");
      setLoading(false);
      return;
    }

    try {
      const dataRes = await axiosCAPApiPrivate.post("/api/GetRemittanceNewReportDetail", {
          CustomerAccountNo: shipperAcctNo,
          Column: status,
          FromDate: dateFrom,
          ToDate: dateTo,
      });
      console.log(dataRes);

      const data = dataRes.data;
      if (data.Code === "200") {
        setSortList(data.Data);
        setRemittanceCorpListFilter(data.Data);
        console.log(data.Data);            
      } else {
        console.error("Error: ", data.Message);
      }
    } catch (error) {
        console.error("Request failed: ", error);
    } finally {
        setLoading(false);
    }
  };

  const onDispoChange = (tracking, value) => {
    const newSortList = sortList.map((item) => {
      if (item.tracking === tracking) {
        return { ...item, disposition: value };
      }
      return item;
    });
  };

  const getGetRemittanceCorpDaily = async (shipperAcctNo, dateFrom, dateTo) => {
    setReportGenerated(false);
    setLoading(true);

    GetRemittanceData();
    GetUnRemittanceData();

    try {
      const response = await axiosCAPApiPrivate.post("/api/GetRemittanceCorpDaily", {
        CustomerAccountNo: shipperAcctNo,
        FromDate: dateFrom,
        ToDate: dateTo,
      });

      setReportGenerated(true);
      console.log("Report generated state:", reportGenerated);

      const data = response.data;

      if (data.Code === "200") {
        setLoading(false);
        
        setRemittanceCorpList(data.Data);
        setRemittanceCorpListFilter(data.Data);
        GetSummaryRemittanceReport(data.Data);
        setSummaryRemittanceReport(data.Data);
        console.log("API Response: ", data.Data);
        console.log("Data Summary: ", data.Data);
        // DITO LAGYAN!!!
        // setRemittanceExcelList(data.Data);
        // setReportGenerated(true);
      }
      setLoading(false);
    } catch (error) {
      setToastKind("error");
      setToastTitle("Remittance Status Failed!");
      setToastDesc(error.message);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setTimeout(() => {
        setToastOpen(true);
      }, 1500);
      setTimeout(() => {
        setToastOpen(false);
      }, 3000);
    }
  };

  // const getRemittanceCorpSummaryForThreeMonths = () => {
  //   setLoading(true);

  //   Auth.validateToken()
  //     .then(async (response) => {
  //       return await response.data;
  //     })
  //     .then((data) => {
  //       if (data.Code === "403") {
  //         Auth.refreshToken()
  //           .then(async (response) => {
  //             return await response.data;
  //           })
  //           .then(async (data) => {
  //             if (data.Code === "200") {
  //               const userInfo = cookies.get("userInfo");
  //               const newToken = data.Data;
  //               userInfo.token = newToken;
  //               cookies.set("userInfo", userInfo, { path: "/" });
  //               cookies.set("accessToken", newToken, { path: "/" });

  //               procGetRemittanceCorpSummaryForThreeMonths();
  //             } else {
  //               setToastKind("error");
  //               setToastTitle("Remittance Status Failed!");
  //               setToastDesc(data.message);
  //               setTimeout(() => {
  //                 setLoading(false);
  //               }, 1000);
  //               setTimeout(() => {
  //                 setToastOpen(true);
  //               }, 1500);
  //               setTimeout(() => {
  //                 setToastOpen(false);
  //               }, 3000);
  //             }
  //           })
  //           .catch((error) => {
  //             setToastKind("error");
  //             setToastTitle("Remittance Status Failed!");
  //             setToastDesc(error.message);
  //             setTimeout(() => {
  //               setLoading(false);
  //             }, 1000);
  //             setTimeout(() => {
  //               setToastOpen(true);
  //             }, 1500);
  //             setTimeout(() => {
  //               setToastOpen(false);
  //             }, 3000);
  //           });
  //       } else {
  //         procGetRemittanceCorpSummaryForThreeMonths();
  //       }
  //     })
  //     .catch((error) => {
  //       setToastKind("error");
  //       setToastTitle("Remittance Status Failed!");
  //       setToastDesc(error.message);
  //       setTimeout(() => {
  //         setLoading(false);
  //       }, 1000);
  //       setTimeout(() => {
  //         setToastOpen(true);
  //       }, 1500);
  //       setTimeout(() => {
  //         setToastOpen(false);
  //       }, 3000);
  //     });
  // };

  const getRemittanceCorpSummaryForThreeMonths = async () => {
    setLoading(true);

    try {
      const response = await axiosCAPApiPrivate.post(
        "/api/GetRemittanceCorpSummaryForThreeMonths",
        {
          CustomerAccountNo: shipperAcctNo,
          ReportRange: timeline,
        }
      );

      const data = response.data;

      if (data.Code === "200") {
        setLoading(false);
        setRemittanceCorpList(data.Data);
        setRemittanceCorpListFilter(data.Data);
        // setRemittanceExcelList(data.Data);
        console.log("API Response Three Month:", data.Data);
      }
      setLoading(false);
    } catch (error) {
      setToastKind("error");
      setToastTitle("Remittance Status Failed!");
      setToastDesc(error.message);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setTimeout(() => {
        setToastOpen(true);
      }, 1500);
      setTimeout(() => {
        setToastOpen(false);
      }, 3000);
    }
  };

  const GenerateRemittanceReport = async (shipperAcctNo, dateFrom, dateTo) => {

    try {
      if (timeline === "Monthly") getRemittanceCorpSummaryForThreeMonths();
      else if (timeline === "Daily") {
        getGetRemittanceCorpDaily(shipperAcctNo, dateFrom, dateTo);
        // GetRemittanceData();
        // GetUnRemittanceData();
      }

      await Promise.all([
        GetRemittanceData(),
        GetUnRemittanceData(),
      ]);

    } catch (error) {
      console.error(error);
    } 
  };

  const prepareSummaryData = (data) => {
    // Prepare the summary data here. This is just a sample structure.
    return data.map(item => ({
        date: item.DateText,
        remittance: item.RemittanceAmount || 0, // Assuming these fields exist
        unremittance: item.UnRemittanceAmount || 0 // Assuming these fields exist
    }));
};

  // New Code Gilbert Generate Reports
  const exportToCSV = () => {
    const Excel = require("exceljs");
    const workbook = new Excel.Workbook();

    const summaryReport = workbook.addWorksheet("Summary");
    summaryReport.insertRow(1, "");
    summaryReport.getCell("A1").value = "Remittance Report Status";
    summaryReport.getCell("B1").value = "Start Date";
    summaryReport.getCell("C1").value = "End Date";
    summaryReport.insertRow(2, "");
    summaryReport.getCell("A2").value = "Coverage";
    summaryReport.getCell("B2").value = `${dateFrom}`;
    summaryReport.getCell("C2").value = `${dateTo}`;

    const dateTexts = SummaryRemittanceReport.map(item => item.DateText);
    const remittedTotal = SummaryRemittanceReport.map(item => item.Remitted);
    const unremittedTotal = SummaryRemittanceReport.map(item => item.UnRemitted);
    const totalDelivered = SummaryRemittanceReport.map(item => item.Delivered);

    summaryReport.insertRow(4, "");
    summaryReport.getCell("A4").value = "Status";
    summaryReport.getCell("A4").font = { bold: true };
    summaryReport.insertRow(5, "");
    summaryReport.getCell("B4").value = "Remitted";
    summaryReport.getCell("C4").value = "UnRemitted";
    summaryReport.getCell("D4").value = "Total Delivered";

    dateTexts.forEach((dateText, index) => {
      summaryReport.insertRow(5 + index, [
        dateText,
        remittedTotal[index],
        unremittedTotal[index],
        totalDelivered[index]
      ]);
    });

    // Calculate total sums
    const totalRemitted = remittedTotal.reduce((acc, val) => acc + val, 0);
    const totalUnremitted = unremittedTotal.reduce((acc, val) => acc + val, 0);
    const totalDeliveredSum = totalDelivered.reduce((acc, val) => acc + val, 0);

    // Insert totals row
    const totalRowIndex = 6 + dateTexts.length; // Index for the total row
    summaryReport.insertRow(totalRowIndex, [
        "Total", // Label for the totals row
        totalRemitted,
        totalUnremitted,
        totalDeliveredSum
    ]);

    summaryReport.getCell(`A${totalRowIndex + 1}`).font = { bold: true };

    // Center the numeric values in the totals row
    summaryReport.getCell(`B${totalRowIndex + 1}`).alignment = { horizontal: 'center' };
    summaryReport.getCell(`C${totalRowIndex + 1}`).alignment = { horizontal: 'center' };
    summaryReport.getCell(`D${totalRowIndex + 1}`).alignment = { horizontal: 'center' };

    // Set Columns widths
    summaryReport.columns = [
      { width: 30 },
      { width: 20 },
      { width: 20 },
      { width: 20 }
    ];

    const remittedReport = workbook.addWorksheet("Remitted");
    remittedReport.columns = [
      { header: "Tracking No.", key: "tracking", width: 15 },
      { header: "Transaction Date", key: "encodedDate", width: 20 },
      { header: "Consignee Name", key: "consigneeName", width: 25 },
      { header: "Consignee Mobile", key: "consigneeContact", width: 30 },
      { header: "Consignee Address", key: "consigneeAddress", width: 50 },
      { header: "Last Status", key: "status", width: 30 },
      { header: "Last Status Date", key: "statusDate", width: 30 },
      { header: "Remitted Date", key: "acceptanceDate", width: 20 },
      { header: "Item Value", key: "itemValue", width: 20 },
      { header: "COD/COP Amount", key: "codCopAmount", width: 20 },
      { header: "Freight Amount", key: "freightAmount", width: 20 },
      { header: "Disbursement Mode", key: "disbursementMode", width: 25 },
      { header: "Remarks", key: "Remarks", width: 40 }
    ];

    remittedReport.insertRow(1, "");
    remittedReport.insertRow(2, "");
    remittedReport.getCell("A2").value = "REMITTANCE REPORT";
    remittedReport.insertRow(3, "");
    remittedReport.mergeCells("A2:B2");
    remittedReport.getRow(4).font = { bold: true };
    addBorder(remittedReport, 4);
    
    var row = 5;
    RemittedExcelList.map((list) => {
      remittedReport.addRow({
        tracking: list.tracking,
        encodedDate: list.encodedDate,
        consigneeName: list.consigneeName,
        consigneeContact: list.consigneeContact,
        consigneeAddress: list.consigneeAddress,
        status: list.status,
        statusDate: list.statusDate,
        acceptanceDate: list.acceptanceDate,
        itemValue: list.DeclareValue,
        codCopAmount: list.collectedAmount,
        freightAmount: list.TotalFreight,
        disbursementMode: list.disbursementMode,
        Remarks: list.Remarks
      });
      addBorder(remittedReport, row);
      row++;
    });

    const unremittedReport = workbook.addWorksheet("UnRemitted");
    unremittedReport.columns = remittedReport.columns;
    unremittedReport.insertRow(1, "");
    unremittedReport.insertRow(2, "");
    unremittedReport.getCell("A2").value = "UNREMITTANCE REPORT";
    unremittedReport.insertRow(3, "");
    unremittedReport.mergeCells("A2:B2");
    unremittedReport.getRow(4).font = { bold: true };
    addBorder(unremittedReport, 4);
    
    var row = 5;
    UnRemittedExcelList.map((list) => {
      unremittedReport.addRow({
        tracking: list.tracking,
        encodedDate: list.encodedDate,
        consigneeName: list.consigneeName,
        consigneeContact: list.consigneeContact,
        consigneeAddress: list.consigneeAddress,
        status: list.status,
        statusDate: list.statusDate,
        acceptanceDate: list.acceptanceDate,
        itemValue: list.DeclareValue,
        codCopAmount: list.collectedAmount,
        freightAmount: list.TotalFreight,
        disbursementMode: list.disbursementMode,
        Remarks: list.Remarks,
      });
      addBorder(unremittedReport, row);
      row++;
    });

    workbook.xlsx
      .writeBuffer()
      .then((buffer) =>
        FileSaver.saveAs(
          new Blob([buffer]),
          `Remittance Report.xlsx`
        )
      )
      .catch((err) => console.log("Error writing excel export", err));
  };

  const addBorder = (sheet, row) => {
    sheet.getCell("A" + row).border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
    sheet.getCell("B" + row).border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
    sheet.getCell("C" + row).border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
    sheet.getCell("D" + row).border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
    sheet.getCell("E" + row).border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
    sheet.getCell("F" + row).border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
    sheet.getCell("G" + row).border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
    sheet.getCell("H" + row).border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
    sheet.getCell("I" + row).border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
    sheet.getCell("J" + row).border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
    sheet.getCell("K" + row).border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
    sheet.getCell("L" + row).border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
    sheet.getCell("M" + row).border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
  };

  React.useEffect(() => {
    console.log("Updated RemittedExcelList:", RemittedExcelList);
  }, [RemittedExcelList]);

  React.useEffect(() => {
    console.log("Updated UnRemittedExcelList:", UnRemittedExcelList);
  }, [UnRemittedExcelList]);

  React.useEffect(() => {
    if (filter === "Transaction Date")
      setRemittanceCorpListFilter(remittanceCorpList);
    else if (filter === "Delivered Date")
      setRemittanceCorpListFilter(
        remittanceCorpListFilter.filter((x) => x.Delivered > 0).map((s) => s)
      );
    else if (filter === "Remitted Date")
      setRemittanceCorpListFilter(
        remittanceCorpListFilter.filter((x) => x.Remitted > 0).map((s) => s)
      );
  }, [filter]);

  React.useEffect(() => {}, [timeline]);

  console.log(newItems);

  return (
    <>
      <LBCModal
        description={toastDesc}
        isOpen={isToastOpen}
        onClose={() => setToastOpen(false)}
        title={toastTitle}
        toastKind={toastKind}
      />

      <Div className="w-full">
        <Spacer className="w-10 h-10" />
        <Div className="w-full border border-grey-400 rounded-lg shadow-lg">
          <FlexRow className="w-full items-center justify-between py-5">
            <FlexRow className="items-center">
              <Div className="w-48 mx-2">
                <RawDropdown
                  icon={<div />}
                  width="w-full"
                  border="border phone:border-grey-100"
                  value={filter}
                  options={FilterOptionsRemittance}
                  onSelect={setFilter}
                />
              </Div>
              {timeline === "Daily" ? (
                <FlexRow>
                  <input
                    className="text-sm w-40 mx-2 block rounded-lg"
                    type="date"
                    value={dateFrom}
                    onChange={(e) => setDateFrom(e.target.value)}
                  />
                  <input
                    className="text-sm w-40 mx-2 block rounded-lg"
                    type="date"
                    value={dateTo}
                    onChange={(e) => setDateTo(e.target.value)}
                  />
                </FlexRow>
              ) : (
                <></>
              )}

              <Button
                className="w-40 ml-2 h-10"
                isLoading={loading}
                onClick={() =>
                  GenerateRemittanceReport(shipperAcctNo, dateFrom, dateTo)
                }
              >
                Generate
              </Button>
              
              {reportGenerated && (
                <RawButton onClick={() => exportToCSV()}>
                  <AiOutlineDownload className="text-secondary-200 text-xl" />
                </RawButton>
              )}
            </FlexRow>
          </FlexRow>
          <Spacer className="w-10 h-10" />

          {/* <FlexRow className="flex flex-row w-3/4 items-center justify-between py-2 border border-grey-400"> */}
          <FlexRow className="flex flex-row w-full items-center justify-center py-2 border border-grey-400">
          {/* <FlexRow className="w-full items-center justify-between py-2 border border-grey-400"> */}
            <ColumnHeader
                title={
                    "REMITTED " +
                    remittanceCorpList
                    .reduce((n, { Remitted }) => n + Remitted, 0)
                    .toLocaleString()
                }
                onClick={() => getRemittedReport("Remitted")}
                containerClass="hover:bg-grey-400 rounded-full w-200px px-4 py-2"
                titleClassName=""
            />
            <ColumnHeader
                title={
                    "UNREMITTED " +
                    remittanceCorpList
                    .reduce((n, { UnRemitted }) => n + UnRemitted, 0)
                    .toLocaleString()
                }
                onClick={() => getRemittedReport("UnRemitted")}
                containerClass="hover:bg-grey-400 rounded-full w-200px px-4 py-2"
                titleClassName=""
            />
            <ColumnHeader
                title={
                    "TOTAL " +
                    remittanceCorpList
                    .reduce((n, { Delivered }) => n + Delivered, 0)
                    .toLocaleString()
                }
                containerClass="hover:bg-grey-400 rounded-full w-200px px-4 py-2"
                onClick={() => getRemittedReport("TotalData")}
                titleClassName=""
            />
          </FlexRow>

          {/* <FlexRow className="w-full items-center justify-between py-2 border border-grey-400">
            {RemittanceCorpColumnsNew.map((column) => (
              <Text
                className={`text-secondary-100 text-sm text-center font-semibold px-4 py-2 ${"w-1/4"}`}
              >
                {column}
              </Text>
            ))}
          </FlexRow> */}
          
          <Spacer className="w-full h-10" />
          {/* <FlexColumn className="w-full"> */}
            {/* <Div className="w-full overflow-x-scroll border border-grey-400 rounded-lg shadow-lg my-5"> */}
            <Div className="w-full border border-grey-400 rounded-lg shadow-lg overflow-x-scroll my-5">
              <FlexRow className="items-center justify-between w-3000px">
                {/* <ColumnHeader title="Tracking No." containerClass="w-300px px-4 py-2 text-center" /> */}
                <ColumnHeader
                  icon={false}
                  title="Tracking No."
                  containerClass="w-200px px-4 py-2"
                  titleClassName=""
                />
                {/* <ColumnHeader title="Transaction Date" containerClass="w-300px px-4 py-2 text-center" /> */}
                <ColumnHeader
                  icon={false}
                  title="Transaction Date"
                  containerClass="w-200px px-4 py-2"
                  titleClassName=""
                />
                {/* <ColumnHeader title="Consignee Name" containerClass="w-300px px-4 py-2 text-center" /> */}
                <ColumnHeader
                  icon={false}
                  title="Consignee Name"
                  containerClass="w-250px px-4 py-2"
                  titleClassName=""
                />
                {/* <ColumnHeader title="Consignee Mobile No." containerClass="w-200px px-4 py-2 text-center" /> */}
                <ColumnHeader
                  icon={false}
                  title="Consignee Mobile No."
                  containerClass="w-300px px-4 py-2"
                  titleClassName=""
                />
                {/* <ColumnHeader title="Consignee Address" containerClass="w-400px px-4 py-2 text-center" /> */}
                <ColumnHeader
                  icon={false}
                  title="Consignee Address"
                  containerClass="w-300px px-4 py-2"
                  titleClassName=""
                />
                {/* <ColumnHeader title="Last Status" containerClass="w-200px px-4 py-2 text-center" /> */}
                <ColumnHeader
                  icon={false}
                  title="Last Status"
                  containerClass="w-300px px-4 py-2"
                  titleClassName=""
                />
                {/* <ColumnHeader title="Last Status Date" containerClass="w-300px px-4 py-2 text-center" /> */}
                <ColumnHeader
                  icon={false}
                  title="Last Status Date"
                  containerClass="w-200px px-4 py-2"
                  titleClassName=""
                />
                {/* <ColumnHeader title="Remitted Date" containerClass="w-300px px-4 py-2 text-center" /> */}
                <ColumnHeader
                  icon={false}
                  title="Remitted Date"
                  containerClass="w-200px px-4 py-2"
                  titleClassName=""
                />
                {/* <ColumnHeader title="Item Value" containerClass="w-200px px-4 py-2 text-center" /> */}
                <ColumnHeader
                  icon={false}
                  title="Item Value"
                  containerClass="w-200px px-4 py-2"
                  titleClassName=""
                />
                {/* <ColumnHeader title="COD/COP Amount" containerClass="w-200px px-4 py-2 text-center" /> */}
                <ColumnHeader
                  icon={false}
                  title="COD/COP Amount"
                  containerClass="w-200px px-4 py-2"
                  titleClassName=""
                />
                {/* <ColumnHeader title="Freight Amount" containerClass="w-200px px-4 py-2 text-center" /> */}
                <ColumnHeader
                  icon={false}
                  title="Freight Amount"
                  containerClass="w-200px px-4 py-2"
                  titleClassName=""
                />
                {/* <ColumnHeader title="Disbursement Mode" containerClass="w-200px px-4 py-2 text-center" /> */}
                <ColumnHeader
                  icon={false}
                  title="Disbursement Mode"
                  containerClass="w-200px px-4 py-2"
                  titleClassName=""
                />
                {/* <ColumnHeader title="Remarks" containerClass="w-400px px-4 py-2 text-center" /> */}
                <ColumnHeader
                  icon={false}
                  title="Remarks"
                  containerClass="w-200px px-4 py-2"
                  titleClassName=""
                />
              </FlexRow>
              {/* <FlexRow className="items-center justify-between w-3000px">
                <ColumnHeader
                  title="Tracking No."
                  containerClass="w-300px px-4 py-2"
                  titleClassName=""
                />
                <ColumnHeader
                  title="Transaction Date"
                  containerClass="w-300px px-4 py-2"
                  titleClassName=""
                />
                <ColumnHeader
                  title="Consignee Name"
                  containerClass="w-300px px-4 py-2"
                  titleClassName=""
                />
                <ColumnHeader
                  title="Consignee Mobile No."
                  containerClass="w-300px px-4 py-2"
                  titleClassName=""
                />
                <Div className="w-200">
                  <ColumnHeader
                    title="Consignee Address"
                    containerClass="w-400px px-4 py-2"
                    titleClassName=""
                  />
                </Div>
                <ColumnHeader
                  title="Last Status"
                  containerClass="w-200px px-4 py-2"
                  titleClassName=""
                />
                <ColumnHeader
                  title="Last Status Date"
                  containerClass="w-300px px-4 py-2"
                  titleClassName=""
                />
                <ColumnHeader
                  title="Remitted Date"
                  containerClass="w-300px px-4 py-2"
                  titleClassName=""
                />
                <ColumnHeader
                  title="Item Value"
                  containerClass="w-200px px-4 py-2"
                  titleClassName=""
                />
                <ColumnHeader
                  title="COD/COP Amount"
                  containerClass="w-200px px-4 py-2"
                  titleClassName=""
                />
                <ColumnHeader
                  title="Freight Amount"
                  containerClass="w-200px px-4 py-2"
                  titleClassName=""
                />
                <ColumnHeader
                  title="Disbursement Mode"
                  containerClass="w-200px px-4 py-2"
                  titleClassName=""
                />
                <ColumnHeader
                  title="Remarks"
                  containerClass="w-400px px-4 py-2"
                  titleClassName=""
                />
              </FlexRow> */}
                <Divider className="text-grey-100 my-2 w-3000px" />
                <LBCModal
                    description={toastDesc}
                    isOpen={isToastOpen}
                    onClose={() => setToastOpen(false)}
                    title={toastTitle}
                    toastKind={toastKind}
                />
                {loading ? (
                  <Text className="text-red-400 text-center flex flex-row justify-center items-center my-20">
                    <ImSpinner2 className="animate-spin mr-2 text-2xl desktop:text-3xl" />
                      Loading data ...
                  </Text>
                ) : (
                  <>
                    {newItems.map((list) => (
                      <RemittanceDetailsReportsDataTableRow
                        data={list}
                        status={remittedStatusColumn}
                        onDispoChange={onDispoChange}
                      />
                    ))}
                  </>
                )}
                <FlexRow className="items-center justify-between w-2800px py-2 hover:bg-grey-400">
                  <Text className="text-secondary-100 text-sm w-200px px-4">
                    {}
                  </Text>
                  <Text className="text-secondary-100 text-sm w-200px px-4">
                    {}
                  </Text>
                  <Text className="text-secondary-100 text-sm w-300px px-4">
                    {}
                  </Text>
                  <Text className="text-secondary-100 text-sm w-200px px-4">
                    {}
                  </Text>
                  <Text className="text-secondary-100 text-sm w-300px px-4">
                    {}
                  </Text>
                  <Text className="text-secondary-100 text-sm w-300px px-4">
                    {}
                  </Text>
                  <Text className="text-secondary-100 text-sm w-700px px-4">
                    {}
                  </Text>
                  <Text className="text-red-400 font-bold text-sm w-200px px-4">
                    Total
                  </Text>
                  <Text className="text-red-400 font-bold text-sm w-200px px-4">
                    PHP{" "}
                    {newItems
                      .reduce(
                        (n, { DeclareValue }) =>
                          n + parseFloat(DeclareValue),
                        0
                      )
                      .toLocaleString()}
                  </Text>
                  <Text className="text-red-400 font-bold text-sm w-200px px-4">
                    PHP{" "}
                    {newItems
                      .reduce(
                        (n, { collectedAmount }) =>
                          n + parseFloat(collectedAmount),
                        0
                      )
                      .toLocaleString()}
                  </Text>
                  <Text className="text-red-400 font-bold text-sm w-200px px-4">
                    PHP{" "}
                    {newItems
                      .reduce(
                        (n, { TotalFreight }) =>
                          n + parseFloat(TotalFreight),
                        0
                      )
                      .toLocaleString()}
                  </Text>
                  <Text className="text-secondary-100 text-sm w-150px px-4">
                    {}
                  </Text>
                  <Text className="text-secondary-100 text-sm w-300px px-4">
                    {}
                  </Text>
                </FlexRow>
                
            </Div>
          {/* </FlexColumn> */}
          {/* {loading ? (
            <Text className="text-red-400 text-center flex flex-row justify-center items-center my-10">
              <ImSpinner2 className="animate-spin mr-2 text-2xl desktop:text-3xl" />
            </Text>
          ) : (
            <Div className="w-full">
              {newList.map((item, key) => (
                <RemittanceCorpDataRow data={item} key={key} />
              ))}
            </Div>
          )} */}
        </Div>
        <Text className="text-red-400 text-xs font-bold my-2">
              {`Disclaimer: Coverage is Twelve (12) months from encoded date.`}
            </Text>
        <Spacer className="w-full h-10" />
        <Div className="w-full">
          <Pagination
            currentPage={currentPage}
            itemsPerPage={articlesPerPage}
            paginate={paginate}
            totalItems={remittanceCorpListFilter.length}
          />
        </Div>
      </Div>
    </>
  );
};
