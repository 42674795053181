import React, { useRef } from "react";
import { Cookies } from "react-cookie";
import {
  Div,
  Divider,
  FlexColumn,
  FlexRow,
  Spacer,
} from "../../../core/Containers";
import { Image } from "../../../core/Image";
import { Span, Text } from "../../../core/Text";
import { ColumnHeader } from "../../../components/Tables";
import { Button, RawButton, RawDropdown } from "../../../core/Buttons";
import { ImUpload3, ImInfo, ImSpinner2 } from "react-icons/im";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BookNowDataRow } from "../../../components/Tables/BookNowDataRow";
import { BookNowSummaryColumn } from "../../../constants/ConstantsData";
import {
  BookNowDataEditRow,
  BookNowDataSummaryRow,
} from "../../../components/Tables/BookNowSummaryRow";
import { CheckBox, FileInput } from "../../../core/Forms";
import { BookNowModal } from "../../../components/Modals/BookNowModal";
import { BookNowModalCams } from "../../../components/Modals/BookNowModalCams";
import { BookNowModalNew } from "../../../components/Modals/BookNowModalNew";
import { HiCheckCircle } from "react-icons/hi";
import { FaTimes, FaTimesCircle } from "react-icons/fa";
import { Images } from "../../../assets/images/images";
import { Loading } from "../../Window/Loading";
import { LBCModal, LBCModalTwoFunc } from "../../../components/Modals/LBCModal";
import moment from "moment";
import Barcode from "react-barcode";
import QRCode from "qrcode.react";
import { getPayCollectOptn } from "../../../helpers/common";
import { useDropzone } from "react-dropzone";
import axiosCAPApiPrivate from "../../../api/axiosCAPApiPrivate";
import axiosLexaApiPrivate from "../../../api/axiosLexaApiPrivate";

const cookies = new Cookies();
const numbersRegx = /^[0-9]*$/; // for Mobile Number
const mobilenumberRegex = /^(9)\d{9}$/; // mobile number validation regex
const emailRegex =
  /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // email validation regex

export const BookNowTab = ({
  selectedTab,
  setShowConfirmLeave,
  blkSessionId,
}) => {
  const navigate = useNavigate();
  const dataFetchedRef = useRef(false);
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const actionQuery = query.get("booking");

  const [bookType, setBookType] = React.useState("default");
  const [bookAdded, setBookAdded] = React.useState(false);

  const [loading, setLoading] = React.useState(false);
  const [loading2, setLoading2] = React.useState(false);
  const [isToastOpenA, setToastOpenA] = React.useState(false);
  const [isToastOpenB, setToastOpenB] = React.useState(false);

  const inputFileRef = React.useRef(null);
  const [file, setFile] = React.useState([{ name: "browse", size: 0 }]);
  const [prevFile, setPrevFile] = React.useState([{ name: "browse", size: 0 }]);
  const [uploaded, setUpload] = React.useState(false);
  const [uploadFile, setUploadFile] = React.useState("");

  const [isToastOpen, setToastOpen] = React.useState(false);
  const [toastKind, setToastKind] = React.useState("");
  const [toastTitle, setToastTitle] = React.useState("");
  const [toastDesc, setToastDesc] = React.useState("");

  const [isToastOpen1, setToastOpen1] = React.useState(false);

  const [isToastOpen2, setToastOpen2] = React.useState(false);
  const [toastKind2, setToastKind2] = React.useState("");
  const [toastTitle2, setToastTitle2] = React.useState("");
  const [toastDesc2, setToastDesc2] = React.useState("");

  const [isToastOpen3, setToastOpen3] = React.useState(false);
  const [toastTitle3, setToastTitle3] = React.useState("");
  const [toastDesc3, setToastDesc3] = React.useState("");

  const [bulkSessionId, setBulkSessionId] = React.useState("");

  const [shipperAcctName, setShipperAcctName] = React.useState("");
  const [shipperAcctNo, setShipperAcctNo] = React.useState("");

  const [bulkDetailsData, setBulkDetailsData] = React.useState([]);
  const [validData, setValidData] = React.useState(true);
  const [updatedItemId, setUpdatedItemId] = React.useState(0);
  const [selectedItem, setSelectedItem] = React.useState(null);

  const [agreementAccepted, setAgreementAccepted] = React.useState(false);

  const [selected, setSelected] = React.useState("default");
  const [selectedList, setSelectedList] = React.useState([]);

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    multiple: false,
    accept: ".xlsx",
    onDrop: (acceptedFiles) => setFile(acceptedFiles),
  });

  const userInfo = cookies.get("userInfo", { path: "/" });
  var getBooleanCams = cookies.get("userInfo", { path: "/" });
  const isCams = getBooleanCams.isCAMS;

  React.useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    // const _bulkSessionId = '6184708570';
    const _bulkSessionId =
      blkSessionId && blkSessionId !== ""
        ? blkSessionId
        : Math.floor(1000000000 + Math.random() * 9000000000);
    setBulkSessionId(_bulkSessionId);

    var _shipperAcct = cookies.get("shipperAcct", { path: "/" });
    var _shipperAcctSplit = _shipperAcct ? _shipperAcct.split("|") : [];
    var _shipperAcctName = _shipperAcctSplit[0]
      ? _shipperAcctSplit[0].toString().trim()
      : "";
    var _shipperAcctNo = _shipperAcctSplit[1]
      ? _shipperAcctSplit[1].toString().trim()
      : "";

    setShipperAcctName(_shipperAcctName);
    setShipperAcctNo(_shipperAcctNo);

    if (selectedTab === 0) {
      getBulkDetails(_shipperAcctNo, _bulkSessionId);
    }
  }, []);

  React.useEffect(() => {
    if (actionQuery === "added") {
      setBookAdded(true);
      setBookType("summary");
    }
  }, [actionQuery]);

  React.useEffect(() => {
    setPrevFile(file);
    setUpload(true);
    setTimeout(() => {
      if (file != null) {
        if (file.length > 0) {
          if (file[0].name !== "browse" && prevFile !== file) {
            bulkUpload(shipperAcctNo, bulkSessionId, file);
          }
        }
      }
    }, 2000);
  }, [file, prevFile, inputFileRef]);

  React.useEffect(() => {
    if (uploadFile !== "" && !loading) {
      setTimeout(() => {
        setUploadFile("");
      }, 10000);
    }
  }, [uploadFile, loading]);

  const onCancelFile = () => {
    setUpload(false);
    setFile(prevFile);
  };

  // React.useEffect(() => {
  //   if (bulkDetailsData.length > 0 && !showConfirmLeave) {
  //     setShowConfirmLeave(true);
  //   }
  // }, [bulkDetailsData, showConfirmLeave]);

  {
    /* == Actions == */
  }
  const bulkUpload = async (shipperAccountNo, bulkSessionId, file) => {
    //setLoading(true);
    setUpload(true);
    setUploadFile("");

    const today = new Date();
    const date = `${today.getFullYear()}-${
      today.getMonth() + 1
    }-${today.getDate()} ${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`;
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    const changeDate = new Date(tomorrow);

    let pickUpDate = `${changeDate.getFullYear()}-${
      changeDate.getMonth() + 1
    }-${changeDate.getDate()} 08:00:00`;

    let formData = new FormData();

    formData.append("media", file[0]);

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        shipperuuid: shipperAccountNo,
        bulksessionid: bulkSessionId,
        bulkstatusupdate: "INCOMPLETE BOOKING",
        pickupdate: pickUpDate,
      },
      // onUploadProgress,
    };

    try {
      const response = await axiosLexaApiPrivate.post(
        "/BulkUpload/Corp",
        formData,
        config
      );
      const resData = response.data;

      if (resData.ResponseCode === "200") {
        const bulkResult = resData.ResponseData.BookingDetails;
        setUpload(false);
        setBookAdded(true);
        setUploadFile(file[0].name);
        setFile([{ name: "browse", size: 0 }]);
        setPrevFile([{ name: "browse", size: 0 }]);
        getBulkDetails(shipperAcctNo, bulkSessionId);
      } else {
        const bulkResult = resData.ResponseData.BookingDetails;
        setUpload(false);
        setFile([{ name: "browse", size: 0 }]);
        setPrevFile([{ name: "browse", size: 0 }]);

        setToastKind("error");
        setToastTitle("Upload Failed!");
        setToastDesc(
          `Invalid Excel Value : ${resData.ResponseData.BookingError[0].InvalidValue}`
        );
        setTimeout(() => {
          //setLoading(false);
          setUpload(false);
        }, 1000);
        setTimeout(() => {
          setToastOpen(true);
        }, 1500);
        setTimeout(() => {
          setToastOpen(false);
        }, 5000);
      }
    } catch (error) {
      setUpload(false);
      setFile([{ name: "browse", size: 0 }]);
      setPrevFile([{ name: "browse", size: 0 }]);

      setToastKind("error");
      setToastTitle("API Fetch Failed!");
      setToastDesc(error.message);
      setTimeout(() => {
        //setLoading(false);
        setUpload(false);
      }, 1000);
      setTimeout(() => {
        setToastOpen(true);
      }, 1500);
      setTimeout(() => {
        setToastOpen(false);
      }, 5000);
    }
  };

  const getBulkDetails = async (shipperAccountNo, bulkSessionId) => {
    setBulkDetailsData([]);
    setLoading(true);

    try {
      const response = await axiosCAPApiPrivate.get(
        `/api/GetCorpDomesticStagingBulkDetails/${shipperAccountNo}/${bulkSessionId}`
      );
      const resData = response.data;

      if (resData.Code === "200") {
        let bulkBookingDetails = resData.Data;
        setValidData(true);

        bulkBookingDetails.forEach((item, index) => {
          const senderAddress = item.ShipperStBldg.split(",");
          const senderFloorNumber = senderAddress[0]
            ? senderAddress[0].trim()
            : "";
          const senderStreetAddress = senderAddress[1]
            ? senderAddress[1].trim()
            : "";
          const receiverAddress = item.ConsigneeStBldg.split(",");
          const receiverFloorNumber = receiverAddress[0]
            ? receiverAddress[0].trim()
            : "";
          const receiverStreetAddress = receiverAddress[1]
            ? receiverAddress[1].trim()
            : "";
          // const isValidPkg = !(
          //   item.ReferenceNoThree === "RIDER PICK UP" &&
          //   (Number(item.ProdId) === 164 || Number(item.ProdId) === 165)
          // );
          const isValidPkg = true;

          if (
            item.Shipper.trim() === "" ||
            item.Shipper.trim() === " " ||
            !numbersRegx.test(item.ShipperMobileNumber) ||
            !mobilenumberRegex.test(item.ShipperMobileNumber) ||
            (item.ReferenceNoThree === "RIDER PICK UP" &&
              (senderFloorNumber === "" ||
                // senderStreetAddress === "" ||
                item.ShipperProvince === "" ||
                item.ShipperCityMunicipality === "" ||
                item.ShipperBrgy === "")) ||
            (item.ReferenceNoThree === "DROP-OFF" &&
              (item.ShipperProvince === "" ||
                item.ShipperCityMunicipality === "")) ||
            item.Consignee.trim() === "" ||
            item.Consignee.trim() === " " ||
            !numbersRegx.test(item.ConsigneeMobileNumber) ||
            !mobilenumberRegex.test(item.ConsigneeMobileNumber) ||
            (item.ReferenceNoFour === "HOME DELIVERY" &&
              (receiverFloorNumber === "" ||
                // receiverStreetAddress === "" ||
                item.ConsigneeProvince === "" ||
                item.ConsigneeCityMunicipality === "" ||
                item.ConsigneeBrgy === "" ||
                item.ConsigneeBrgyId === "0")) ||
            (item.ReferenceNoFour === "BRANCH PICK UP" &&
              (item.ConsigneeProvince === "" ||
                item.ConsigneeCityMunicipality === "" ||
                item.ConsigneeBrgy === "" ||
                item.ConsigneeBrgyId === "0")) ||
            item.Description === "" ||
            item.DeclaredValue < 100 ||
            item.DeclaredValue > 100000 ||
            item.ProdDisplayName === "" ||
            !isValidPkg
          ) {
            setValidData(false);
            bulkBookingDetails[index]["Remarks"] = "INVALID";
          } else {
            bulkBookingDetails[index]["Remarks"] =
              updatedItemId === item.Id ? "UPDATED" : "VALID";
          }
        });
        setBulkDetailsData(bulkBookingDetails);
        console.log("data: ", bulkBookingDetails);
        setShowConfirmLeave(bulkBookingDetails.length > 0);
        console.log(bulkBookingDetails.TrackingNo);
        setLoading(false);
      } else {
        setToastKind("info");
        setToastTitle("Information");
        setToastDesc(resData.Message);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
        setTimeout(() => {
          setToastOpen(true);
        }, 1500);
        setTimeout(() => {
          setToastOpen(false);
        }, 5000);
      }
    } catch (error) {
      setToastKind("error");
      setToastTitle("API Fetch Failed!");
      setToastDesc(error.message);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setTimeout(() => {
        setToastOpen(true);
      }, 1500);
      setTimeout(() => {
        setToastOpen(false);
      }, 5000);
    }
  };

  const updateBulkStatus = async (
    shipperAccountNo,
    bulkSessionID,
    bulkstatus
  ) => {
    setLoading(true);
    setUpdatedItemId(0);

    const data = {
      shipperAccountNo: shipperAccountNo,
      bulkSessionId: bulkSessionID,
      bulkstatus: bulkstatus,
    };

    const config = {
      headers: {
        shipperuuid: shipperAccountNo,
      },
    };

    const reqData = {
      bulkSessionId: bulkSessionID,
      bulkstatus: bulkstatus,
    };

    try {
      const response = await axiosLexaApiPrivate.post(
        "/UpdateBulkStatus",
        reqData,
        config
      );
      const resData = response.data;

      if (bulkstatus === "CONFIRMED BOOKING") {
        setBookType("booked");
        setShowConfirmLeave(false);
        setLoading(false);

        getBulkDetails(shipperAcctNo, bulkSessionId);
      } else {
        getBulkDetails(shipperAcctNo, bulkSessionId);
      }
    } catch (error) {
      setToastKind("error");
      setToastTitle("API Fetch Failed!");
      setToastDesc(error.message);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setTimeout(() => {
        setToastOpen(true);
      }, 1500);
      setTimeout(() => {
        setToastOpen(false);
      }, 5000);
    }
  };

  const printAirWaybill = async (data) => {
    setLoading2(true);
    let podItems = [];

    data.forEach((dataId) => {
      const getBulkDetailsData = bulkDetailsData.find(
        (itm) => itm.Id === dataId
      );
      const item = getBulkDetailsData;

      const senderAddress = item.ShipperStBldg.split(",");
      const senderFloorNumber = senderAddress[0] ? senderAddress[0].trim() : "";
      const senderStreetAddress = senderAddress[1]
        ? senderAddress[1].trim()
        : "";
      const senderNearestLandmark = senderAddress[2]
        ? senderAddress[2].trim()
        : "";

      let senderFullAddress = item
        ? item.ReferenceNoThree === "RIDER PICK UP"
          ? `${senderFloorNumber} ${senderStreetAddress}, ${item.ShipperBrgy}, ${item.ShipperCityMunicipality}, ${item.ShipperProvince}`
          : `${item.ShipperCityMunicipality}, ${item.ShipperProvince}`
        : "";
      if (senderNearestLandmark !== "") {
        senderFullAddress += `, ${senderNearestLandmark}`;
      }

      const receiverAddress = item.ConsigneeStBldg.split(",");
      const receiverFloorNumber = receiverAddress[0]
        ? receiverAddress[0].trim()
        : "";
      const receiverStreetAddress = receiverAddress[1]
        ? receiverAddress[1].trim()
        : "";
      const receiverNearestLandmark = receiverAddress[2]
        ? receiverAddress[2].trim()
        : "";

      let receiverFullAddress = item
        ? item.ReferenceNoFour === "HOME DELIVERY"
          ? `${receiverFloorNumber} ${receiverStreetAddress}, ${item.ConsigneeBrgy}, ${item.ConsigneeCityMunicipality}, ${item.ConsigneeProvince}`
          : `${item.ConsigneeBrgy}, ${item.ConsigneeCityMunicipality}, ${item.ConsigneeProvince}`
        : "";
      if (receiverNearestLandmark !== "") {
        receiverFullAddress += `, ${receiverNearestLandmark}`;
      }

      const trackingNo =
        item.ReferenceNoThree === "DROP-OFF" &&
        (item.Status === "PENDING DROP OFF" ||
          item.Status === "INCOMPLETE BOOKING" ||
          item.Status === "CANCELLED BY USER" ||
          item.Status === "EDITED BY USER")
          ? item.TrackingNo
            : item.BookingNo;

      const attachmentNameThree = item.AttachmentNameThree.split("|");
      const payCollectOptn = attachmentNameThree[1]
        ? Number(attachmentNameThree[1].trim())
        : 0;

      const amountToPay =
        Number(item.ShippingFee) +
        Number(item.ValuationFee) +
        Number(item.PKGBoxFee);

      let codAmount = 0;
      if (payCollectOptn === 1) {
        codAmount = Number(item.DeclaredValue);
      } else if (payCollectOptn === 2) {
        codAmount = amountToPay;
      } else if (payCollectOptn === 3) {
        codAmount = Number(amountToPay) + Number(item.DeclaredValue);
      }

      let transDate = new Date;
      // Get the individual components
      const day = String(transDate.getDate()).padStart(2, '0');  // Ensure two digits
      const month = String(transDate.getMonth() + 1).padStart(2, '0');  // Month is 0-indexed
      const year = transDate.getFullYear();

      const hours = transDate.getHours();
      const minutes = String(transDate.getMinutes()).padStart(2, '0');
      const seconds = String(transDate.getSeconds()).padStart(2, '0');

      // Determine AM/PM
      const ampm = hours >= 12 ? 'pm' : 'am';

      // Convert hours to 12-hour format
      const hours12 = hours % 12 || 12;  // 12:00 AM should display as 12, not 0

      // Format the date and time
      const formattedDate = `${day}/${month}/${year} ${hours12}:${minutes}:${seconds} ${ampm}`;
      console.log(formattedDate);

      let tryLangs = item.ReferenceNoFour === "HOME DELIVERY"
          ? "Rider Delivery"
          : "Branch Pick Up";

      const reqData = {
        Barcode1D: trackingNo, // item.TrackingNo,
        TrackingNo: trackingNo, // item.TrackingNo,
        ConsigneeName: item.Consignee,
        ConsigneeAddress:
          receiverFullAddress + " " + item.ConsigneeMobileNumber, // item.ConsigneeStBldg.trim(),
        ShipperName: item.Shipper, // ShipperName: item.ContactPerson,
        ShipperAddress:
          userInfo && userInfo.accountTypeTag === 1
            ? senderFullAddress
            : senderFullAddress + " " + item.ShipperMobileNumber, // item.ShipperStBldg.trim(),
        NoOfItems: item.Quantity,
        TransactionDate: formattedDate,
        TransactionDate: item.TransactionDate,
        DeliveryRequirement: item.DeliveryRequirements,
        SpecialInstructions: item.SpecialInstructions,
        DRNo: item.Description,
        DRNo2: "",
        Origin: item.Origin,
        // DeclaredValue: item.DeclaredValue,
        Destination: item.Destination,
        VolumeWt: `${parseInt(item.VolWTcbm, 0)}`,
        LWH: `${parseInt(item.LengthCM, 0)},${parseInt(
          item.WidthCM,
          0
        )},${parseInt(item.HeightCM, 0)}`,
        ActualWt: `${parseInt(item.ACTWTkgs, 0)}`,
        ChargeableWT: `${parseInt(item.ChargeableWT, 0)}`,
        PayMode: item.PaymentMode,
        ServiceMode: tryLangs,
        ShipmentMode: item.ShipmentMode,
        Product: item.ProductName,
        ProductName: item.ProdDisplayName,
        TotalFreightCharge: item.EstimatedFreightRate,
        Amount: `${
          item.ReferenceNoFour === "HOME DELIVERY" ? "COD" : "COP"
        } Amount: ${Number(item.CODAmount)
          .toFixed(2)
          .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`,
        Barcode2D: "",
        CreatedBy: "",
        PrintedBy: "",
      };

      console.log("POD Data:", reqData);

      podItems.push(reqData);
    });

    try {
      const response = await axiosLexaApiPrivate.post(
        "/GenerateMultiplePOD",
        podItems
      );
      const resData = response.data;

      if (resData && resData !== null) {
        const pdfByte = resData.Rendered_Byte;
        const byteCharacters = atob(pdfByte);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        const urlFinal = url;

        if (urlFinal !== undefined && urlFinal !== "") {
          window.open(urlFinal, "_blank");
        }
      }

      setLoading2(false);
    } catch (error) {
      setToastKind("error");
      setToastTitle("API Fetch Failed!");
      setToastDesc(error.message);
      setTimeout(() => {
        setLoading2(false);
      }, 1000);
      setTimeout(() => {
        setToastOpen(true);
      }, 1500);
      setTimeout(() => {
        setToastOpen(false);
      }, 5000);
    }
  };

  const onAddClick = () => {
    if (isCams) {
      setSelectedItem(null);
      setUpdatedItemId(0);
      setToastOpenB(true);
    } else {
      setSelectedItem(null);
      setUpdatedItemId(0);
      setToastOpenA(true);
    }
  };

  const onEditClick = async (item) => {
    setLoading(true);
    try {      
      if (isCams) {
        await new Promise(resolve => setTimeout(resolve, 1000));
        setSelectedItem(item);
        setUpdatedItemId(item.Id);
        setToastOpenB(true);
      } else {
        setSelectedItem(item);
        setUpdatedItemId(item.Id);
        setToastOpenA(true);
      }
    } catch (error) {
      console.error("Error during edit:", error);
    } finally {
      setLoading(false);
    }
  };

  const onDeleteClick = (item) => {
    setSelectedItem(item);
    setUpdatedItemId(0);
    setToastKind2("Delete");
    setToastTitle2("Are you sure you want to delete your booking?");
    setToastDesc2(
      "Once you delete your booking, you will no longer be able to have access to this booking."
    );
    setToastOpen2(true);
  };

  const onCancelClick = (item) => {
    setSelectedItem(item);
    setUpdatedItemId(0);
    setToastKind2("Cancel");
    setToastTitle2("Are you sure you want to cancel your booking?");
    setToastDesc2(
      "Once you cancel your booking, you will no longer be able to have access to this booking."
    );
    setToastOpen2(true);
  };

  const onViewBreakdownClick = (item) => {
    setToastTitle3("COD/COP Amount");
    setToastDesc3(
      <>
        <div className="amt-to-pay-container">
          <table style={{ marginTop: "10px" }}>
            <tbody>
              <tr>
                <td className="amt-to-pay-label">Item Value</td>
                <td className="amt-to-pay-value">{`PHP ${Number(
                  item &&
                    (getPayCollectOptn(item) === 1 ||
                      getPayCollectOptn(item) === 3)
                    ? item.DeclaredValue
                    : "0.00"
                )
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`}</td>
              </tr>
              <tr>
                <td className="amt-to-pay-label">Service Fee</td>
                <td className="amt-to-pay-value">{`PHP ${Number(
                  item &&
                    (getPayCollectOptn(item) === 2 ||
                      getPayCollectOptn(item) === 3)
                    ? item.EstimatedFreightRate
                    : "0.00"
                )
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr style={{ height: "50px" }}>
                <td style={{ textAlign: "left", color: "#000000" }}>Total</td>
                <td
                  style={{ textAlign: "right", color: "#000000" }}
                >{`PHP ${Number(item ? item.CODAmount : "0.00")
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`}</td>
              </tr>
            </tfoot>
          </table>
        </div>
      </>
    );
    setToastOpen3(true);
  };

  const onUploadAgainClick = () => {
    document.getElementById("excelUpload").scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
  };

  const onBookNowClick = () => {
    updateBulkStatus(shipperAcctNo, bulkSessionId, "CONFIRMED BOOKING");
  };

  const onConfirmClick = () => {
    setSelectedList([]);
    if (toastKind2 === "Delete") {
      updateBulkStatus(
        shipperAcctNo,
        selectedItem.BookingNo,
        "DELETED BY USER"
      );
    } else if (toastKind2 === "Cancel") {
      updateBulkStatus(
        shipperAcctNo,
        selectedItem.BookingNo,
        "CANCELLED BY USER"
      );
    }

    setToastOpen2(false);
  };

  {
    /* == End == */
  }

  const onSelectItems = (id) => {
    if (selectedList.includes(id)) {
      const tempList = [...selectedList].filter((value) => value !== id);
      console.log(tempList);
      setSelectedList(tempList);
    } else {
      const tempList = [...selectedList];
      tempList.push(id);
      setSelectedList(tempList);
    }
  };

  const onSelectAll = (checked) => {
    setSelectedList([]);
    if (checked) {
      let tempList = [];
      bulkDetailsData.forEach((item) => {
        if (!tempList.includes(item.Id)) {
          tempList.push(item.Id);
        }
      });

      setSelectedList(tempList);
    }
  };

  if (bookType === "booked") {
    setShowConfirmLeave(false);
  }

  const onSaveLater = () => {
    setLoading2(true);

    setTimeout(() => {
      setShowConfirmLeave(false);
    }, 2000);
    setTimeout(() => {
      setLoading2(false);
      setToastOpen1(false);
      navigate("/dashboard-home");
    }, 3000);
  };
  
  return (
    <>
      {loading2 && <Loading />}

      {isToastOpenA && (
        <BookNowModal
          isOpen={isToastOpenA}
          onClose={() => setToastOpenA(false)}
          data={{ bulkSessionId: bulkSessionId, editItem: selectedItem }}
          onBookingConfirmed={() =>
            getBulkDetails(shipperAcctNo, bulkSessionId)
          }
          setShowConfirmLeave={(val) => setShowConfirmLeave(val)}
        />
      )}

      {isToastOpenB && (
        <BookNowModalNew
          isOpen={isToastOpenB}
          onClose={() => setToastOpenB(false)}
          data={{ bulkSessionId: bulkSessionId, editItem: selectedItem }}
          onBookingConfirmed={() =>
            getBulkDetails(shipperAcctNo, bulkSessionId)
          }
          setShowConfirmLeave={(val) => setShowConfirmLeave(val)}
        />
        // <BookNowModalCams
        //   isOpen={isToastOpenB}
        //   onClose={() => setToastOpenB(false)}
        //   data={{ bulkSessionId: bulkSessionId, editItem: selectedItem }}
        //   onBookingConfirmed={() =>
        //     getBulkDetails(shipperAcctNo, bulkSessionId)
        //   }
        //   setShowConfirmLeave={(val) => setShowConfirmLeave(val)}
        // />
      )}

      <LBCModal
        id="alertModal"
        description={toastDesc}
        isOpen={isToastOpen}
        onClose={() => setToastOpen(false)}
        title={toastTitle}
        toastKind={toastKind}
      />

      <LBCModalTwoFunc
        id="confirmModal"
        bodyClassName="justify-between bg-white border border-grey-400 w-500px p-5 rounded-lg shadow-lg"
        description={toastDesc2}
        isOpen={isToastOpen2}
        onClose={() => setToastOpen2(false)}
        title={toastTitle2}
        buttonTextFirst="Yes"
        buttonTextSecond="No"
        onClickFirst={onConfirmClick}
        onClickSecond={() => setToastOpen2(false)}
      />

      <LBCModalTwoFunc
        id="viewBreakdown"
        bodyClassName="justify-between bg-white border border-grey-400 w-500px p-5 rounded-lg shadow-lg"
        description={toastDesc3}
        isOpen={isToastOpen3}
        onClose={() => setToastOpen3(false)}
        title={toastTitle3}
        showContorl={false}
      />

      <LBCModalTwoFunc
        id="saveForLater"
        description={
          <>
            <Span>
              You can go back anytime to finish your booking by clicking
            </Span>
            <Span className="mx-1 font-bold">
              Activity {`>`} Online Bookings {`>`} Draft
            </Span>
            <Span>in the menu.</Span>
          </>
        }
        isOpen={isToastOpen1}
        onClose={() => setToastOpen1(false)}
        title="Save for later?"
        toastKind="info"
        buttonTextFirst="Cancel"
        buttonTextSecond="Save for later"
        onClickSecond={onSaveLater}
        loading={loading2}
      />

      {bookType === "summary" && !loading ? (
        <FlexColumn className="w-full">
          <Spacer className="p-5" />
          <Text className="text-secondary-200 font-bold">SUMMARY</Text>
          <Text className="text-secondary-200">
            Kindly review the summary details before placing your order
          </Text>
          <Spacer className="p-5" />

          <Div className="w-full border border-grey-400 rounded-md p-5">
            <Text className="text-secondary-200 font-bold">
              BOOKING DETAILS
            </Text>
            <FlexRow className="w-full items-center justify-between py-2 border-b-2 border-secondary-200">
              {BookNowSummaryColumn.map((item) => (
                <Div className={`${item.width}`}>
                  <Text className="text-secondary-200 text-left font-bold">
                    {item.name}
                  </Text>
                </Div>
              ))}
            </FlexRow>
            <Div className="h-3/4 desktop:h-400px overflow-y-scroll py-5">
              {bulkDetailsData.map((item) => (
                <BookNowDataSummaryRow
                  data={item}
                  onViewBreakdownClick={onViewBreakdownClick}
                />
              ))}
            </Div>
          </Div>

          <CheckBox
            containerClass="w-full py-4"
            id="agreement"
            name="agreement"
            label={
              <Text className="text-secondary-200 text-xs laptop:text-sm desktop:text-base">
                I have read, understood and agree to
                <a
                  className="m-1 text-red-400 text-xs underline"
                  href="https://www.lbcexpress.com/terms-and-conditions"
                  rel="noreferrer"
                  target="_blank"
                >
                  LBC’s Terms of Service
                </a>
                and
                <a
                  className="ml-1 text-red-400 text-xs underline"
                  href="https://www.lbcexpress.com/privacy"
                  rel="noreferrer"
                  target="_blank"
                >
                  Privacy Policy
                </a>
              </Text>
            }
            checked={agreementAccepted}
            onClick={(e) => setAgreementAccepted(e.target.checked)}
          />

          <FlexRow className="w-full items-center justify-center pt-5 pb-20">
            <RawButton className="w-44" onClick={() => setToastOpen1(true)}>
              <Text className="text-red-400 font-bold">Save for later</Text>
            </RawButton>
            <FlexRow className="w-full items-start justify-center pr-44">
              <Button
                className="w-32 mr-2 phone:bg-white phone:text-secondary-200 hover:bg-grey-500 phone:border phone:border-grey-400 h-10"
                onClick={() => setBookType("default")}
              >
                Back
              </Button>
              <Button
                className={`w-32 ml-2 h-10 ${
                  !agreementAccepted
                    ? "cursor-not-allowed disabled:bg-red-90"
                    : ""
                }`}
                onClick={onBookNowClick}
                disabled={!agreementAccepted}
              >
                Book Now
              </Button>
            </FlexRow>
          </FlexRow>
        </FlexColumn>
      ) : (
        <>
          {bookType === "summary" && loading ? (
            <Text className="text-red-400 text-center flex flex-row justify-center items-center my-20">
              <ImSpinner2 className="animate-spin mr-2 text-2xl desktop:text-3xl" />
              Loading data ...
            </Text>
          ) : (
            <></>
          )}
        </>
      )}
      {bookType === "booked" && !loading ? (
        <FlexColumn className="w-full items-center">
          <Div className="w-500px border border-grey-400 rounded-lg shadow-lg my-5">
            <Div className="flex flex-col items-center justify-between p-4 w-full bg-white border-l-8 rounded-lg shadow-lg border-green-100">
              <Div className="w-full flex flex-row items-center phone:justify-between tablet:justify-center">
                <Div className="w-1/5">
                  <HiCheckCircle className="text-green-100 text-4xl" />
                </Div>
                <Div className="w-4/5">
                  <Text className="font-bold italic text-2xl text-secondary-100 pb-2">
                    Booking Confirmed
                  </Text>
                  <Text className="text-sm text-secondary-200 pt-1">
                    You've successfully booked your order/s!
                  </Text>
                  <Text className="text-sm text-secondary-200 pt-0">
                    {bulkDetailsData.length > 0
                      ? moment(bulkDetailsData[0].TransactionDate).format(
                          "MMMM D, YYYY h:mm A"
                        )
                      : moment().format("MMMM D, YYYY h:mm A")}
                  </Text>
                </Div>
              </Div>
            </Div>
          </Div>

          <Div className="w-2/3 rounded-sm shadow-sm border border-grey-400 p-10">
            <FlexRow className="w-full items-center">
              <Image
                className="h-14 w-14"
                alt="LBC logo"
                src={Images.LBClogo}
              />
              <Div className="pl-2  w-500px">
                <Text className="text-sm text-secondary-200">
                  {bulkDetailsData.length > 1
                    ? "MULTIPLE BOOKING REFERENCE NO."
                    : "SINGLE BOOKING REFERENCE NO."}
                </Text>
                <Text className="text-xl text-secondary-200 font-bold">
                  {bulkDetailsData.length > 0
                    ? bulkDetailsData[0].BookingNo
                    // ? bulkDetailsData[0].BookingNo
                    : ""}
                </Text>
              </Div>
              <Div className="flex flex-col w-full items-end justify-start">
                <Div className="flex flex-row h-24 items-center justify-start w-300px">
                  {bulkDetailsData.length > 0 && (
                    <>
                      <QRCode
                        // value={bulkDetailsData[0].BookingNo}
                        value={bulkDetailsData[0].TrackingNo}
                        style={{
                          width: "70px",
                          height: "70px",
                          marginRight: "10px",
                        }}
                      />
                      <Barcode
                        // value={bulkDetailsData[0].BookingNo}
                        value={bulkDetailsData[0].TrackingNo}
                        height={70}
                        width={2}
                        displayValue={false}
                      />
                    </>
                  )}
                </Div>
                <Text className="flex flex-row items-center jusitfy-center text-secondary-200 text-xs font-bold w-300px">
                  <ImInfo className="inline mr-2" />
                  <Span>Keep this QR and barcode as reference</Span>
                </Text>
              </Div>
            </FlexRow>
            <FlexRow className="w-full items-start py-1">
              <Text className="text-secondary-200 text-sm text-right w-52 pr-2">
                PICK UP SCHEDULE:
              </Text>
              <Text className="text-dark-100 text-sm text-left w-full pr-3">
                {bulkDetailsData.length > 0
                  ? moment(bulkDetailsData[0].PickupDate).format("MMMM D, yyyy")
                  : ""}
              </Text>
            </FlexRow>
            <FlexRow className="w-full items-start py-1">
              <Text className="text-secondary-200 text-sm text-right w-52 pr-2">
                SENDER:
              </Text>
              <Text className="text-dark-100 text-sm text-left w-full pr-3">
                {bulkDetailsData.length > 0
                  ? `${bulkDetailsData[0].Shipper.trim()} (+63 ${
                      bulkDetailsData[0].ShipperMobileNumber
                    })`
                  : ""}
              </Text>
            </FlexRow>
            <FlexRow className="w-full items-start py-1">
              <Text className="text-secondary-200 text-sm text-right w-52 pr-2">
                NO. OF SHIPMENTS BOOKED:
              </Text>
              <Text className="text-dark-100 text-sm text-left w-full pr-3">
                {`${bulkDetailsData.length} transactions`}
              </Text>
            </FlexRow>
          </Div>

          <Spacer className="h-5" />

          <Div className="w-full rounded-sm shadow-sm border border-grey-400 p-10">
            <FlexRow className="w-full items-center">
              <Image
                className="h-14 w-14"
                alt="LBC logo"
                src={Images.LBClogo}
              />
              <Div className="pl-2 w-500px">
                <Text className="text-sm text-secondary-200">
                  {bulkDetailsData.length > 1
                    ? "MULTIPLE BOOKING REFERENCE NO."
                    : "SINGLE BOOKING REFERENCE NO."}
                </Text>
                <Text className="text-xl text-secondary-200 font-bold">
                  {bulkDetailsData.length > 0
                    ? bulkDetailsData[0].BookingNo
                    // ? bulkDetailsData[0].BookingNo
                    : ""}
                </Text>
              </Div>
              <Div className="flex flex-col w-full items-end justify-start">
                <FlexRow>
                  <RawDropdown
                    icon={<div />}
                    width="w-full"
                    value={`${selectedList.length} Selected`}
                    options={[]}
                    onSelect={setSelected}
                  />
                  <Button
                    className={`w-72 ml-2 h-10 ${
                      selectedList.length === 0
                        ? "cursor-not-allowed disabled:bg-red-90"
                        : ""
                    }`}
                    onClick={() => printAirWaybill(selectedList)}
                    disabled={selectedList.length === 0}
                  >
                    Print Air Waybill
                  </Button>
                </FlexRow>
              </Div>
            </FlexRow>

            <Spacer className="h-5" />

            <FlexRow className="w-full items-center">
              <CheckBox
                className="ml-3"
                containerClass="text-sm text-red-400 font-semibold"
                id="chkSelectAll"
                name="chkSelectAll"
                label="Select All"
                onClick={(e) => onSelectAll(e.target.checked)}
                checked={selectedList.length === bulkDetailsData.length}
              />
            </FlexRow>

            <Spacer className="h-5" />

            {bulkDetailsData.map((item, i) => (
              <BookNowDataEditRow
                data={item}
                onSelect={onSelectItems}
                onViewClick={(data) => printAirWaybill([data.Id])}
                onEditClick={(data) => onEditClick(data)}
                onCancelClick={(data) => onCancelClick(data)}
                selectedList={selectedList}
                key={i}
              />
            ))}

            <FlexRow className="items-center justify-end w-full mt-1 py-2 font-semibold">
              Total Qty: {bulkDetailsData.length}
            </FlexRow>
          </Div>

          <FlexRow className="w-full items-center justify-center pt-5 pb-20">
            <Button
              className="w-32 mr-2 phone:bg-white phone:text-secondary-200 hover:bg-grey-500 phone:border phone:border-grey-400 h-10"
              onClick={() => navigate("/")}
            >
              Home
            </Button>
            <Button
              className="w-32 ml-2 h-10"
              onClick={() => navigate("/activity")}
            >
              View Activity
            </Button>
          </FlexRow>
        </FlexColumn>
      ) : (
        <>
          {bookType === "booked" && loading ? (
            <Text className="text-red-400 text-center flex flex-row justify-center items-center my-20">
              <ImSpinner2 className="animate-spin mr-2 text-2xl desktop:text-3xl" />
              Loading data ...
            </Text>
          ) : (
            <></>
          )}
        </>
      )}
      {bookType === "default" && !loading ? (
        <Div className="w-full">
          <Spacer className="h-5" />
          <FlexRow className="items-center justify-between">
            <Text className="text-secondary-200 font-bold">
              BOOKING DETAILS
            </Text>
            {/* <input
              className="w-48 block rounded-lg"
              type="date"
              value="2023-01-01"
            /> */}
          </FlexRow>
          <Spacer className="h-2" />

          {uploadFile !== "" && validData && (
            <Div className="w-full flex items-center justify-center">
              <Div className="w-500px border border-grey-400 rounded-lg shadow-lg my-5">
                <Div className="flex flex-col items-center justify-between p-4 w-full bg-white border-l-8 rounded-lg shadow-lg border-green-100">
                  <Div className="w-full flex flex-row items-center phone:justify-between tablet:justify-center">
                    <Div className="w-1/5">
                      <HiCheckCircle className="text-green-100 text-4xl" />
                    </Div>
                    <Div className="w-4/5">
                      <Text className="font-bold italic text-2xl text-secondary-100 pb-2">
                        Upload Successful
                      </Text>
                      <Text className="text-sm text-secondary-200 pt-1">
                        Uploaded file: {uploadFile}
                      </Text>
                      <RawButton onClick={onUploadAgainClick}>
                        <Text className="text-primary-600 text-sm hover:text-primary-400">
                          Upload again
                        </Text>
                      </RawButton>
                    </Div>
                  </Div>
                </Div>
              </Div>
            </Div>
          )}
          {uploadFile !== "" && !validData && (
            <Div className="w-full flex items-center justify-center">
              <Div className="w-500px border border-grey-400 rounded-lg shadow-lg my-5">
                <Div className="flex flex-col items-center justify-between p-4 w-full bg-white border-l-8 rounded-lg shadow-lg border-red-100">
                  <Div className="w-full flex flex-row items-center phone:justify-between tablet:justify-center">
                    <Div className="w-1/5">
                      <FaTimesCircle className="text-red-400 text-4xl" />
                    </Div>
                    <Div className="w-4/5">
                      <Text className="font-bold italic text-2xl text-secondary-100 pb-2">
                        You’ve entered incorrect details
                      </Text>
                      {uploadFile !== "" ? (
                        <>
                          <Text className="text-sm text-secondary-200 pt-1">
                            Uploaded file: {uploadFile}
                          </Text>
                          <Text className="text-sm text-red-400">
                            Please edit or upload again.
                          </Text>
                        </>
                      ) : (
                        <Text className="text-sm text-red-400">
                          Please edit.
                        </Text>
                      )}
                    </Div>
                  </Div>
                </Div>
              </Div>
            </Div>
          )}
          <Spacer className="h-5" />

          <Div className="w-full border border-grey-400 rounded-lg shadow-lg overflow-x-scroll my-5">
            <FlexRow className="items-center justify-between w-3000px">
              <ColumnHeader
                icon={false}
                title="Send via"
                onClick={() => {}}
                containerClass="w-200px px-4 py-2"
                titleClassName=""
              />
              <ColumnHeader
                icon={false}
                title="Tracking/Booking Number"
                onClick={() => {}}
                containerClass="w-200px px-4 py-2"
                titleClassName=""
              />
              <ColumnHeader
                icon={false}
                title="Sender"
                onClick={() => {}}
                containerClass="w-200px px-4 py-2"
                titleClassName=""
              />
              <ColumnHeader
                icon={false}
                title="Deliver via"
                onClick={() => {}}
                containerClass="w-200px px-4 py-2"
                titleClassName=""
              />
              <ColumnHeader
                icon={false}
                title="Receiver"
                onClick={() => {}}
                containerClass="w-200px px-4 py-2"
                titleClassName=""
              />
              <ColumnHeader
                icon={false}
                title="Item Name"
                onClick={() => {}}
                containerClass="w-200px px-4 py-2"
                titleClassName=""
              />
              <ColumnHeader
                icon={false}
                title="Item Value"
                onClick={() => {}}
                containerClass="w-200px px-4 py-2"
                titleClassName=""
              />
              <ColumnHeader
                icon={false}
                title="Shipment Type"
                onClick={() => {}}
                containerClass="w-200px px-4 py-2"
                titleClassName=""
              />
              <ColumnHeader
                icon={false}
                title="Actual Weight (kg)"
                onClick={() => {}}
                containerClass="w-200px px-4 py-2"
                titleClassName=""
              />
              <ColumnHeader
                icon={false}
                title="Item Dimension L x W x H (cm)"
                onClick={() => {}}
                containerClass="w-200px px-4 py-2"
                titleClassName=""
              />
              <ColumnHeader
                icon={false}
                title="Packaging"
                onClick={() => {}}
                containerClass="w-200px px-4 py-2"
                titleClassName=""
              />
              <ColumnHeader
                icon={false}
                title="Landmark/Special Instructions"
                onClick={() => {}}
                containerClass="w-200px px-4 py-2"
                titleClassName=""
              />
              <ColumnHeader
                icon={false}
                title="Freight Free"
                onClick={() => { }}
                containerClass="w-200px px-4 py-2"
                titleClassName=""
              />
              <ColumnHeader
                icon={false}
                title="COD/COP Amount"
                onClick={() => {}}
                containerClass="w-200px px-4 py-2"
                titleClassName=""
              />
              <ColumnHeader
                icon={false}
                title="Action"
                onClick={() => {}}
                containerClass="w-200px px-4 py-2 phone:justify-center"
                titleClassName=""
              />
            </FlexRow>
            <Divider className="text-grey-100 my-2 w-3000px" />

            {bulkDetailsData.length > 0 ? (
              <Div>
                {bulkDetailsData.map((item, key) => (
                  <BookNowDataRow
                    item={item}
                    onDeleteClick={onDeleteClick}
                    onEditClick={onEditClick}
                    key={key}
                  />
                ))}
              </Div>
            ) : (
              <Text className="text-secondary-200 text-sm text-center py-10">
                No data to be displayed
              </Text>
            )}
          </Div>

          <Div className="w-full p-2">
            <FlexRow className="justify-center w-full">
              <RawButton
                className="bg-grey-500 hover:bg-grey-400 rounded-lg p-2"
                onClick={onAddClick}
              >
                <Text className="text-secondary-200 text-sm font-bold">
                  + Add Booking
                </Text>
              </RawButton>
            </FlexRow>

            {!isCams && (
              <>
                <Text className="text-secondary-200 text-center my-2">OR</Text>

                <FlexRow className="items-center justify-between w-full">
                  <Spacer className="border-t-2 border-grey-400 w-2/5" />
                  <Text className="text-secondary-200 text-center font-bold w-1/5">
                    UPLOAD EXCEL
                  </Text>
                  <Spacer className="border-t-2 border-grey-400 w-2/5" />
                </FlexRow>

                {uploaded && file[0].name !== "browse" ? (
                  <FlexColumn className="items-start jusitfy-center w-96 m-5 p-5 mx-auto rounded-lg bg-grey-500 hover:bg-grey-400">
                    <FlexRow className="w-full items-center justify-between mb-2">
                      <Text className="text-secondary-200 text-sm">
                        File Upload
                      </Text>
                      <RawButton onClick={onCancelFile}>
                        <FaTimes className="text-secondary-200 text-xs" />
                      </RawButton>
                    </FlexRow>
                    <FlexRow className="w-96 items-center justify-between">
                      <Div className="w-72">
                        <div
                          className="rounded-full background-play h-2.5"
                          style={{
                            width: `100%`,
                          }}
                        />
                      </Div>
                      <Text className="text-secondary-200 text-xs w-24 pl-5">
                        {(file[0].size / 1000000).toFixed(2)} MB
                      </Text>
                    </FlexRow>
                  </FlexColumn>
                ) : (
                  <FlexColumn
                    id="excelUpload"
                    {...getRootProps({
                      className:
                        "items-center jusitfy-center w-96 m-5 p-5 mx-auto rounded-lg bg-red-70 border-dashed border-2 border-orange-100",
                    })}
                  >
                    <ImUpload3 className="text-grey-300 text-center" />
                    <FlexRow className="items-center jusitfy-center">
                      <Text className="text-secondary-200 text-sm my-2">
                        Drop .xlsx or
                      </Text>
                      <FileInput
                        file={file}
                        setFile={setFile}
                        fileRef={inputFileRef}
                        {...getInputProps()}
                      >
                        <Text
                          className={`${
                            file[0].name !== "browse" ? "w-40" : ""
                          } truncate text-blue-100 text-sm my-2 mx-1 hover:underline`}
                        >
                          {file[0].name}
                        </Text>
                      </FileInput>

                      {file[0].name !== "browse" ? (
                        <RawButton
                          onClick={() => setFile([{ name: "browse", size: 0 }])}
                        >
                          <FaTimes className="text-secondary-200 text-xs" />
                        </RawButton>
                      ) : (
                        <></>
                      )}
                    </FlexRow>
                  </FlexColumn>
                )}

                <Link
                  to="/Corp_Bulk_Upload_Template.xlsx"
                  target="_blank"
                  download
                  className="text-blue-100 text-center text-sm mx-auto hover:underline block"
                >
                  Download a sample .xlsx template
                </Link>
              </>
            )}

            <Spacer className="h-5" />

            <Div className="bg-grey-300 py-2">
              <Div className="w-3/4 mx-auto">
                <Text className="flex flex-row items-center jusitfy-center text-secondary-200 text-xs font-bold">
                  <ImInfo className="inline mr-2" />
                  <Span>Helpful Tips</Span>
                </Text>
                <Div className="px-10">
                  <Text className="text-secondary-200 text-xs">
                    &#x2022; Make sure you fill out the correct Item Value for
                    each order. This will be the basis of COD/COP Item Value
                    collection
                  </Text>
                  {/* <Text className="text-secondary-200 text-xs">
                    &#x2022; For Home Delivery, ensure that your area is
                    serviceable. Check serviceable areas
                    <a
                      className="m-1 text-blue-100 text-xs underline"
                      href="https://www.lbcexpress.com/branches"
                      rel="noreferrer"
                      target="_blank"
                    >
                      here.
                    </a>
                    For Branch Pick Ups, find branches
                    <a
                      className="ml-1 text-blue-100 text-xs underline"
                      href="https://www.lbcexpress.com/branches"
                      rel="noreferrer"
                      target="_blank"
                    >
                      here.
                    </a>
                  </Text> */}
                  <Text className="text-secondary-200 text-xs">
                    &#x2022; After booking your orders, check the{" "}
                    <Link
                      className="m-1 text-blue-100 text-xs underline"
                      to="/activity"
                    >
                      Activity
                    </Link>{" "}
                    to view the Booking Reference Number list
                  </Text>
                </Div>
              </Div>
            </Div>

            {bulkDetailsData.length > 0 ? (
              <FlexRow className="w-full items-center justify-center pt-5 pb-20">
                <RawButton className="w-44" onClick={() => setToastOpen1(true)}>
                  <Text className="text-red-400 font-bold">Save for later</Text>
                </RawButton>
                <FlexRow className="w-full items-start justify-center pr-44">
                  {bookType !== "default" && (
                    <Button
                      className="w-32 mr-2 phone:bg-white phone:text-secondary-200 hover:bg-grey-500 phone:border phone:border-grey-400 h-10"
                      onClick={() => setBookType("default")}
                    >
                      Back
                    </Button>
                  )}
                  <Button
                    className={`w-32 ml-2 h-10 ${
                      bookType === "default" && !validData
                        ? "cursor-not-allowed disabled:bg-red-90"
                        : ""
                    }`}
                    onClick={() => setBookType("summary")}
                    disabled={bookType === "default" && !validData}
                  >
                    Next
                  </Button>
                </FlexRow>
              </FlexRow>
            ) : (
              <></>
            )}
          </Div>
        </Div>
      ) : (
        <>
          {bookType === "default" && loading ? (
            <Text className="text-red-400 text-center flex flex-row justify-center items-center my-20">
              <ImSpinner2 className="animate-spin mr-2 text-2xl desktop:text-3xl" />
              Loading data ...
            </Text>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
};
