import React from "react";
import { Div, FlexRow } from "../../core/Containers";
import { Text } from "../../core/Text";
import { CheckBox } from "../../core/Forms";
import { BookNowModal } from "../Modals/BookNowModal";
import { RawButton } from "../../core/Buttons";
import { LBCModalTwoFunc } from "../Modals";
import moment from "moment";

export const BookNowDataSummaryRow = ({ data, onViewBreakdownClick }) => {
  const senderAddress = data.ShipperStBldg.split(",");
  const senderUnit = senderAddress[0] ? senderAddress[0].trim() : "";
  const senderStreet = senderAddress[1] ? senderAddress[1].trim() : "";
  const senderLandmark = senderAddress[2] ? senderAddress[2].trim() : "";

  const receiverAddress = data.ConsigneeStBldg.split(",");
  const receiverUnit = receiverAddress[0] ? receiverAddress[0].trim() : "";
  const receiverStreet = receiverAddress[1] ? receiverAddress[1].trim() : "";
  const receiverLandmark = receiverAddress[2] ? receiverAddress[2].trim() : "";

  console.log("data nanaman:", data);

  return (
    <>
      {/* <FlexRow className="items-start justify-between w-full py-2 hover:bg-grey-400"> */}
      <FlexRow className="items-center justify-between w-1090px">
        <Div className="w-1/6">
          <Text className="text-secondary-100 text-sm">
            {data.ProdDisplayName}
          </Text>
          <Text className="text-secondary-100 text-sm">{data.Description}</Text>
        </Div>

        <Div className="w-1/6">
          {/* <Text className="text-secondary-100 text-sm font-semibold">
            {data.Shipper}
          </Text> */}
          <Text className="text-secondary-100 text-sm font-semibold">
            {data.ContactPerson}
          </Text>
          <Text className="text-secondary-100 text-xs">{`+63 ${data.ShipperMobileNumber}`}</Text>
          {data.ReferenceNoThree === "RIDER PICK UP" ? (
            <>
              <Text className="text-secondary-100 text-xs">{`${senderUnit} ${senderStreet}, ${data.ShipperBrgy}, ${data.ShipperCityMunicipality}, ${data.ShipperProvince}`}</Text>
              <Text className="text-secondary-100 text-xs">
                via <strong>Pick Up</strong>
              </Text>
              <Text className="text-secondary-100 text-xs">
                Pick Up Date:{" "}
                <span className="text-orange-400">
                  {moment(data.PickupDate).format("M/D/yyyy")}
                </span>
              </Text>
            </>
          ) : (
            <>
              <Text className="text-secondary-100 text-xs">{`${data.ShipperCityMunicipality}, ${data.ShipperProvince}`}</Text>
              <Text className="text-secondary-100 text-xs">
                via <strong>Drop Off</strong>
              </Text>
            </>
          )}
        </Div>

        <Div className="w-1/5">
          <Text className="text-secondary-100 text-sm font-semibold">
            {data.Consignee}
          </Text>
          <Text className="text-secondary-100 text-xs">{`+63 ${data.ConsigneeMobileNumber}`}</Text>
          {data.ReferenceNoFour === "HOME DELIVERY" ? (
            <>
              <Text className="text-secondary-100 text-xs">{`${receiverUnit} ${receiverStreet}, ${data.ConsigneeBrgy}, ${data.ConsigneeCityMunicipality}, ${data.ConsigneeProvince}`}</Text>
              <Text className="text-secondary-100 text-xs">
                via <strong>Rider Delivery</strong>
              </Text>
            </>
          ) : (
            <>
              <Text className="text-secondary-100 text-xs">{`${data.ConsigneeBrgy}, ${data.ConsigneeCityMunicipality}, ${data.ConsigneeProvince}`}</Text>
              <Text className="text-secondary-100 text-xs">
                via <strong>Branch Pick Up</strong>
              </Text>
            </>
          )}
        </Div>

        <Div className="w-1/10">
          <Text className="text-secondary-100 text-sm font-semibold">
            PHP{" "}
            {Number(data.EstimatedFreightRate)
              .toFixed(2)
              .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
          </Text>
        </Div>

        <Div className="w-1/7">
          <Text className="text-secondary-100 text-sm">
            PHP{" "}
            {Number(data.CODAmount)
              .toFixed(2)
              .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
          </Text>
          {/* <RawButton onClick={() => onViewBreakdownClick(data)}>
            <Text className="text-primary-100 text-sm hover:text-primary-400">
              View Breakdown
            </Text>
          </RawButton> */}
        </Div>

        {/* <Div className="w-1/6">
          <Text className="text-secondary-100 text-sm">{"Bank Deposit"}</Text>
        </Div> */}
        
        <Div className="w-1/7">
          <Text className="text-secondary-100 text-sm">
            {data.TrackingNo}
            {(data.TrackingNo.trim() === "" ||
              data.TrackingNo.trim() === " ") && (
              <p className="text-xs text-left text-red-400 dark:text-red-400">
                Tracking Number not supplied
              </p>
            )}
          </Text>
        </Div>
      </FlexRow>
    </>
  );
};

export const BookNowDataEditRow = ({
  data,
  onSelect,
  onViewClick,
  onEditClick,
  onCancelClick,
  selectedList,
}) => {
  const [isToastOpen, setToastOpen] = React.useState(false);
  const [isToastOpenA, setToastOpenA] = React.useState(false);

  const senderAddress = data.ShipperStBldg.split(",");
  const senderUnit = senderAddress[0] ? senderAddress[0].trim() : "";
  const senderStreet = senderAddress[1] ? senderAddress[1].trim() : "";
  const senderLandmark = senderAddress[2] ? senderAddress[2].trim() : "";

  const receiverAddress = data.ConsigneeStBldg.split(",");
  const receiverUnit = receiverAddress[0] ? receiverAddress[0].trim() : "";
  const receiverStreet = receiverAddress[1] ? receiverAddress[1].trim() : "";
  const receiverLandmark = receiverAddress[2] ? receiverAddress[2].trim() : "";

  const closeSuccessAlert = itemId => {
    const tRowAlertSuccess = document.getElementById(`updatedCB-${itemId}`);
    if (tRowAlertSuccess) tRowAlertSuccess.remove();
  };

  return (
    <>
      {/* {data.Remarks === 'UPDATED' && (
        <FlexRow id={`updatedCB-${data.Id}`} className="items-center w-full bg-green-80 border-t-4 border-green-400 rounded-b text-secondary-100 px-4 py-1 shadow-md" role="alert">
          <Div className="flex items-center">
            <Div className="py-1">
              <svg className="fill-current h-6 w-6 text-green-400 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
                <path d="M20,12A8,8 0 0,1 12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4C12.76,4 13.5,4.11 14.2, 4.31L15.77,2.74C14.61,2.26 13.34,2 12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0, 0 22,12M7.91,10.08L6.5,11.5L11,16L21,6L19.59,4.58L11,13.17L7.91,10.08Z" />
              </svg>
            </Div>
            <FlexRow>
              <p className="text-sm mr-2">{"Record successfully updated."}</p>
              <RawButton className="text-sm text-blue-400" onClick={() => closeSuccessAlert(data.Id)}>Close</RawButton>
            </FlexRow>
          </Div>
        </FlexRow>
      )} */}
      <FlexRow className="items-stretch justify-between w-full py-2 border-b border-grey-400 hover:bg-grey-400">
        <Div className="flex w-10 items-center justify-center">
          <CheckBox
            className="phone:mx-0"
            containerClass="mx-auto"
            id={data.Id}
            name={data.Id}
            label=""
            onClick={() => onSelect(data.Id)}
            checked={selectedList && selectedList.includes(data.Id)}
          />
        </Div>

        <Div className="w-1/8">
          <Text className="text-sm text-red-400 font-semibold">
            {/* {data.BookingNo} */}
            { data.TrackingNo }
          </Text>
        </Div>

        <Div className="w-1/8">
          <Text className="text-secondary-100 text-sm">
            {data.ProdDisplayName}{" "}
            <span className="text-grey-100">{data.Description}</span>
          </Text>
          <Text className="text-secondary-100 text-xs font-semibold">
            {`${data.ReferenceNoThree === "RIDER PICK UP" ? "Pick Up" : "Drop Off"
              } → ${data.ReferenceNoFour === "HOME DELIVERY"
                ? "Rider Delivery"
                : "Branch Pick Up"
              }`}
          </Text>
          {data.ReferenceNoThree === "RIDER PICK UP" && (
            <Text className="text-secondary-100 text-xs">
              Pick Up Date:{" "}
              <span className="text-orange-400">
                {moment(data.PickupDate).format("M/D/yyyy")}
              </span>
            </Text>
          )}
          {/* {data.ReferenceNoFour === "HOME DELIVERY" && (
            <Text className="text-secondary-100 text-xs">
              Estimate Delivery Date:<br/>
              <span className="text-orange-400">
                {`${data.DelTATDays} days from actual ${data.ReferenceNoThree === "RIDER PICK UP" ? "drop off" : "pick up" } (excluding Sundays and holidays)​`}
              </span>
            </Text>
          )} */}
        </Div>

        <Div className="w-1/6">
          {/* <Text className="text-secondary-100 text-sm font-semibold">
            {data.Shipper}
          </Text> */}
          <Text className="text-secondary-100 text-sm font-semibold">
            {data.ContactPerson}
          </Text>
          <Text className="text-secondary-100 text-xs">
            {`+63 ${data.ShipperMobileNumber}`}
          </Text>
          {data.ReferenceNoThree === "RIDER PICK UP" ? (
            <Text className="text-grey-100 text-xs">
              {`${senderUnit} ${senderStreet}, ${data.ShipperBrgy}, ${data.ShipperCityMunicipality}, ${data.ShipperProvince}`}
            </Text>
          ) : (
            <Text className="text-grey-100 text-xs">
              {`${data.ShipperCityMunicipality}, ${data.ShipperProvince}`}
            </Text>
          )}
        </Div>

        <Div className="w-1/6">
          <Text className="text-secondary-100 text-sm font-semibold">
            <span className="font-normal">to</span> {data.Consignee}
          </Text>
          <Text className="text-secondary-100 text-xs">
            {`+63 ${data.ConsigneeMobileNumber}`}
          </Text>
          {data.ReferenceNoFour === "HOME DELIVERY" ? (
            <Text className="text-grey-100 text-xs">
              {`${receiverUnit} ${receiverStreet}, ${data.ConsigneeBrgy}, ${data.ConsigneeCityMunicipality}, ${data.ConsigneeProvince}`}
            </Text>
          ) : (
            <Text className="text-grey-100 text-xs">
              {`${data.ConsigneeBrgy}, ${data.ConsigneeCityMunicipality}, ${data.ConsigneeProvince}`}
            </Text>
          )}
        </Div>

        <Div className="w-1/10">
          <Text className="text-secondary-100 text-sm">Freight Fee</Text>
          <Text className="text-secondary-100 text-sm font-semibold">
            PHP{" "}
            {Number(data.EstimatedFreightRate)
              .toFixed(2)
              .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
          </Text>
        </Div>

        <Div className="w-1/10">
          <Text className="text-secondary-100 text-sm">
            {data.ReferenceNoFour === "HOME DELIVERY" ? "COD Amount" : "COP Amount"}
          </Text>
          <Text className="text-secondary-100 text-sm font-semibold">
            PHP{" "}
            {Number(data.CODAmount)
              .toFixed(2)
              .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
          </Text>
        </Div>

        <Div className="flex w-1/8 items-center justify-center">
          {onViewClick ? (
            <RawButton onClick={() => onViewClick(data)}>
              <Text className="text-red-400 text-sm underline font-semibold mx-1">
                View
              </Text>
            </RawButton>
          ) : (
            <RawButton>
              <Text className="text-red-400 text-sm underline font-semibold mx-1">
                View
              </Text>
            </RawButton>
          )}
          {data.AllowCancelEdit === "Y" && (onEditClick ? (
            <RawButton onClick={() => onEditClick(data)}>
              <Text className="text-red-400 text-sm underline font-semibold mx-1">
                Edit
              </Text>
            </RawButton>
          ) : (
            <RawButton>
              <Text className="text-red-400 text-sm underline font-semibold mx-1">
                Edit
              </Text>
            </RawButton>
          ))}
          {data.AllowCancelEdit === "Y" && (onCancelClick ? (
            <RawButton onClick={() => onCancelClick(data)}>
              <Text className="text-red-400 text-sm underline font-semibold mx-1">
                Cancel
              </Text>
            </RawButton>
          ) : (
            <RawButton>
              <Text className="text-red-400 text-sm underline font-semibold mx-1">
                Cancel
              </Text>
            </RawButton>
          ))}
        </Div>
      </FlexRow>
    </>
  );
};
