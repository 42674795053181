export const BorderColors = [
  'border-l-yellow-100',
  'border-l-blue-100',
  'border-l-red-100',
];

export const ToastData = {
  type: {
    suc: 'success',
    err: 'error',
    info: 'info',
    warn: 'warning',
  },
  title: {
    create200: 'Created Successfully!',
    create400: 'Create Failed!',
    update200: 'Updated Successfully!',
    update400: 'Update Failed!',
    delete200: 'Deleted Successfully!',
    delete400: 'Delete Failed!',
    login200: 'Login Success!',
    login400: 'Login Failed!',
  },
  desc: {
    err01: 'Something went wrong. Please try again.',
    err02: 'Something went wrong. Input data is invalid.',
  },
};

export const Months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const MonthsFull = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

/////////////////////////////////////
//  Book Now Data
/////////////////////////////////////

export const BookNowSummaryColumn = [
  {
    name: 'Package',
    width: 'w-1/5',
  },
  {
    name: 'Sender',
    width: 'w-1/5',
  },
  {
    name: 'Receiver',
    width: 'w-1/5',
  },
  {
    name: 'Freight Fee',
    width: 'w-1/6',
  },
  {
    name: 'COD/COP Amount',
    width: 'w-1/5',
  },
  // {
  //   name: 'COD/COP Disbursal',
  //   width: 'w-1/5',
  // },
  {
    name: 'Tracking No',
    width: 'w-1/5',
  }
];

export const BookNowStage = [
  {
    name: 'Sender',
    stage: 1,
  },
  {
    name: 'Receiver',
    stage: 2,
  },
  {
    name: 'Package',
    stage: 3,
  },
];

/////////////////////////////////////
//  Activity Pages Data
/////////////////////////////////////

export const ActiveColumns = [
  {
    name: 'Tracking No.',
    url: '',
  },
  {
    name: 'Created By',
    url: '',
  },
  {
    name: 'Book Type',
    url: '',
  },
  {
    name: 'Book Date',
    url: '',
  },
];

/////////////////////////////////////
//  Payment Pages Data
/////////////////////////////////////

export const RemittanceHistoryColumns = [
  'Tracking Number',
  'Reference Number',
  'Remittance Date',
  'Remittance Mode',
  'Account Name',
  'COD Amount',
  'Amount Remitted',
];

/////////////////////////////////////
//  Delivery Report Data
/////////////////////////////////////

export const DeliveryReportColumns = [
  'DATE',
  'DELIVERED',
  'PENDING / FOR DISPOSITION',
  'FOR UPDATE',	
  'RETURNED TO SHIPPER',	
  'LOST',	
  'TOTAL TRANSACTIONS',
];

/////////////////////////////////////
//  Remittance Report Data
/////////////////////////////////////

export const RemittanceCorpColumns = [
  'DATE',
  'REMITTED',
  'UNREMITTED',
  'TOTAL DELIVERED'
];

/////////////////////////////////////
//  Remittance History Report Data
/////////////////////////////////////

export const RemittanceHistoryReportColumns = [
  'Tracking Number',
  'Reference Number',
  'Remittance Date',
  'Remittance Status',	
  'COD Amount'//,	
  //'Amount Remitted'
];