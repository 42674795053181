import axios from "axios";
import axiosLexaApi from "./axiosLexaApi";
import { Cookies } from "react-cookie";
import { appConfig } from "../helpers/common";

const cookies = new Cookies();

const axiosLexaApiPrivate = axios.create({
  baseURL: appConfig.api.lexaapiUrl,
  headers: {
    "Content-Type": "application/json",
    token: appConfig.api.lexaapiToken,
    lbcOAkey: appConfig.api.lexaapiLBCOAkey,
  },
  withCredentials: true,
});

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, accessToken = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(accessToken);
    }
  });

  failedQueue = [];
};

axiosLexaApiPrivate.interceptors.request.use(
  (config) => {
    const accessToken = cookies.get("lexaAccessToken"); // Retrieve access token from cookies
    if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosLexaApiPrivate.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const originalRequest = error.config;
    if (error.response.status === 403 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then(({ accessToken }) => {
            originalRequest.headers["Authorization"] = "Bearer " + accessToken;
            return axiosLexaApiPrivate(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      return new Promise(function (resolve, reject) {
        axiosLexaApi
          .post("/RefreshJWTToken")
          .then((response) => {
            return response.data;
          })
          .then((data) => {
            const newAccessToken = data;
            cookies.set("lexaAccessToken", newAccessToken, {
              secure: true,
              path: "/",
              sameSite: "none",
            }); // Update access token in cookies
            axiosLexaApiPrivate.defaults.headers["Authorization"] =
              "Bearer " + newAccessToken;
            originalRequest.headers["Authorization"] =
              "Bearer " + newAccessToken;
            processQueue(null, newAccessToken);
            resolve(axiosLexaApiPrivate(originalRequest));
          })
          .catch((err) => {
            processQueue(err, null);
            reject(err);
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    }

    return Promise.reject(error);
  }
);

export default axiosLexaApiPrivate;
