import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie";
import { FlexRow, FlexColumn, Spacer, Div } from "../../core/Containers";
import { Text, Span } from "../../core/Text";
import { Image } from "../../core/Image";
import { RawInput } from "../../core/Forms";
import { LBCModal } from "../../components/Modals/LBCModal";
import { Loading } from "../Window/Loading";
import { Images } from "../../assets/images/images";
import { isEmail, appConfig } from "../../helpers/common";
import { Button } from "../../core/Buttons";
import { browserName } from "react-device-detect";
import axiosCAPApi from "../../api/axiosCAPApi";
import axiosCAPApiPrivate from "../../api/axiosCAPApiPrivate";
import axiosLexaApi from "../../api/axiosLexaApi";

const cookies = new Cookies();

export const Login = () => {
  const [username, setUsername] = useState("");
  const [usernameErr, setUsernameErr] = useState("");
  const [password, setPassword] = useState("");
  const [passwordErr, setPasswordErr] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const [isToastOpen, setToastOpen] = useState(false);
  const [toastKind, setToastKind] = useState("");
  const [toastTitle, setToastTitle] = useState("Account Created");
  const [toastDesc, setToastDesc] = useState("Redirecting to onboarding.");

  const [userID, setUserID] = useState(0);
  const [loginAttempt, setLoginAttempt] = useState(0);
  const [IPAddress, setIPAddress] = useState();
  const [Geolocation, setGeolocation] = useState({});

  const navigate = useNavigate();

  const getIp = async () => {
    const response = await fetch("https://ipapi.co/json/");
    const data = await response.json();
    setIPAddress(data.ip);
  };

  const geolocation = () => {
    // if geolocation is supported by the users browser
    if (navigator.geolocation) {
      // get the current users location
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // save the geolocation coordinates in two variables
          const { latitude, longitude } = position.coords;
          setGeolocation({ latitude, longitude });
        },
        // if there was an error getting the users location
        (error) => {
          console.error("Error getting user location:", error);
        }
      );
    }
    // if geolocation is not supported by the users browser
    else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  useEffect(() => {
    getIp();
    geolocation();
  }, []);

  const checkUsername = async (e, action) => {
    e && e.preventDefault();
    setLoading(true);

    try {
      const response = await axiosCAPApi.post(
        "/api/CheckUsername",
        { username },
        {
          headers: { lbcOAkey: appConfig.api.lbcOAkey },
          withCredentials: true,
        }
      );

      const data = response.data;

      if (data.Code === "200") {
        if (data.Data !== null) {
          setUserID(data.Data.userID);

          if (action === "Login") {
            if (data.Data.isActive === false) {
              setToastKind("error");
              setToastTitle("Login Failed!");
              setToastDesc(
                "Access to this account has been temporarily locked due to many failed login attempts. You can immediately restore it by resetting your password."
              );
              setTimeout(() => {
                setLoading(false);
              }, 1000);
              setTimeout(() => {
                setToastOpen(true);
              }, 1500);
              setTimeout(() => {
                setToastOpen(false);
              }, 5000);
            } else {
              await onLogin();
            }
          } else if (action === "ForgotPass") {
            onSendOTP(data.Data);
          } else {
            setTimeout(() => {
              setLoading(false);
              window.open(
                `mailto:${appConfig.supportCenterEmail}?subject=Corporate Accounts Portal Concerns`
              );
            }, 1000);
          }
        } else {
          setToastKind("error");
          setToastTitle("Checking Email Failed!");
          setToastDesc(
            "There is no user record corresponding to this identifier. The user may have been deleted."
          );
          setTimeout(() => {
            setLoading(false);
          }, 1000);
          setTimeout(() => {
            setToastOpen(true);
          }, 1500);
          setTimeout(() => {
            setToastOpen(false);
          }, 5000);
        }
      } else {
        setToastKind("error");
        setToastTitle("Checking Email Failed!");
        setToastDesc(data.Message);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
        setTimeout(() => {
          setToastOpen(true);
        }, 1500);
        setTimeout(() => {
          setToastOpen(false);
        }, 5000);
      }
    } catch (error) {
      setToastKind("error");
      setToastTitle("Checking Email Failed!");
      setToastDesc(error.message);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setTimeout(() => {
        setToastOpen(true);
      }, 1500);
      setTimeout(() => {
        setToastOpen(false);
      }, 5000);
    }
  };

  const onLogin = async () => {
    setLoading(true);

    try {
      const response = await axiosCAPApi.post("/api/Authenticate", {
        username,
        password,
        IPAddress,
        Browser: browserName,
        Latitude: Geolocation.latitude,
        Longitude: Geolocation.longitude,
        URL: window.location.href,
      });

      const data = response.data;

      if (data.Code === "200") {
        const _userInfo = data.Data;

        if (_userInfo.changePassDate === "") {
          setToastKind("warning");
          setToastTitle("Change Password Required");
          setToastDesc("You are required to change your password.");
          setTimeout(() => {
            setLoading(false);
          }, 1000);
          setTimeout(() => {
            setToastOpen(true);
          }, 1500);
          setTimeout(() => {
            setToastOpen(false);
            navigate(`/change-password?userID=${_userInfo.userID}`);
          }, 5000);
        } else {
          const lexaResponse = await axiosLexaApi.post("/GenerateJWTToken", {
            client: "CorpTNT",
            email: username,
            password: password
          });
          const lexaAccessToken = lexaResponse.data;
          
          cookies.set("userInfo", _userInfo, { path: "/" });
          cookies.set("accessToken", _userInfo.accessToken, {
            secure: true,
            path: "/",
            sameSite: "none",
          });
          cookies.set("lexaAccessToken", lexaAccessToken, {
            secure: true,
            path: "/",
            sameSite: "none",
          });
          await getUserShipperList(_userInfo?.userID);
          setToastKind("success");
          setToastTitle("Logging in");
          setToastDesc("Redirecting...");

          setTimeout(() => {
            setLoading(false);
          }, 1000);
          setTimeout(() => {
            setToastOpen(true);
          }, 1500);
          setTimeout(() => {
            setLoading(false);
            navigate(`/dashboard-home`);
          }, 5000);
        }
      } else {
        setLoginAttempt(loginAttempt + 1);

        if (loginAttempt >= 3) {
          deactivateUser();
          setToastKind("error");
          setToastTitle("Login Failed!");
          setToastDesc(
            "Access to this account has been temporarily locked due to many failed login attempts. You can immediately restore it by resetting your password."
          );
          setTimeout(() => {
            setLoading(false);
          }, 1000);
          setTimeout(() => {
            setToastOpen(true);
          }, 1500);
          setTimeout(() => {
            setToastOpen(false);
          }, 5000);
        } else {
          setToastKind("error");
          setToastTitle("Login Failed!");
          setToastDesc("Please enter your correct credentials.");
          setTimeout(() => {
            setLoading(false);
          }, 1000);
          setTimeout(() => {
            setToastOpen(true);
          }, 1500);
          setTimeout(() => {
            setToastOpen(false);
          }, 5000);
        }
      }
    } catch (error) {
      setToastKind("error");
      setToastTitle("Login Failed!");
      setToastDesc(error.message);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setTimeout(() => {
        setToastOpen(true);
      }, 1500);
      setTimeout(() => {
        setToastOpen(false);
      }, 5000);
    }
  };

  const getUserShipperList = async (userID) => {
    setLoading(true);

    try {
      const response = await axiosCAPApiPrivate.get(
        "/api/GetUserShipperList/" + userID
      );

      const data = response.data;

      if (data.Code === "200") {
        cookies.set("userShipperList", data.Data, {
          path: "/",
        });
        if (data.Data.length > 0) {
          cookies.set("shipperAcct", data.Data[0], {
            path: "/",
          });
        }
        setLoading(false);
      } else {
        setToastKind("error");
        setToastTitle("Get User Shipper List Failed!");
        setToastDesc(data.Message);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
        setTimeout(() => {
          setToastOpen(true);
        }, 1500);
        setTimeout(() => {
          setToastOpen(false);
        }, 5000);
      }
    } catch (error) {
      setToastKind("error");
      setToastTitle("Get User Shipper List Failed!");
      setToastDesc(error.message);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setTimeout(() => {
        setToastOpen(true);
      }, 1500);
      setTimeout(() => {
        setToastOpen(false);
      }, 5000);
    }
  };

  const onSendOTP = async (userInfo) => {
    setLoading(true);

    const generatedOTP = Math.floor(100000 + Math.random() * 900000);

    try {
      const response = await axiosCAPApiPrivate.post("/api/SendEmail", {
        fromEmail: "customercare@lbcexpress.com",
        toEmail: username,
        subject: `Corporate Accounts Portal Verification Code: ${generatedOTP}`,
        html: true,
        body: `<div>${generatedOTP} is your Corporate Accounts Portal verification code.</div><br><br><div>Best regards,</div><div>Corporate Accounts Portal Support Team</div><br><br>%Unsubscribe_Link%`,
      });

      const data = response.data;

      if (data.Code === "200") {
        setToastKind("success");
        setToastTitle("OTP sent to your email address.");
        setToastDesc("Please check your email address.");
        setTimeout(() => {
          setLoading(false);
        }, 1000);
        setTimeout(() => {
          setToastOpen(true);
        }, 1500);
        setTimeout(() => {
          navigate(
            `/forgot-password?otp=${generatedOTP}&userID=${userInfo.userID}&email=${userInfo.username}`
          );
        }, 5000);
      } else {
        setToastKind("error");
        setToastTitle("OTP Generation Failed!");
        setToastDesc(data.Message);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
        setTimeout(() => {
          setToastOpen(true);
        }, 1500);
        setTimeout(() => {
          setToastOpen(false);
        }, 5000);
      }
    } catch (error) {
      setToastKind("error");
      setToastTitle("OTP Generation Failed!");
      setToastDesc(
        "Unable to generate OTP. Please contact Corporate Accounts Portal Support Team."
      );
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setTimeout(() => {
        setToastOpen(true);
      }, 1500);
      setTimeout(() => {
        setToastOpen(false);
      }, 5000);
    }
  };

  const deactivateUser = async () => {
    try {
      const response = await axiosCAPApiPrivate.post("/api/UpdateUserDetails", {
        userID,
        isActive: 0,
      });

      const data = response.data;

      if (data.Code === "200") {
      } else {
      }
    } catch (error) {}
  };

  const onForgotPassClick = () => {
    let validData = true;
    setUsernameErr("");

    if (username === "") {
      setUsernameErr("Email is required.");
      validData = false;
    } else if (!isEmail(username)) {
      setUsernameErr("Not a valid Email.");
      validData = false;
    }

    if (validData) {
      checkUsername(null, "ForgotPass");
    }
  };

  const onCCMClick = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      window.open(
        `mailto:${appConfig.supportCenterEmailCorp}?subject=Corporate Accounts Portal Concerns`
      );
    }, 2000);
  };

  const onSalesSupportClick = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      window.open(
        `mailto:${appConfig.supportCenterEmailRetail}?subject=Retail CAMS Branch Tool Concerns`
      );
    }, 2000);
  };

  return (
    <>
      {loading && <Loading />}

      <LBCModal
        description={toastDesc}
        isOpen={isToastOpen}
        onClose={() => setToastOpen(false)}
        title={toastTitle}
        toastKind={toastKind}
      />

      <FlexRow className="justify-center items-center h-full w-full">
        <FlexColumn className="justify-center items-center w-500px rounded-lg shadow-xl border border-grey-400 desktop:w-600px py-7">
          <Image
            className="h-32 w-36 desktop:h-32 desktop:w-36"
            alt="LBC logo"
            src={Images.LBClogo}
          />

          <Spacer className="h-2" />

          {/* <Text className="text-secondary-100 text-sm font-bold text-center desktop:text-base">
            BUSINESS
          </Text>
          <Text className="text-secondary-100 text-sm font-bold text-center desktop:text-base">
            SOLUTIONS
          </Text> */}

          <Spacer className="h-5" />

          <Text className="text-secondary-200 text-xl font-bold text-center desktop:text-2xl">
            Sign in
          </Text>
          <Text className="text-secondary-200 text-sm text-center desktop:text-base">
            with your registered email
          </Text>

          <Spacer className="h-10" />

          <form
            className="flex flex-col items-center justify-center"
            onSubmit={(e) => checkUsername(e, "Login")}
          >
            <RawInput
              className="text-secondary-200"
              containerClass="w-300px"
              name="username"
              placeholder="Email or Username"
              onChange={setUsername}
              type="text"
              value={username}
              validation={usernameErr}
            />

            <Spacer className="h-4" />

            <Div className="relative w-300px">
              <div
                className="absolute inset-y-0 right-0 flex items-center px-3 h-10 cursor-pointer"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-5 h-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-5 h-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                  </svg>
                )}
              </div>
              <RawInput
                className="text-secondary-200"
                containerClass="w-full"
                name="password"
                placeholder="Password"
                onChange={setPassword}
                type={showPassword ? "text" : "password"}
                value={password}
                maxlength={20}
                validation={passwordErr}
              />
            </Div>

            <Spacer className="h-5" />

            <Button className="w-36 ml-2 h-10" type="submit">
              Sign In
            </Button>

            {/* <button className="hidden" type="submit">
              Log In
            </button> */}
          </form>

          <Spacer className="h-10" />

          {/* <Text
            className="text-red-400 font-bold cursor-pointer"
            onClick={onForgotPassClick}
          >
            Forgot Password?
          </Text> */}
          <FlexColumn className="text-xs italic text-center desktop:text-sm">
            <Span className="text-secondary-200 italic mr-1">
              Issues in logging in? Click for help.
            </Span>
            <FlexRow className="justify-center items-center h-full w-full">
              <Text
                className="text-red-400 italic font-bold cursor-pointer"
                onClick={onCCMClick}
              >
                CCM - for Corporate Customer
              </Text>
            </FlexRow>
            <FlexRow className="justify-center items-center h-full w-full">
              <Text
                className="text-red-400 italic font-bold cursor-pointer"
                onClick={onSalesSupportClick}
              >
                Sales Support - for CAMS Customer
              </Text>
            </FlexRow>
          </FlexColumn>
        </FlexColumn>
      </FlexRow>
    </>
  );
};
