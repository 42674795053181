import axios from "axios";
import { appConfig } from "../helpers/common";

const axiosCommonRefApi = axios.create({
  baseURL: appConfig.api.lbccommonrefv2Url,
  headers: {
    "Content-type": "application/json",
    lbcOAkey: appConfig.api.lbccommonrefv2LbcOAkey,
  },
});

export default axiosCommonRefApi;
