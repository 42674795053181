import React, { Fragment, useRef } from "react";
import { Cookies } from "react-cookie";
import { Dialog, Transition } from "@headlessui/react";
import { Div, FlexColumn, FlexRow, Spacer } from "../../core/Containers";
import { Span, Text } from "../../core/Text";
import { Button, RawButton } from "../../core/Buttons";
import { CheckBox, Radio, RawInput, RawSelect } from "../../core/Forms";
import { useNavigate } from "react-router-dom";
import { LBCModal, LBCModalTwoFunc } from "./LBCModal";
import { BookNowStage } from "../../constants/ConstantsData";
import { StageUI } from "../Feature";
import { FormLabel } from "../../core/Forms/FormLabel";
import { CorpPortalAcctPackageItem } from "../Feature/PackageItem";
import { Loading } from "../../pages/Window/Loading";
import moment from "moment/moment";
import convert from "xml-js";
import {
    getProvinceRegionId,
    isNumber,
    isMobileNumber,
} from "../../helpers/common";
import Calendar from "react-calendar";
import { Images } from "../../assets/images/images";
import { AddressBookModal } from "./AddressBookModal";
import { ImInfo } from "react-icons/im";
import axiosCAPApi from "../../api/axiosCAPApi";
import axiosCAPApiPrivate from "../../api/axiosCAPApiPrivate";
import axiosLexaApiPrivate from "../../api/axiosLexaApiPrivate";
import axiosCommonRefApi from "../../api/axiosCommonRefApi";

const cookies = new Cookies();

const filterConfig = {
    ignoreCase: true,
    ignoreAccents: true,
    trim: true,
    matchFrom: "any",
    stringify: (option) => `${option.label}`,
};
export const BookNowModalCams = ({
    isOpen,
    onClose,
    data,
    onBookingConfirmed,
    setShowConfirmLeave,
}) => {

    const navigate = useNavigate();
    const dataFetchedRef = useRef();

    const [isToastOpen1, setToastOpen1] = React.useState(false);

    const [isToastOpen2, setToastOpen2] = React.useState(false);
    const [toastKind2, setToastKind2] = React.useState("");
    const [toastTitle2, setToastTitle2] = React.useState("");
    const [toastDesc2, setToastDesc2] = React.useState("");

    const [isToastOpen3, setToastOpen3] = React.useState(false);

    const [isToastOpen4, setToastOpen4] = React.useState(false);

    const [isABMOpen, setIsABMOpen] = React.useState(false);

    const [pickUpDate, setPickUpDate] = React.useState("");
    const [pickUpDateErr, setPickUpDateErr] = React.useState("");

    const [loading, setLoading] = React.useState(false);
    const [currentStage, setStage] = React.useState(1);
    const [bulkSessionId, setBulkSessionId] = React.useState("");
    const [editValidated, setEditValidated] = React.useState(false);

    const [sendingMethod, setSendingMethod] = React.useState("DROP-OFF");

    const [minDate, setMinDate] = React.useState(moment());
    const [maxDate, setMaxDate] = React.useState(moment().add(10, "days"));
    const [isSaveForLater, setIsSaveForLater] = React.useState(false);

    const [shipperAcctName, setShipperAcctName] = React.useState("");
    const [shipperAcctNo, setShipperAcctNo] = React.useState("");
    const [customerDetails, setCustomerDetails] = React.useState(null);

    const [senderSelectedAddress, setSenderSelectedAddress] = React.useState(null);

    const [holidays, setHolidays] = React.useState([]);

    const [useWithSupplies, setUseWithSupplies] = React.useState(false);
    const [useWithSuppliesErr, setUseWithSuppliesErr] = React.useState("");
    const [senderTrackingNo, setSenderTrackingNo] = React.useState("");
    const [senderTrackingNoErr, setSenderTrackingNoErr] = React.useState("");

    { /* == Receiver Details States == */ }
    const [deliveryMethod, setDeliveryMethod] = React.useState("HOME DELIVERY");

    React.useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;

        if (isOpen) {
            var _shipperAcct = cookies.get("shipperAcct", { path: "/" });
            var _shipperAcctSplit = _shipperAcct ? _shipperAcct.split("|") : [];
            var _shipperAcctName = _shipperAcctSplit[0]
                ? _shipperAcctSplit[0].toString().trim()
                : "";
            var _shipperAcctNo = _shipperAcctSplit[1]
                ? _shipperAcctSplit[1].toString().trim()
                : "";
            setShipperAcctName(_shipperAcctName);
            setShipperAcctNo(_shipperAcctNo);
        }
    }, [isOpen]);

    const onSaveLater = () => {
        setLoading(true);
        if (currentStage > 1) {
            setTimeout(() => {
                setShowConfirmLeave(false);
            }, 2000);
            setTimeout(() => {
                setLoading(false);
                setToastOpen1(false);
                setIsSaveForLater(true);
            }, 3000);
        }
    };

    const onConfirmBooking = () => {
        setToastOpen3(false);
        // calculatePriceNewRates();
    };

    const onPickUpDateChange = (value) => {
        setPickUpDate(moment(value).format("dddd, MMMM DD, YYYY"));
        setToastOpen4(false);
    
        setPickUpDateErr("");
    };

    const onSelectAddress = (item) => {
        setSenderSelectedAddress(item);
    };

    const disabledDates = (date) => {
        const blackoutDates = [];
        holidays.forEach((holiday) => {
            blackoutDates.push(
                moment(new Date(holiday.Holidays)).format("YYYY-MM-DD")
            );
        });
        return (
            date.getDay() === 0 ||
            blackoutDates.includes(moment(date).format("YYYY-MM-DD"))
        );
    }

    const onSendingMethodChange = (sendingMethod) => {

    };

    const onDeliveryMethodChange = (deliveryMethod) => {

    };

    const onUseWithSuppliesChange = (value) => {
        setUseWithSupplies(value);
        if (value) {
          setSenderTrackingNo('');
        }
    };

    const onChangeStage = (stage, type) => {
        let validSender = true;
        if (type === "next" && stage === 1) {
            validSender = validateSenderDetails();
        }

        let validReceiver = true;
        if (type === "next" && stage === 2) {
            validReceiver = validateReceiverDetails();
        }

        let validPackage = true;
        if (type === "next" && stage === 3) {
            validPackage = validatePackageDetails();
        }

        if (validSender && validReceiver) {
            if (type === "next" && stage === 2) {
                
            }
        }
    };

    const validateSenderDetails = () => {

    };

    const validateReceiverDetails = () => {

    };

    const validatePackageDetails = () => {

    };



    const clearStates = () => {

    };

    // Dulo!
    const onCloseModal = () => {
        clearStates();
        onClose();
    };

    return (
        <Transition appear as={Fragment} show={isOpen}>
            <Dialog
                as="div"
                className="fixed inset-0 z-50 overflow-hide"
                onClose={onClose}
            >
                <div className="h-full w-full text-center flex justify-end">
                    <Transition.Child
                        as={Fragment}
                        enter="transition ease duration-300 transform"
                        enterFrom="opacity-0 -translate-y-full"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease duration-300 transform"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 -translate-y-full"
                    >
                        <div className="h-full w-full overflow-hidden z-20 p-5 align-middle transition-all transform flex flex-col items-center justify-center bg-secondary-200">
                            <LBCModalTwoFunc
                                id="saveForLater"
                                description={
                                    <>
                                        <Span>
                                            You can go back anytime to finish your booking by clicking
                                        </Span>
                                        <Span className="mx-1 font-bold">
                                            Activity {`>`} Online Bookings {`>`} Draft
                                        </Span>
                                        <Span>in the menu.</Span>
                                    </>
                                }
                                isOpen={isToastOpen1}
                                onClose={() => setToastOpen1(false)}
                                title="Save for later?"
                                toastKind="info"
                                buttonTextFirst="Cancel"
                                buttonTextSecond="Save for later"
                                onClickSecond={onSaveLater}
                                loading={loading}
                            />

                            <LBCModal
                                id="alertModal"
                                description={toastDesc2}
                                isOpen={isToastOpen2}
                                onClose={() => setToastOpen2(false)}
                                title={toastTitle2}
                                toastKind={toastKind2}
                            />

                            <LBCModalTwoFunc
                                id="confirmModal"
                                description="Are you sure you want to proceed?"
                                isOpen={isToastOpen3}
                                onClose={() => setToastOpen3(false)}
                                title="Booking Confirmation"
                                buttonTextFirst="Cancel"
                                buttonTextSecond="Continue"
                                onClickFirst={() => setToastOpen3(false)}
                                onClickSecond={onConfirmBooking}
                            />

                            <LBCModalTwoFunc
                                id="pickUpCalendar"
                                description={
                                    <>
                                        <FlexRow className="w-full items-start justify-center">
                                            <Calendar
                                                className="pickup-calender"
                                                onChange={(value) => onPickUpDateChange(value)}
                                                value={pickUpDate}
                                                minDate={minDate ? minDate.toDate() : moment().toDate()}
                                                maxDate={
                                                    maxDate
                                                        ? maxDate.toDate()
                                                        : moment().add(10, "days").toDate()
                                                }
                                                tileDisabled={({ date }) => disabledDates(date)}
                                            />
                                        </FlexRow>
                                    </>
                                }
                                isOpen={isToastOpen4}
                                onClose={() => setToastOpen4(false)}
                                title="Select a Pick Up Date"
                                toastKind="info"
                                onClickSecond={onSaveLater}
                                loading={loading}
                                showContorl={false}
                            />

                            {isABMOpen && (
                                <AddressBookModal
                                    isOpen={isABMOpen}
                                    onClose={() => setIsABMOpen(false)}
                                    data={{ customerAccountNo: shipperAcctNo }}
                                    onSelect={onSelectAddress}
                                />
                            )}
                            <FlexColumn className="justify-between bg-white border border-grey-400 h-5/6 w-900px px-10 py-5 rounded-lg shadow-lg">
                                <FlexRow className="w-full items-start justify-start">
                                    {BookNowStage.map((item, index) => {
                                        <StageUI
                                            label={item.name}
                                            count={item.count}
                                            currentIndex={currentStage}
                                            onStage={
                                                currentStage === item.stage || currentStage > item.stage
                                            }
                                            key={index}
                                        />
                                    })}
                                </FlexRow>

                                <Div className="w-full h-3/4 my-2 p-5 overflow-y-scroll">
                                    {currentStage === 1 && (
                                        <Div className="w-full">
                                            <Div className="w-full">
                                                <Text className="text-secondary-200 text-left">
                                                    How do you want to send your package?
                                                </Text>
                                                <div className="send-del-option">
                                                    <div className="optn-container">
                                                        <input
                                                            type="radio"
                                                            id="drop-off-optn"
                                                            name="send-optn"
                                                            onChange={() => onSendingMethodChange("DROP-OFF")}
                                                            checked={sendingMethod === "DROP-OFF"}
                                                        />
                                                        <label
                                                            htmlFor="drop-off-optn"
                                                            style={{ marginRight: "5px" }}
                                                        >
                                                            <div className="send-del-optn-body">
                                                                <div
                                                                    id="drop-off-icon"
                                                                    className="send-del-optn-icon"
                                                                />
                                                                <div className="send-del-optn-details">
                                                                    <div className="title">Drop Off</div>
                                                                    Drop off at the nearest LBC Corporate
                                                                    Solutions branch.
                                                                </div>
                                                            </div>
                                                            <span className="checkmark" />
                                                        </label>
                                                    </div>
                                                </div>
                                            </Div>
                                            <FlexRow className="w-full items-start justify-between py-2">
                                                <Text className="text-secondary-200 text-sm text-right w-1/3 pr-2 pt-2">
                                                    SHIPPER NAME:
                                                </Text>
                                                <RawInput
                                                    className=" text-secondary-200 text-sm h-10"
                                                    containerClass="w-2/3"
                                                    name="senderShipperName"
                                                    placeholder="Shipper Name"
                                                    type="text"
                                                    value="Test"
                                                    autoComplete="off"
                                                />
                                            </FlexRow>
                                            <FlexRow className="w-full items-start justify-between py-2">
                                                <Text className="text-secondary-200 text-sm text-right w-1/3 pr-2 pt-2">
                                                    SHIPPER ADDRESS:
                                                </Text>
                                                <textarea
                                                    className="block w-2/3 text-sm text-gray-900 bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                                    name="senderShipperAddress"
                                                    placeholder="Shipper Address"
                                                    type="textarea"
                                                    value="4B Templanza St. Don Pedro Marulas Valenzuela City"
                                                    autoComplete="off"
                                                />
                                            </FlexRow>
                                            <FlexRow className="w-full items-start justify-between py-2">
                                                <Text className="text-secondary-200 text-sm text-right w-1/3 pr-2 pt-2">
                                                    CONTACT PERSON:
                                                </Text>
                                                <Div className="relative w-1/3 pr-2">
                                                    <RawInput
                                                        className="text-secondary-200 text-sm h-10 pr-9"
                                                        containerClass="w-full"
                                                        name="senderName"
                                                        placeholder="Contact Name"
                                                        type="text"
                                                        value="Gilbert Adane"
                                                        autoComplete="off"
                                                    />
                                                </Div>
                                                <div className="relative w-1/3 pl-2">
                                                    <div className="absolute inset-y-0 left-1 flex items-center pl-3 pointer-events-none h-10 text-secondary-200 text-sm">
                                                        +63
                                                    </div>
                                                    <RawInput
                                                        className="text-secondary-200 text-sm h-10 pl-9"
                                                        containerClass="w-full"
                                                        name="senderNum"
                                                        placeholder="9000000000"
                                                        type="text"
                                                        value="9772856199"
                                                        autoComplete="off"
                                                        maxlength="10"
                                                    />
                                                </div>
                                            </FlexRow>
                                            <FlexRow className="w-full items-start py-2">
                                                <Text className="text-secondary-200 text-sm text-right w-1/3 pr-2 pt-2">
                                                    WITH ADVANCE SUPPLIES? :
                                                </Text>
                                                <div className="flex items-center w-1/3 pl-2">
                                                    <CheckBox
                                                        id="useWithSupplies"
                                                        name="useWithSupplies"
                                                        checked={useWithSupplies}
                                                        onClick={(e) => onUseWithSuppliesChange(e.target.checked)}
                                                    />
                                                    <RawInput
                                                        className="text-secondary-200 text-sm h-10 ml-2" // Add margin to separate from checkbox
                                                        containerClass="w-full"
                                                        name="trackingNo" // Change to your desired name
                                                        placeholder="Enter Tracking No" // Change to your desired placeholder
                                                        type="text"
                                                        value="12346789012"
                                                        disabled={useWithSupplies}
                                                    />
                                                </div>
                                            </FlexRow>

                                            <Spacer className="w-full h-4" />

                                            {sendingMethod === "DROP-OFF" && (
                                                <>
                                                    <Text className="text-left">
                                                        You may drop off your package in any LBC
                                                        branch within 2 weeks starting from your
                                                        booking date.
                                                    </Text>
                                                    <Spacer className="w-full h-4" />
                                                    <Text className="text-left">
                                                        Find LBC branches{" "}
                                                        <a
                                                            className="text-blue-100"
                                                            href="https://www.lbcexpress.com/branches"
                                                            target="blank"
                                                        >
                                                            here
                                                        </a>
                                                    </Text>
                                                </>
                                            )}
                                        </Div>
                                    )}

                                    { /* == Receiver Details == */ }
                                    {currentStage === 2 && (
                                        <Div className="w-full">
                                            <Div className="w-full">
                                                <FormLabel className="text-secondary-200 text-left">
                                                    How do you want to your package received?
                                                </FormLabel>
                                                <div className="send-del-option">
                                                    <div className="optn-container">
                                                        <input
                                                            type="radio"
                                                            id="rider-delivery-optn"
                                                            name="del-optn"
                                                            onChange={() =>
                                                                onDeliveryMethodChange("HOME DELIVERY")
                                                            }
                                                            checked={deliveryMethod === "HOME DELIVERY"}
                                                        />
                                                    </div>
                                                </div>
                                            </Div>
                                        </Div>
                                    )}

                                    <Spacer className="h-5" />
                                </Div>
                                <FlexRow className="w-full items-start justify-center py-2">
                                    {currentStage > 1 && (
                                        <RawButton
                                            className="w-1/4"
                                            onClick={() => setToastOpen1(true)}
                                        >
                                            <Text className="text-red-400 font-bold">
                                                Save for later
                                            </Text>
                                        </RawButton>
                                    )}
                                    <Spacer className="w-1/2" />
                                    <Button
                                        className="w-1/4 mr-2 phone:bg-white phone:text-secondary-200 hover:bg-grey-500 phone:border phone:border-grey-400 h-10"
                                        onClick={
                                            currentStage === 1
                                                ? onCloseModal
                                                : () => onChangeStage(currentStage, "prev")
                                        }
                                    >
                                        { currentStage === 1 ? "Cancel" : "Back" }
                                    </Button>
                                    <Button
                                        className="w-1/4 ml-2 h-10"
                                        onClick={() => onChangeStage(currentStage, "next")}
                                        isLoading={loading}
                                    >
                                        Next
                                    </Button>
                                </FlexRow>
                            </FlexColumn>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    );
};