import React, { useRef } from "react";
import { Cookies } from "react-cookie";
import { Div, FlexRow, Spacer } from "../../../core/Containers";
import { Text } from "../../../core/Text";
import { ActivityDataRow, ColumnHeader } from "../../../components/Tables";
import { Pagination } from "../../../components/Tables/Pagination";
import { useNavigate } from "react-router-dom";
import { ImSpinner2 } from "react-icons/im";
import { LBCModal } from "../../../components/Modals";
import moment from "moment";
import { Loading } from "../../Window/Loading";
import axiosCAPApiPrivate from "../../../api/axiosCAPApiPrivate";
import axiosLexaApiPrivate from "../../../api/axiosLexaApiPrivate";

const cookies = new Cookies();

export const ActiveTab = ({ dateFrom, dateTo }) => {
  const navigate = useNavigate();
  const dataFetchedRef = useRef(false);

  const [shipperAcctName, setShipperAcctName] = React.useState("");
  const [shipperAcctNo, setShipperAcctNo] = React.useState("");

  const [bookingDetails, setBookingDetails] = React.useState([]);

  const [loading, setLoading] = React.useState(true);
  const [loading2, setLoading2] = React.useState(false);
  const [isAscending, setAscending] = React.useState(true);

  const [singleView, setSingleView] = React.useState("default");
  const [selected, setSelected] = React.useState("default");
  const [selectedList, setSelectedList] = React.useState([]);

  const [currentPage, setCurrentPage] = React.useState(1);
  const [articlesPerPage] = React.useState(10);
  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;

  const newItems = bookingDetails.slice(
    indexOfFirstArticle,
    indexOfLastArticle
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const [isToastOpen, setToastOpen] = React.useState(false);
  const [toastKind, setToastKind] = React.useState("");
  const [toastTitle, setToastTitle] = React.useState("");
  const [toastDesc, setToastDesc] = React.useState("");

  const userInfo = cookies.get("userInfo", { path: "/" });
  React.useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    var _shipperAcct = cookies.get("shipperAcct", { path: "/" });
    var _shipperAcctSplit = _shipperAcct ? _shipperAcct.split("|") : [];
    var _shipperAcctName = _shipperAcctSplit[0]
      ? _shipperAcctSplit[0].toString().trim()
      : "";
    var _shipperAcctNo = _shipperAcctSplit[1]
      ? _shipperAcctSplit[1].toString().trim()
      : "";

    setShipperAcctName(_shipperAcctName);
    setShipperAcctNo(_shipperAcctNo);

    getBookingDetails(_shipperAcctNo);
  }, []);

  React.useEffect(() => {
    var _shipperAcct = cookies.get("shipperAcct", { path: "/" });
    var _shipperAcctSplit = _shipperAcct ? _shipperAcct.split("|") : [];
    var _shipperAcctName = _shipperAcctSplit[0]
      ? _shipperAcctSplit[0].toString().trim()
      : "";
    var _shipperAcctNo = _shipperAcctSplit[1]
      ? _shipperAcctSplit[1].toString().trim()
      : "";

    if (shipperAcctNo !== "" && _shipperAcctNo !== shipperAcctNo) {
      setShipperAcctName(_shipperAcctName);
      setShipperAcctNo(_shipperAcctNo);

      getBookingDetails(_shipperAcctNo);
    }
  }, [cookies.get("shipperAcct", { path: "/" })]);

  React.useEffect(() => {
    if (shipperAcctNo !== "") {
      getBookingDetails(shipperAcctNo);
    }
  }, [dateFrom, dateTo]);

  const getBookingDetails = async (shipperAccountNo) => {
    setBookingDetails([]);
    setLoading(true);

    const reqData = {
      customerAccountNo: shipperAccountNo,
      dateFrom: dateFrom,
      dateTo: dateTo,
      transactionStatus: "ACTIVE",
    };

    try {
      const response = await axiosCAPApiPrivate.post(
        `/api/GetCorpDomesticStagingDetails`,
        reqData
      );
      const resData = response.data;

      if (resData.Code === "200") {
        setBookingDetails(resData.Data);
        setBookingDetails(resData.Data);
        setLoading(false);
      } else {
        setToastKind("error");
        setToastTitle("API Fetch Failed!");
        setToastDesc(resData.Message);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
        setTimeout(() => {
          setToastOpen(true);
        }, 1500);
        setTimeout(() => {
          setToastOpen(false);
        }, 5000);
      }
    } catch (error) {
      setToastKind("error");
      setToastTitle("API Fetch Failed!");
      setToastDesc(error.message);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setTimeout(() => {
        setToastOpen(true);
      }, 1500);
      setTimeout(() => {
        setToastOpen(false);
      }, 5000);
    }
  };

  const onSelectItems = (id) => {
    if (selectedList.includes(id)) {
      const tempList = [...selectedList].filter((value) => value !== id);
      setSelectedList(tempList);
    } else {
      const tempList = [...selectedList];
      tempList.push(id);
      setSelectedList(tempList);
    }
  };

  const onSort = async (column) => {
    setLoading(true);
    if (isAscending) {
      if (column === 0) {
        const tempList = await bookingDetails.sort((a, b) => {
          if (a.BookingNo < b.BookingNo) {
            return -1;
          }
          if (a.BookingNo > b.BookingNo) {
            return 1;
          }
          return 0;
        });
        setBookingDetails(tempList);
      } else if (column === 1) {
        const tempList = await bookingDetails.sort((a, b) => {
          if (
            (a.TransactionDate !== ""
              ? moment(a.TransactionDate).format("MMM. D, YYYY")
              : "") <
            (b.TransactionDate !== ""
              ? moment(b.TransactionDate).format("MMM. D, YYYY")
              : "")
          ) {
            return -1;
          }
          if (
            (a.TransactionDate !== ""
              ? moment(a.TransactionDate).format("MMM. D, YYYY")
              : "") >
            (b.TransactionDate !== ""
              ? moment(b.TransactionDate).format("MMM. D, YYYY")
              : "")
          ) {
            return 1;
          }
          return 0;
        });
        setBookingDetails(tempList);
      } else if (column === 2) {
        const tempList = await bookingDetails.sort((a, b) => {
          if (a.ContactPerson < b.ContactPerson) {
            return -1;
          }
          if (a.ContactPerson > b.ContactPerson) {
            return 1;
          }
          return 0;
        });
        setBookingDetails(tempList);
      } else if (column === 3) {
        const tempList = await bookingDetails.sort((a, b) => {
          if (a.ShipperStBldg < b.ShipperStBldg) {
            return -1;
          }
          if (a.ShipperStBldg > b.ShipperStBldg) {
            return 1;
          }
          return 0;
        });
        setBookingDetails(tempList);
      } else if (column === 4) {
        const tempList = await bookingDetails.sort((a, b) => {
          if (
            (a.ProductLine === "SUPPLIES" ? "Supplies" : "Shipment" || "") <
            (b.ProductLine === "SUPPLIES" ? "Supplies" : "Shipment" || "")
          ) {
            return -1;
          }
          if (
            (a.ProductLine === "SUPPLIES" ? "Supplies" : "Shipment" || "") >
            (b.ProductLine === "SUPPLIES" ? "Supplies" : "Shipment" || "")
          ) {
            return 1;
          }
          return 0;
        });
        setBookingDetails(tempList);
      } else if (column === 5) {
        const tempList = await bookingDetails.sort((a, b) => {
          if (
            (a.PickupDate !== ""
              ? moment(a.PickupDate).format("MMM. D, YYYY")
              : "") <
            (b.PickupDate !== ""
              ? moment(b.PickupDate).format("MMM. D, YYYY")
              : "")
          ) {
            return -1;
          }
          if (
            (a.PickupDate !== ""
              ? moment(a.PickupDate).format("MMM. D, YYYY")
              : "") >
            (b.PickupDate !== ""
              ? moment(b.PickupDate).format("MMM. D, YYYY")
              : "")
          ) {
            return 1;
          }
          return 0;
        });
        setBookingDetails(tempList);
      }
      setTimeout(() => {
        setLoading(false);
        setAscending(!isAscending);
      }, 1000);
    } else {
      if (column === 0) {
        const tempList = await bookingDetails.sort((a, b) => {
          if (a.BookingNo > b.BookingNo) {
            return -1;
          }
          if (a.BookingNo < b.BookingNo) {
            return 1;
          }
          return 0;
        });
        setBookingDetails(tempList);
      } else if (column === 1) {
        const tempList = await bookingDetails.sort((a, b) => {
          if (
            (a.TransactionDate !== ""
              ? moment(a.TransactionDate).format("MMM. D, YYYY")
              : "") >
            (b.TransactionDate !== ""
              ? moment(b.TransactionDate).format("MMM. D, YYYY")
              : "")
          ) {
            return -1;
          }
          if (
            (a.TransactionDate !== ""
              ? moment(a.TransactionDate).format("MMM. D, YYYY")
              : "") <
            (b.TransactionDate !== ""
              ? moment(b.TransactionDate).format("MMM. D, YYYY")
              : "")
          ) {
            return 1;
          }
          return 0;
        });
        setBookingDetails(tempList);
      } else if (column === 2) {
        const tempList = await bookingDetails.sort((a, b) => {
          if (a.ContactPerson > b.ContactPerson) {
            return -1;
          }
          if (a.ContactPerson < b.ContactPerson) {
            return 1;
          }
          return 0;
        });
        setBookingDetails(tempList);
      } else if (column === 3) {
        const tempList = await bookingDetails.sort((a, b) => {
          if (a.ShipperStBldg > b.ShipperStBldg) {
            return -1;
          }
          if (a.ShipperStBldg < b.ShipperStBldg) {
            return 1;
          }
          return 0;
        });
        setBookingDetails(tempList);
      } else if (column === 4) {
        const tempList = await bookingDetails.sort((a, b) => {
          if (
            (a.ProductLine === "SUPPLIES" ? "Supplies" : "Shipment" || "") >
            (b.ProductLine === "SUPPLIES" ? "Supplies" : "Shipment" || "")
          ) {
            return -1;
          }
          if (
            (a.ProductLine === "SUPPLIES" ? "Supplies" : "Shipment" || "") <
            (b.ProductLine === "SUPPLIES" ? "Supplies" : "Shipment" || "")
          ) {
            return 1;
          }
          return 0;
        });
        setBookingDetails(tempList);
      } else if (column === 5) {
        const tempList = await bookingDetails.sort((a, b) => {
          if (
            (a.PickupDate !== ""
              ? moment(a.PickupDate).format("MMM. D, YYYY")
              : "") >
            (b.PickupDate !== ""
              ? moment(b.PickupDate).format("MMM. D, YYYY")
              : "")
          ) {
            return -1;
          }
          if (
            (a.PickupDate !== ""
              ? moment(a.PickupDate).format("MMM. D, YYYY")
              : "") <
            (b.PickupDate !== ""
              ? moment(b.PickupDate).format("MMM. D, YYYY")
              : "")
          ) {
            return 1;
          }
          return 0;
        });
        setBookingDetails(tempList);
      }
      setTimeout(() => {
        setLoading(false);
        setAscending(!isAscending);
      }, 1000);
    }
  };

  const printAirWaybill = async (data) => {
    setLoading2(true);

    let podItems = [];

    data.forEach((dataId) => {
      const getBookingDetails = bookingDetails.find((itm) => itm.Id === dataId);
      const item = getBookingDetails;

      const senderAddress = item.ShipperStBldg.split(",");
      const senderFloorNumber = senderAddress[0] ? senderAddress[0].trim() : "";
      const senderStreetAddress = senderAddress[1]
        ? senderAddress[1].trim()
        : "";
      const senderNearestLandmark = senderAddress[2]
        ? senderAddress[2].trim()
        : "";

      let senderFullAddress = item
        ? item.ReferenceNoThree === "RIDER PICK UP"
          ? `${senderFloorNumber} ${senderStreetAddress}, ${item.ShipperBrgy}, ${item.ShipperCityMunicipality}, ${item.ShipperProvince}`
          : `${item.ShipperCityMunicipality}, ${item.ShipperProvince}`
        : "";
      if (senderNearestLandmark !== "") {
        senderFullAddress += `, ${senderNearestLandmark}`;
      }

      const receiverAddress = item.ConsigneeStBldg.split(",");
      const receiverFloorNumber = receiverAddress[0]
        ? receiverAddress[0].trim()
        : "";
      const receiverStreetAddress = receiverAddress[1]
        ? receiverAddress[1].trim()
        : "";
      const receiverNearestLandmark = receiverAddress[2]
        ? receiverAddress[2].trim()
        : "";

      let receiverFullAddress = item
        ? item.ReferenceNoFour === "HOME DELIVERY"
          ? `${receiverFloorNumber} ${receiverStreetAddress}, ${item.ConsigneeBrgy}, ${item.ConsigneeCityMunicipality}, ${item.ConsigneeProvince}`
          : `${item.ConsigneeBrgy}, ${item.ConsigneeCityMunicipality}, ${item.ConsigneeProvince}`
        : "";
      if (receiverNearestLandmark !== "") {
        receiverFullAddress += `, ${receiverNearestLandmark}`;
      }

      const trackingNo =
        item.ReferenceNoThree === "DROP-OFF" &&
        (item.Status === "PENDING DROP OFF" ||
          item.Status === "INCOMPLETE BOOKING" ||
          item.Status === "CANCELLED BY USER" ||
          item.Status === "EDITED BY USER")
          ? (!item.TrackingNo.startsWith('32') ? item.TrackingNo : item.BookingNo)
          : item.TrackingNo;

      const attachmentNameThree = item.AttachmentNameThree.split("|");
      const payCollectOptn = attachmentNameThree[1]
        ? Number(attachmentNameThree[1].trim())
        : 0;

      const amountToPay =
        Number(item.ShippingFee) +
        Number(item.ValuationFee) +
        Number(item.PKGBoxFee);

      let codAmount = 0;
      if (payCollectOptn === 1) {
        codAmount = Number(item.DeclaredValue);
      } else if (payCollectOptn === 2) {
        codAmount = amountToPay;
      } else if (payCollectOptn === 3) {
        codAmount = Number(amountToPay) + Number(item.DeclaredValue);
      }

      const reqData = {
        Barcode1D: trackingNo, // item.TrackingNo,
        TrackingNo: trackingNo, // item.TrackingNo,
        ConsigneeName: item.Consignee,
        ConsigneeAddress:
          receiverFullAddress + " " + item.ConsigneeMobileNumber, // item.ConsigneeStBldg.trim(),
        ShipperName: item.Shipper, // ShipperName: item.ContactPerson,
        ShipperAddress:
          userInfo && userInfo.accountTypeTag === 1
            ? senderFullAddress
            : senderFullAddress + " " + item.ShipperMobileNumber, // item.ShipperStBldg.trim(),
        NoOfItems: item.Quantity,
        TransactionDate: item.TransactionDate,
        DeliveryRequirement: item.DeliveryRequirements,
        SpecialInstructions: item.SpecialInstructions,
        DRNo: item.Description,
        DRNo2: "",
        Origin: item.Origin,
        // DeclaredValue: item.DeclaredValue,
        Destination: item.Destination,
        VolumeWt: `${parseInt(item.VolWTcbm, 0)}`,
        LWH: `${parseInt(item.LengthCM, 0)},${parseInt(
          item.WidthCM,
          0
        )},${parseInt(item.HeightCM, 0)}`,
        ActualWt: `${parseInt(item.ACTWTkgs, 0)}`,
        ChargeableWT: `${parseInt(item.ChargeableWT, 0)}`,
        PayMode: item.PaymentMode,
        ServiceMode: item.ServiceMode,
        ShipmentMode: item.ShipmentMode,
        Product: item.ProductName,
        ProductName: item.ProdDisplayName,
        TotalFreightCharge: item.EstimatedFreightRate,
        Amount: `${
          item.ReferenceNoFour === "HOME DELIVERY" ? "COD" : "COP"
        } Amount: {Number(item.CODAmount)
          .toFixed(2)
          .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`,
        Barcode2D: "",
        CreatedBy: "",
        PrintedBy: "",
      };

      podItems.push(reqData);
    });

    try {
      const response = await axiosLexaApiPrivate.post(
        "/GenerateMultiplePOD",
        podItems
      );
      const resData = response.data;

      if (resData && resData !== null) {
        const pdfByte = resData.Rendered_Byte;
        const byteCharacters = atob(pdfByte);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        const urlFinal = url;

        if (urlFinal !== undefined && urlFinal !== "") {
          window.open(urlFinal, "_blank");
        }
      }

      setLoading2(false);
    } catch (error) {
      setToastKind("error");
      setToastTitle("API Fetch Failed!");
      setToastDesc(error.message);
      setTimeout(() => {
        setLoading2(false);
      }, 1000);
      setTimeout(() => {
        setToastOpen(true);
      }, 1500);
      setTimeout(() => {
        setToastOpen(false);
      }, 5000);
    }
  };

  return (
    <>
      {loading2 && <Loading />}

      <LBCModal
        id="alertModal"
        description={toastDesc}
        isOpen={isToastOpen}
        onClose={() => setToastOpen(false)}
        title={toastTitle}
        toastKind={toastKind}
      />

      <Div className="w-full ">
        <Spacer className="w-full h-10" />
        <Div className="w-full border border-grey-400 rounded-lg shadow-lg">
          <FlexRow className="items-center justify-between py-2 pl-2 border border-grey-400">
            {/* <Text className="text-secondary-100 text-xs text-left font-semibold w-32 px-2 py-2">
              Booking No.
            </Text> */}
            <ColumnHeader
              title="Booking No."
              onClick={() => onSort(0)}
              containerClass="justify-start hover:bg-grey-400 rounded-full w-32 px-2 py-2"
              titleClassName=""
            />
            <ColumnHeader
              title="Booking Date"
              onClick={() => onSort(1)}
              containerClass="justify-start hover:bg-grey-400 rounded-full w-32 px-2 py-2"
              titleClassName=""
            />
            <ColumnHeader
              title="Shipper"
              onClick={() => onSort(2)}
              containerClass="justify-start hover:bg-grey-400 rounded-full w-1/6 px-2 py-2"
              titleClassName=""
            />
            <ColumnHeader
              title="Pick-up Address"
              onClick={() => onSort(3)}
              containerClass="justify-start hover:bg-grey-400 rounded-full w-1/6 px-2 py-2"
              titleClassName=""
            />
            <ColumnHeader
              title="Type"
              onClick={() => onSort(4)}
              containerClass="justify-start hover:bg-grey-400 rounded-full w-32 px-2 py-2"
              titleClassName=""
            />
            <ColumnHeader
              title="Preferred Pick-up Date"
              onClick={() => onSort(5)}
              containerClass="justify-start hover:bg-grey-400 rounded-full w-32 px-2 py-2"
              titleClassName="text-left"
            />
          </FlexRow>
          {loading ? (
            <Text className="text-red-400 text-center flex flex-row justify-center items-center my-20">
              <ImSpinner2 className="animate-spin mr-2 text-2xl desktop:text-3xl" />
              Loading data ...
            </Text>
          ) : (
            <Div className="w-full">
              {newItems.map((item) => (
                <ActivityDataRow
                  data={item}
                  // onSelect={setSingleView}
                  // diableDelete={true}
                  // diableEdit={true}
                  onBookingNoClick={printAirWaybill}
                />
              ))}
            </Div>
          )}
        </Div>
        <Spacer className="w-full h-10" />
        <Div className="w-full">
          <Pagination
            currentPage={currentPage}
            itemsPerPage={articlesPerPage}
            paginate={paginate}
            totalItems={bookingDetails.length}
          />
        </Div>
      </Div>
    </>
  );
};
