import React from "react";
import { Div, FlexRow } from "../../core/Containers";
import { Text } from "../../core/Text";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaRegEdit } from "react-icons/fa";
import moment from "moment";
import { RawButton } from "../../core/Buttons";

const numbersRegx = /^[0-9]*$/; // for Mobile Number
const mobilenumberRegex = /^(9)\d{9}$/; // mobile number validation regex
const emailRegex =
  /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // email validation regex

export const BookNowDataRow = ({ item, onDeleteClick, onEditClick }) => {
  const senderAddress = item.ShipperStBldg.split(",");
  const senderUnit = senderAddress[0] ? senderAddress[0].trim() : "";
  const senderStreet = senderAddress[1] ? senderAddress[1].trim() : "";
  const senderLandmark = senderAddress[2] ? senderAddress[2].trim() : "";

  const receiverAddress = item.ConsigneeStBldg.split(",");
  const receiverUnit = receiverAddress[0] ? receiverAddress[0].trim() : "";
  const receiverStreet = receiverAddress[1] ? receiverAddress[1].trim() : "";
  const receiverLandmark = receiverAddress[2] ? receiverAddress[2].trim() : "";

  // const isValidPkg = !(
  //   item.ReferenceNoThree === "RIDER PICK UP" &&
  //   (Number(item.ProdId) === 164 || Number(item.ProdId) === 165)
  // );

  const isValidPkg = true;

  const closeSuccessAlert = (itemId) => {
    const tRowAlertSuccess = document.getElementById(`updated-${itemId}`);
    if (tRowAlertSuccess) tRowAlertSuccess.remove();
  };

  return (
    <>
      {item.Remarks === "INVALID" && (
        <FlexRow
          id={`invalid-${item.Id}`}
          className="items-center w-3000px bg-red-80 border-t-4 border-red-400 rounded-b text-secondary-100 px-4 py-1 shadow-md"
          role="alert"
        >
          <Div className="flex items-center">
            <Div className="py-1">
              <svg
                className="fill-current h-6 w-6 text-red-400 mr-2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
              </svg>
            </Div>
            <FlexRow>
              {item.ShipperBrgy === "" ||
              item.ShipperCityMunicipality === "" ||
              item.ShipperProvince === "" ? (
                <>
                  <p className="text-sm mr-2">
                    {
                      "Pickup address is not serviceable. Please contact your Account Manager or click"
                    }
                  </p>
                  {onEditClick ? (
                    <RawButton
                      className="text-sm text-blue-400 mr-2"
                      onClick={() => onEditClick(item)}
                    >
                      Edit
                    </RawButton>
                  ) : (
                    <RawButton className="text-sm text-grey-100 mr-2">
                      Edit
                    </RawButton>
                  )}
                  <p className="text-sm mr-2">{"to change."}</p>
                </>
              ) : (
                <>
                  <p className="text-sm mr-2">
                    {
                      "You’ve entered incorrect details. Please edit and try again."
                    }
                  </p>
                  {onEditClick ? (
                    <RawButton
                      className="text-sm text-blue-400"
                      onClick={() => onEditClick(item)}
                    >
                      Edit
                    </RawButton>
                  ) : (
                    <RawButton className="text-sm text-grey-100">
                      Edit
                    </RawButton>
                  )}
                </>
              )}
            </FlexRow>
          </Div>
        </FlexRow>
      )}
      {item.Remarks === "UPDATED" && (
        <FlexRow
          id={`updated-${item.Id}`}
          className="items-center w-3000px bg-green-80 border-t-4 border-green-400 rounded-b text-secondary-100 px-4 py-1 shadow-md"
          role="alert"
        >
          <Div className="flex items-center">
            <Div className="py-1">
              <svg
                className="fill-current h-6 w-6 text-green-400 mr-2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 22 22"
              >
                <path d="M20,12A8,8 0 0,1 12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4C12.76,4 13.5,4.11 14.2, 4.31L15.77,2.74C14.61,2.26 13.34,2 12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0, 0 22,12M7.91,10.08L6.5,11.5L11,16L21,6L19.59,4.58L11,13.17L7.91,10.08Z" />
              </svg>
            </Div>
            <FlexRow>
              <p className="text-sm mr-2">{"Record successfully updated."}</p>
              <RawButton
                className="text-sm text-blue-400"
                onClick={() => closeSuccessAlert(item.Id)}
              >
                Close
              </RawButton>
            </FlexRow>
          </Div>
        </FlexRow>
      )}
      <FlexRow className="items-start justify-between w-3000px py-2 hover:bg-grey-400">
        <Text className="text-secondary-100 text-sm w-200px px-4">
          {item.ReferenceNoThree === "RIDER PICK UP" ? "Pick Up" : "Drop Off"}
        </Text>
        <Text className="text-secondary-100 text-sm w-200px px-4">
          {item.TrackingNo}
          {(item.TrackingNo.trim() === "" ||
            item.TrackingNo.trim() === " ") && (
            <p className="text-xs text-left text-red-400 dark:text-red-400">
              Tracking Number not supplied
            </p>
          )}
        </Text>
        <Div className="w-200px px-4">
          {/* <Text className="text-secondary-100 text-sm font-semibold">
            {item.Shipper}
            {(item.Shipper.trim() === "" || item.Shipper.trim() === " ") && (
              <p className="text-xs text-left text-red-400 dark:text-red-400">
                Shipper Name not supplied
              </p>
            )}
          </Text> */}
          <Text className="text-secondary-100 text-sm font-semibold">
            {item.ContactPerson}
            {(item.ContactPerson.trim() === "" ||
              item.ContactPerson.trim() === " ") && (
              <p className="text-xs text-left text-red-400 dark:text-red-400">
                Contact Name not supplied
              </p>
            )}
          </Text>
          <Text className="text-secondary-100 text-xs">
            {`+63 ${item.ShipperMobileNumber}`}
            {(!numbersRegx.test(item.ShipperMobileNumber) ||
              !mobilenumberRegex.test(item.ShipperMobileNumber)) && (
              <p className="text-xs text-left text-red-400 dark:text-red-400">
                Invalid Mobile Number
              </p>
            )}
          </Text>
          {item.ReferenceNoThree === "RIDER PICK UP" ? (
            <>
              <Text className="text-secondary-100 text-xs">
                {`${senderUnit} ${senderStreet}, ${item.ShipperBrgy}, ${item.ShipperCityMunicipality}, ${item.ShipperProvince}`}
                {senderUnit === "" && (
                  <p className="text-xs text-left text-red-400 dark:text-red-400">
                    Floor/Unit No. not supplied
                  </p>
                )}
                {/* {senderStreet === "" && (
                  <p className="text-xs text-left text-red-400 dark:text-red-400">
                    Street/Building not supplied
                  </p>
                )} */}
                {item.ShipperBrgy === "" && (
                  <p className="text-xs text-left text-red-400 dark:text-red-400">
                    Invalid Barangay
                  </p>
                )}
                {item.ShipperCityMunicipality === "" && (
                  <p className="text-xs text-left text-red-400 dark:text-red-400">
                    Invalid City/Municipality
                  </p>
                )}
                {item.ShipperProvince === "" && (
                  <p className="text-xs text-left text-red-400 dark:text-red-400">
                    Invalid Province
                  </p>
                )}
              </Text>
              <Text className="text-secondary-100 text-xs">
                Pick Up Date:{" "}
                <span className="text-orange-400">
                  {moment(item.PickupDate).format("M/D/yyyy")}
                </span>
              </Text>
            </>
          ) : (
            <Text className="text-secondary-100 text-xs">
              {`${item.ShipperCityMunicipality}, ${item.ShipperProvince}`}
              {item.ShipperCityMunicipality === "" && (
                <p className="text-xs text-left text-red-400 dark:text-red-400">
                  Invalid City/Municipality
                </p>
              )}
              {item.ShipperProvince === "" && (
                <p className="text-xs text-left text-red-400 dark:text-red-400">
                  Invalid Province
                </p>
              )}
            </Text>
          )}
        </Div>
        <Text className="text-secondary-100 text-sm w-200px px-4">
          {item.ReferenceNoFour === "HOME DELIVERY"
            ? "Rider Delivery"
            : "Branch Pick Up"}
        </Text>
        <Div className="w-200px px-4">
          <Text className="text-secondary-100 text-sm font-semibold">
            {item.Consignee}
            {(item.Consignee.trim() === "" ||
              item.Consignee.trim() === " ") && (
              <p className="text-xs text-left text-red-400 dark:text-red-400">
                Consignee Name not supplied
              </p>
            )}
          </Text>
          <Text className="text-secondary-100 text-xs">
            {`+63 ${item.ConsigneeMobileNumber}`}
            {(!numbersRegx.test(item.ConsigneeMobileNumber) ||
              !mobilenumberRegex.test(item.ConsigneeMobileNumber)) && (
              <p className="text-xs text-left text-red-400 dark:text-red-400">
                Invalid Mobile Number
              </p>
            )}
          </Text>
          {item.ReferenceNoFour === "HOME DELIVERY" ? (
            <Text className="text-secondary-100 text-xs">
              {`${receiverUnit} ${receiverStreet}, ${item.ConsigneeBrgy}, ${item.ConsigneeCityMunicipality}, ${item.ConsigneeProvince}`}
              {receiverUnit === "" && (
                <p className="text-xs text-left text-red-400 dark:text-red-400">
                  Floor/Unit No. not supplied
                </p>
              )}
              {/* {receiverStreet === "" && (
                <p className="text-xs text-left text-red-400 dark:text-red-400">
                  Street/Building not supplied
                </p>
              )} */}
              {(item.ConsigneeBrgy === "" || item.ConsigneeBrgyId === "0") && (
                <p className="text-xs text-left text-red-400 dark:text-red-400">
                  Invalid Barangay
                </p>
              )}
              {item.ConsigneeCityMunicipality === "" && (
                <p className="text-xs text-left text-red-400 dark:text-red-400">
                  Invalid City/Municipality
                </p>
              )}
              {item.ConsigneeProvince === "" && (
                <p className="text-xs text-left text-red-400 dark:text-red-400">
                  Invalid Province
                </p>
              )}
            </Text>
          ) : (
            <Text className="text-secondary-100 text-xs">
              {`${item.ConsigneeBrgy}, ${item.ConsigneeCityMunicipality}, ${item.ConsigneeProvince}`}
              {(item.ConsigneeBrgy === "" || item.ConsigneeBrgyId === "0") && (
                <p className="text-xs text-left text-red-400 dark:text-red-400">
                  Invalid Branch
                </p>
              )}
              {item.ConsigneeCityMunicipality === "" && (
                <p className="text-xs text-left text-red-400 dark:text-red-400">
                  Invalid City/Municipality
                </p>
              )}
              {item.ConsigneeProvince === "" && (
                <p className="text-xs text-left text-red-400 dark:text-red-400">
                  Invalid Province
                </p>
              )}
            </Text>
          )}
        </Div>
        <Text className="text-secondary-100 text-sm w-200px px-4">
          {item.Description}
          {item.Description === "" && (
            <p className="text-xs text-left text-red-400 dark:text-red-400">
              No entry
            </p>
          )}
        </Text>
        <Text className="text-secondary-100 text-sm w-200px px-4">
          {Number(item.DeclaredValue)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
          {(item.DeclaredValue < 100 || item.DeclaredValue > 100000) && (
            <p className="text-xs text-left text-red-400 dark:text-red-400">
              Item Value must be minimum of Php100.00 and maximum of
              Php100,000.00
            </p>
          )}
        </Text>
        <Text className="text-secondary-100 text-sm w-200px px-4">
          {item.ShipmentMode === "AIR" ? "Air" : "Sea"}
        </Text>
        <Text className="text-secondary-100 text-sm w-200px px-4">{`${Number(
          item.ACTWTkgs
        )}kg`}</Text>
        <Div className="w-200px px-4">
          <Text className="text-secondary-100 text-sm">{`L: ${Number(
            item.LengthCM
          )}cm x`}</Text>
          <Text className="text-secondary-100 text-sm">{`W: ${Number(
            item.WidthCM
          )}cm x`}</Text>
          <Text className="text-secondary-100 text-sm">{`H: ${Number(
            item.HeightCM
          )}cm`}</Text>
        </Div>
        <Text className="text-secondary-100 text-sm w-200px px-4">
          {item.ProdDisplayName}
          {item.ProdDisplayName === "" && (
            <p className="text-xs text-left text-red-400 dark:text-red-400">
              No entry/Incorrect Packaging
            </p>
          )}
          {!isValidPkg && (
            <p className="text-xs text-left text-red-400 dark:text-red-400">
              Incorrect Packaging
            </p>
          )}
        </Text>
        <Text className="text-secondary-100 text-sm w-200px px-4">
          {senderLandmark}
        </Text>
        <Text className="text-secondary-100 text-sm w-200px px-4">
          {Number(item.EstimatedFreightRate)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
        </Text>
        <Text className="text-secondary-100 text-sm w-200px px-4">
          {Number(item.CODAmount)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
        </Text>
        <FlexRow className="items-center justify-center w-200px">
          {onDeleteClick ? (
            <RiDeleteBin6Line
              className="text-red-400 text-xl mr-2 cursor-pointer"
              onClick={() => onDeleteClick(item)}
            />
          ) : (
            <RiDeleteBin6Line className="text-red-400 text-xl mr-2 cursor-pointer" />
          )}
          {onEditClick ? (
            <FaRegEdit
              className="text-red-400 text-xl ml-2 cursor-pointer"
              onClick={() => onEditClick(item)}
            />
          ) : (
            <FaRegEdit className="text-red-400 text-xl ml-2 cursor-pointer" />
          )}
        </FlexRow>
      </FlexRow>
    </>
  );
};
