import React, { useRef, useState } from "react";
import { Cookies } from "react-cookie";
import {
  Div,
  Divider,
  FlexColumn,
  FlexRow,
  Spacer,
} from "../../../core/Containers";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { Button, RawButton } from "../../../core/Buttons";
import { AiOutlineDownload } from "react-icons/ai";
import { LBCModal } from "../../../components/Modals/LBCModal";
import { Text } from "../../../core/Text";
import { ImSpinner2 } from "react-icons/im";
import moment from "moment";
import { Pagination } from "../../../components/Tables/Pagination";
import { ColumnHeader } from "../../../components/Tables/ColumnHeader";
import { ReportsDeliveryDataTableRow } from "../../../components/Tables/DeliveryDataRow";
import * as FileSaver from "file-saver";
import axiosCAPApi from "../../../api/axiosCAPApi";
import axiosCAPApiPrivate from "../../../api/axiosCAPApiPrivate";
import Modal from "../../../components/Modals/DownloadExcelModal";

const cookies = new Cookies();

export const DeliveryReport = ({
  reportFilter,
  reportDateFrom,
  reportDateTo,
}) => {
  const navigate = useNavigate();
  const dataFetchedRef = useRef(false);
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const actionQuery = query.get("option");

  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const tabs = ["Delivery Report"];

  const [sortBy, setSort] = React.useState("SHIPMENTS BY LAST STATUS");
  const [timeline, setTimeline] = React.useState("Daily");
  const [deliveryStatusColumn, setDeliveryStatusColumn] = React.useState("");

  const [loading, setLoading] = React.useState(false);
  const [selectedList, setSelectedList] = React.useState([]);

  const [currentPage, setCurrentPage] = React.useState(1);
  const [articlesPerPage] = React.useState(5);
  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const [deliveryReportList, setDeliveryReportList] = React.useState([]);
  const newList = deliveryReportList.slice(
    indexOfFirstArticle,
    indexOfLastArticle
  );

  const [sortList, setSortList] = React.useState([]);
  // const [currentPage, setCurrentPage] = React.useState(1);
  // const [articlesPerPage] = React.useState(5);
  // const indexOfLastArticle = currentPage * articlesPerPage;
  // const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const newItems = sortList.slice(indexOfFirstArticle, indexOfLastArticle);

  const [isToastOpen, setToastOpen] = React.useState(false);
  const [toastKind, setToastKind] = React.useState("");
  const [toastTitle, setToastTitle] = React.useState("");
  const [toastDesc, setToastDesc] = React.useState("");

  const [shipperAcctName, setShipperAcctName] = React.useState("");
  const [shipperAcctNo, setShipperAcctNo] = React.useState("");

  const [filter, setFilter] = React.useState([]);
  const [dateFrom, setDateFrom] = React.useState(
    moment(new Date()).format("yyyy-MM-DD")
  );
  const [dateTo, setDateTo] = React.useState(
    moment(new Date()).format("yyyy-MM-DD")
  );

  const [DeliveredExcelList, setDeliveredExcelList] = React.useState([]);
  const [TotalExcelList, setTotalExcelList] = React.useState([]);
  const [ForDispositionExcelList, setForDispositionExcelList] = React.useState(
    []
  );
  const [InTransitExcelList, setInTransitExcelList] = React.useState([]);
  const [ReturnedExcelList, setReturnedExcelList] = React.useState([]);
  const [OthersExcelList, setOthersExcelList] = React.useState([]);

  // New Params Gilbert
  const [reportGenerated, setReportGenerated] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState(false);

  React.useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    var _shipperAcct = cookies.get("shipperAcct", { path: "/" });
    var _shipperAcctSplit = _shipperAcct ? _shipperAcct.split("|") : [];
    var _shipperAcctName = _shipperAcctSplit[0]
      ? _shipperAcctSplit[0].toString().trim()
      : "";
    var _shipperAcctNo = _shipperAcctSplit[1]
      ? _shipperAcctSplit[1].toString().trim()
      : "";

    setShipperAcctName(_shipperAcctName);
    setShipperAcctNo(_shipperAcctNo);

    setFilter(reportFilter);
    setDateFrom(
      reportDateFrom !== ""
        ? moment(reportDateFrom).format("yyyy-MM-DD")
        : moment(new Date()).format("yyyy-MM-DD")
    );
    setDateTo(
      reportDateTo !== ""
        ? moment(reportDateTo).format("yyyy-MM-DD")
        : moment(new Date()).format("yyyy-MM-DD")
    );

    if (reportDateFrom !== "" && reportDateTo !== "") {
      GenerateDeliveryReport(
        _shipperAcctNo,
        moment(reportDateFrom).format("yyyy-MM-DD"),
        moment(reportDateTo).format("yyyy-MM-DD")
      );
    }
  }, []);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const getGetDeliveryReportDaily = async (shipperAcctNo, dateFrom, dateTo) => {
    setLoading(true);

    try {
      const response = await axiosCAPApiPrivate.post("/api/GetDeliveryReportDaily", {
        CustomerAccountNo: shipperAcctNo,
        FromDate: dateFrom,
        ToDate: dateTo,
      });

      const data = response.data;

      if (data.Code === "200") {
        setLoading(false);
        setDeliveryReportList(data.Data);
        console.log(data.Data);
      }
      setLoading(false);
    } catch (error) {
      setToastKind("error");
      setToastTitle("Delivery Status Failed!");
      setToastDesc(error.message);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setTimeout(() => {
        setToastOpen(true);
      }, 1500);
      setTimeout(() => {
        setToastOpen(false);
      }, 3000);
    }
  };

  const getDeliveryStatusSummaryForCurrentMonth = async () => {
    setLoading(true);

    try {
      const response = await axiosCAPApiPrivate.post(
        "/api/GetDeliveryStatusSummaryForCurrentMonth",
        {
          CustomerAccountNo: shipperAcctNo,
          ReportRange: timeline,
        }
      );

      const data = response.data;

      if (data.Code === "200") {
        setLoading(false);
        setDeliveryReportList(data.Data);
        console.log(data.Data);
      }
      setLoading(false);
    } catch (error) {
      setToastKind("error");
      setToastTitle("Delivery Status Failed!");
      setToastDesc(error.message);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setTimeout(() => {
        setToastOpen(true);
      }, 1500);
      setTimeout(() => {
        setToastOpen(false);
      }, 3000);
    }
  };

  React.useEffect(() => {
    if (actionQuery === "0") {
      setSelectedIndex(0);
      navigate("/reports");
    } else if (actionQuery === "1") {
      setSelectedIndex(1);
      navigate("/reports");
    } else if (actionQuery === "2") {
      setSelectedIndex(2);
      navigate("/reports");
    }
  }, [actionQuery, navigate]);

  const GenerateDeliveryReport = async (shipperAcctNo, dateFrom, dateTo) => {
    setLoading(true);
    setReportGenerated(false);

    try {
      setReportGenerated(true);
      console.log("Report generated state: ", reportGenerated);
      
      if (timeline === "Monthly") {
        await getDeliveryStatusSummaryForCurrentMonth();
      } else if (timeline === "Daily") {
        await getGetDeliveryReportDaily(shipperAcctNo, dateFrom, dateTo);
      }

      await Promise.all([
        GetTotalExcelDetail(),
        GetDeliveredExcelDetail(),
        GetForDispositionExcelDetail(),
        GetInTransitExcelDetail(),
        GetReturnedExcelDetail(),
        GetOthersExcelDetail(),
      ]);

      // setModalMessage('Report Generated and ready for Export!');
      // setModalOpen(true);

    } catch (error) {

    } finally {
      setLoading(false);
    }
    // if (timeline === "Monthly") getDeliveryStatusSummaryForCurrentMonth();
    // else if (timeline === "Daily") {
    //   getGetDeliveryReportDaily(shipperAcctNo, dateFrom, dateTo);
    // }

    // GetDeliveredExcelDetail();
    // GetForDispositionExcelDetail();
    // GetInTransitExcelDetail();
    // GetReturnedExcelDetail();
    // GetOthersExcelDetail();
  };

  const onDispoChange = (tracking, value) => {
    const newSortList = sortList.map((item) => {
      if (item.tracking === tracking) {
        return { ...item, disposition: value };
      }
      return item;
    });

    setSortList(newSortList);
  };

  const getDeliveryReport = async (column) => {
    setDeliveryStatusColumn(column);
    setLoading(true);
    var status = "";
    if (column === "In-transit") {
      status = "ForUpdate";
    } else if (column === "For Disposition") {
      status = "Pending";
    } else if (column === "Delivered") {
      status = "Delivered";
    } else if (column === "Returned") {
      status = "RTS";
    } else if (column === "Others") {
      status = "LOST";
    } else if (column === "ViewAll") {
      status = "ViewAll";
    }

    try {
      const response = await axiosCAPApiPrivate.post("/api/GetReportDetail", {
        CustomerAccountNo: shipperAcctNo,
        Column: status,
        FromDate:
          status == "ViewAll"
            ? moment(new Date(dateFrom)).format("yyyy-MM")
            : dateFrom,
        ToDate: dateTo,
      });

      const data = response.data;

      if (data.Code === "200") {
        setSortList(data.Data);
        var refusedToAcceptCount = 0;
        refusedToAcceptCount += data.Data.filter((x) =>
          x.status.toUpperCase().includes("REFUSE")
        ).length;

        var incompleteIncorrectAddressCount = 0;
        incompleteIncorrectAddressCount += data.Data.filter((x) =>
          x.status.toUpperCase().includes("INCOMPLETE")
        ).length;
        incompleteIncorrectAddressCount += data.Data.filter((x) =>
          x.status.toUpperCase().includes("INCORRECT")
        ).length;

        var consigneeUnknownCount = 0;
        consigneeUnknownCount += data.Data.filter((x) =>
          x.status.toUpperCase().includes("UNKNOWN")
        ).length;

        console.log(data.Data);

        //setRefusedToAccept(refusedToAcceptCount);
        //setIncompleteIncorrectAddress(incompleteIncorrectAddressCount);
        //setConsigneeUnknown(consigneeUnknownCount);
      }
      setLoading(false);
    } catch (error) {
      setToastKind("error");
      setToastTitle("Delivery Report Failed!");
      setToastDesc(error.message);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setTimeout(() => {
        setToastOpen(true);
      }, 1500);
      setTimeout(() => {
        setToastOpen(false);
      }, 3000);
    }
  };

  const GetTotalExcelDetail = async () => {
    try {
      const response = await axiosCAPApiPrivate.post("api/GetReportDetail", {
        CustomerAccountNo: shipperAcctNo,
        Column: "Total",
        FromDate:
          timeline === "Monthly"
            ? moment(new Date()).startOf("month").format("YYYY-MM-DD")
            : dateFrom,
        ToDate:
          timeline === "Monthly"
            ? moment(new Date()).endOf("month").format("YYYY-MM-DD")
            : dateTo,
      });

      const data = response.data;

      if (data.Code === "200") {
        console.log("Total Delivery Reports:", data.Data);
        setTotalExcelList(data.Data);
      }

    } catch (error) {}
  };

  const GetDeliveredExcelDetail = async () => {
    try {
      const response = await axiosCAPApiPrivate.post("/api/GetReportDetail", {
        CustomerAccountNo: shipperAcctNo,
        Column: "Delivered",
        FromDate:
          timeline === "Monthly"
            ? moment(new Date()).startOf("month").format("YYYY-MM-DD")
            : dateFrom,
        ToDate:
          timeline === "Monthly"
            ? moment(new Date()).endOf("month").format("YYYY-MM-DD")
            : dateTo,
      });

      const data = response.data;

      if (data.Code === "200") {
        setDeliveredExcelList(data.Data);
      }
    } catch (error) {}
  };

  const GetForDispositionExcelDetail = async () => {
    try {
      const response = await axiosCAPApiPrivate.post("/api/GetReportDetail", {
        CustomerAccountNo: shipperAcctNo,
        Column: "Pending",
        FromDate:
          timeline === "Monthly"
            ? moment(new Date()).startOf("month").format("YYYY-MM-DD")
            : dateFrom,
        ToDate:
          timeline === "Monthly"
            ? moment(new Date()).endOf("month").format("YYYY-MM-DD")
            : dateTo,
      });

      const data = response.data;

      if (data.Code === "200") {
        setForDispositionExcelList(data.Data);
      }
    } catch (error) {}
  };

  const GetInTransitExcelDetail = async () => {
    try {
      const response = await axiosCAPApiPrivate.post("/api/GetReportDetail", {
        CustomerAccountNo: shipperAcctNo,
        Column: "ForUpdate",
        FromDate:
          timeline === "Monthly"
            ? moment(new Date()).startOf("month").format("YYYY-MM-DD")
            : dateFrom,
        ToDate:
          timeline === "Monthly"
            ? moment(new Date()).endOf("month").format("YYYY-MM-DD")
            : dateTo,
      });

      const data = response.data;

      if (data.Code === "200") {
        setInTransitExcelList(data.Data);
      }
    } catch (error) {}
  };

  const GetReturnedExcelDetail = async () => {
    try {
      const response = await axiosCAPApiPrivate.post("/api/GetReportDetail", {
        CustomerAccountNo: shipperAcctNo,
        Column: "RTS",
        FromDate:
          timeline === "Monthly"
            ? moment(new Date()).startOf("month").format("YYYY-MM-DD")
            : dateFrom,
        ToDate:
          timeline === "Monthly"
            ? moment(new Date()).endOf("month").format("YYYY-MM-DD")
            : dateTo,
      });

      const data = response.data;

      if (data.Code === "200") {
        setReturnedExcelList(data.Data);
      }
    } catch (error) {}
  };

  const GetOthersExcelDetail = async () => {
    try {
      const response = await axiosCAPApiPrivate.post("/api/GetReportDetail", {
        CustomerAccountNo: shipperAcctNo,
        Column: "LOST",
        FromDate:
          timeline === "Monthly"
            ? moment(new Date()).startOf("month").format("YYYY-MM-DD")
            : dateFrom,
        ToDate:
          timeline === "Monthly"
            ? moment(new Date()).endOf("month").format("YYYY-MM-DD")
            : dateTo,
      });

      const data = response.data;

      if (data.Code === "200") {
        setOthersExcelList(data.Data);
      }
    } catch (error) {}
  };

  //const exportToCSV = () => {
  //  alert(deliveryStatusColumn);
  //};

  const exportToCSV = () => {
    const Excel = require("exceljs");
    const workbook = new Excel.Workbook();

    const totalDelivered = deliveryReportList.reduce((n, { Delivered }) => n + Delivered, 0).toLocaleString();
    const totalPending = deliveryReportList.reduce((n, {Pending}) => n + Pending, 0).toLocaleString();
    const totalUpdate = deliveryReportList.reduce((n, {ForUpdate}) => n + ForUpdate, 0).toLocaleString();
    const totalReturned = deliveryReportList.reduce((n, {RTS}) => n + RTS, 0).toLocaleString();
    const totalLost = deliveryReportList.reduce((n, {Lost}) => n + Lost, 0).toLocaleString(); 
    const totalTransaction = deliveryReportList.reduce((n, {Total}) => n + Total, 0).toLocaleString();

    const summaryReport = workbook.addWorksheet("Summary");
    summaryReport.insertRow(1, "");
    summaryReport.getCell("A1").value = "Delivery Report Status";
    summaryReport.getCell("A1").font = { bold: true };
    summaryReport.getCell("B1").value = "Start Date";
    summaryReport.getCell("C1").value = "End Date";
    summaryReport.insertRow(2, "");
    summaryReport.getCell("A2").value = "Coverage";
    summaryReport.getCell("B2").value = `${dateFrom}`;
    summaryReport.getCell("C2").value = `${dateTo}`;
    
    summaryReport.insertRow(4, "");
    summaryReport.getCell("A4").value = "Status";
    summaryReport.getCell("A4").font = { bold: true };

    summaryReport.insertRow(5, "");
    summaryReport.getCell("A5").value = "Delivered";
    summaryReport.getCell("B5").value = totalDelivered; // Set total in column B
    summaryReport.insertRow(6, "");
    summaryReport.getCell("A6").value = "Pending/For Dispostion";
    summaryReport.getCell("B6").value = totalPending; // Set total in column B
    summaryReport.insertRow(7, "");
    summaryReport.getCell("A7").value = "For Update";
    summaryReport.getCell("B7").value = totalUpdate; // Set total in column B
    summaryReport.insertRow(8, "");
    summaryReport.getCell("A8").value = "Returned to Shipper";
    summaryReport.getCell("B8").value = totalReturned; // Set total in column B
    summaryReport.insertRow(9, "");
    summaryReport.getCell("A9").value = "Lost";
    summaryReport.getCell("B9").value = totalLost; // Set total in column B
    summaryReport.insertRow(10, "");
    summaryReport.getCell("A10").value = "Total Transaction";
    summaryReport.getCell("B10").value = totalTransaction; // Set total in column B
    summaryReport.getCell("A10").font = { bold: true };
    summaryReport.insertRow(11, "");

    // Set Columns widths
    summaryReport.columns = [
      { width: 30 },
      { width: 20 },
      { width: 20 }
    ];

    //#region Delivery Report
    const deliveryreport = workbook.addWorksheet("Delivery Report");
    deliveryreport.columns = [
      { header: "Tracking No.", key: "tracking", width: 15 },
      { header: "Encoded Date", key: "encodedDate", width: 20 },
      { header: "Delivery Requirement", key: "deliveryReq", width: 25 },
      { header: "Consignee Name", key: "consigneeName", width: 35 },
      // { header: "Consignee Mobile", key: "consigneeContact", width: 18 },
      { header: "Consignee Address", key: "consigneeAddress", width: 50 },
      { header: "Consignee Mobile No.", key: "consigneeContact", width: 22 },
      { header: "Acceptance Date", key: "acceptanceDate", width: 20 },
      { header: "Last Status", key: "status", width: 30 },
      { header: "Last Status Date", key: "statusDate", width: 15 },
      { header: "Item Name", key: "itemName", width: 15 },
      { header: "Item Value", key: "itemValue", width: 15 },
      { header: "COP/COD Amount", key: "codCopAmount", width: 15 },
      { header: "Freight Amount", key: "freightAmount", width: 15 },
      { header: "Reason", key: "reason", width: 30 },
      { header: "Status", key: "fullFilledStatus", width: 15 },
    ];

    deliveryreport.insertRow(1, "");
    deliveryreport.insertRow(2, "");
    deliveryreport.getCell("A2").value = "DELIVERY REPORT";
    deliveryreport.getCell("A2").font = { bold: true };
    deliveryreport.insertRow(3, "");
    deliveryreport.mergeCells("A2:B2");
    deliveryreport.getRow(4).font = { bold: true };
    addBorder(deliveryreport, 4);

    var row = 5;
    TotalExcelList.map((list, index) => {
      deliveryreport.addRow({
        tracking: list.tracking,
        encodedDate: list.encodedDate,
        deliveryReq: list.deliveryReq,
        consigneeName: list.consigneeName,
        consigneeAddress: list.consigneeAddress,
        consigneeContact: list.consigneeContact,
        acceptanceDate: list.acceptanceDate,
        status: list.status,
        statusDate: list.statusDate,
        itemName: list.itemName,
        itemValue: list.DeclareValue,
        codCopAmount: list.collectedAmount,
        freightAmount: list.TotalFreight,
        reason: list.reason,
        FulfilledStatus: list.FulfilledStatus
      });
      addBorder(deliveryreport, row);
      row++;
    });
    //#endregion Delivery Report

    //#region For Disposition
    const forDisposition = workbook.addWorksheet("Pending for Disposition");
    forDisposition.columns = deliveryreport.columns;
    forDisposition.insertRow(1, "");
    forDisposition.insertRow(2, "");
    forDisposition.getCell("A2").value = "FOR DISPOSITION";
    forDisposition.getCell("A2").font = { bold: true };
    forDisposition.insertRow(3, "");
    forDisposition.mergeCells("A2:B2");
    forDisposition.getRow(4).font = { bold: true };
    addBorder(forDisposition, 4);

    var row = 5;
    ForDispositionExcelList.map((list, index) => {
      forDisposition.addRow({
        tracking: list.tracking,
        encodedDate: list.encodedDate,
        deliveryReq: list.deliveryReq,
        consigneeName: list.consigneeName,
        consigneeAddress: list.consigneeAddress,
        consigneeContact: list.consigneeContact,
        acceptanceDate: list.acceptanceDate,
        status: list.status,
        statusDate: list.statusDate,
        itemName: list.itemName,
        itemValue: list.DeclareValue,
        codCopAmount: list.collectedAmount,
        freightAmount: list.TotalFreight,
        reason: list.reason,
        FulfilledStatus: list.FulfilledStatus
      });
      addBorder(forDisposition, row);
      row++;
    });
    //#endregion For Disposition

    //#endregion for Delivered
    const inDelivered = workbook.addWorksheet("Delivered");
    inDelivered.columns = deliveryreport.columns;
    inDelivered.insertRow(1, "");
    inDelivered.insertRow(2, "");
    inDelivered.getCell("A2").value = "DELIVERED REPORT";
    inDelivered.getCell("A2").font = { bold: true };
    inDelivered.insertRow(3, "");
    inDelivered.mergeCells("A2:B2");
    inDelivered.getRow(4).font = { bold: true };
    addBorder(inDelivered, 4);

    var row = 5;
    DeliveredExcelList.map((list, index) => {
      inDelivered.addRow({
        tracking: list.tracking,
        encodedDate: list.encodedDate,
        deliveryReq: list.deliveryReq,
        consigneeName: list.consigneeName,
        consigneeAddress: list.consigneeAddress,
        consigneeContact: list.consigneeContact,
        acceptanceDate: list.acceptanceDate,
        status: list.status,
        statusDate: list.statusDate,
        itemName: list.itemName,
        itemValue: list.DeclareValue,
        codCopAmount: list.collectedAmount,
        freightAmount: list.TotalFreight,
        reason: list.reason,
        FulfilledStatus: list.FulfilledStatus
      });
      addBorder(inDelivered, row);
      row++;
    });

    //#region In-Transit
    const inTransit = workbook.addWorksheet("For Updates");
    inTransit.columns = deliveryreport.columns;
    inTransit.insertRow(1, "");
    inTransit.insertRow(2, "");
    inTransit.getCell("A2").value = "IN-TRANSIT";
    inTransit.getCell("A2").font = { bold: true };
    inTransit.insertRow(3, "");
    inTransit.mergeCells("A2:B2");
    inTransit.getRow(4).font = { bold: true };
    addBorder(inTransit, 4);

    var row = 5;
    InTransitExcelList.map((list, index) => {
      inTransit.addRow({
        tracking: list.tracking,
        encodedDate: list.encodedDate,
        deliveryReq: list.deliveryReq,
        consigneeName: list.consigneeName,
        consigneeAddress: list.consigneeAddress,
        consigneeContact: list.consigneeContact,
        acceptanceDate: list.acceptanceDate,
        status: list.status,
        statusDate: list.statusDate,
        itemName: list.itemName,
        itemValue: list.DeclareValue,
        codCopAmount: list.collectedAmount,
        freightAmount: list.TotalFreight,
        reason: list.reason,
        FulfilledStatus: list.FulfilledStatus
      });
      addBorder(inTransit, row);
      row++;
    });
    //#endregion In-Transit

    //#region RTS
    const rts = workbook.addWorksheet("Returned to Shipper");
    rts.columns = deliveryreport.columns;
    rts.insertRow(1, "");
    rts.insertRow(2, "");
    rts.getCell("A2").value = "RTS";
    rts.getCell("A2").font = { bold: true };
    rts.insertRow(3, "");
    rts.mergeCells("A2:B2");
    rts.getRow(4).font = { bold: true };
    addBorder(rts, 4);

    var row = 5;
    ReturnedExcelList.map((list, index) => {
      rts.addRow({
        tracking: list.tracking,
        encodedDate: list.encodedDate,
        deliveryReq: list.deliveryReq,
        consigneeName: list.consigneeName,
        consigneeAddress: list.consigneeAddress,
        consigneeContact: list.consigneeContact,
        acceptanceDate: list.acceptanceDate,
        status: list.status,
        statusDate: list.statusDate,
        itemName: list.itemName,
        itemValue: list.DeclareValue,
        codCopAmount: list.collectedAmount,
        freightAmount: list.TotalFreight,
        reason: list.reason,
        FulfilledStatus: list.FulfilledStatus
      });
      addBorder(rts, row);
      row++;
    });
    //#endregion RTS

    //#region LOST
    const lost = workbook.addWorksheet("Lost");
    lost.columns = deliveryreport.columns;
    lost.insertRow(1, "");
    lost.insertRow(2, "");
    lost.getCell("A2").value = "RTS";
    lost.getCell("A2").font = { bold: true };
    lost.insertRow(3, "");
    lost.mergeCells("A2:B2");
    lost.getRow(4).font = { bold: true };
    addBorder(lost, 4);

    var row = 5;
    OthersExcelList.map((list, index) => {
      lost.addRow({
        tracking: list.tracking,
        encodedDate: list.encodedDate,
        deliveryReq: list.deliveryReq,
        consigneeName: list.consigneeName,
        consigneeAddress: list.consigneeAddress,
        consigneeContact: list.consigneeContact,
        acceptanceDate: list.acceptanceDate,
        status: list.status,
        statusDate: list.statusDate,
        itemName: list.itemName,
        itemValue: list.DeclareValue,
        codCopAmount: list.collectedAmount,
        freightAmount: list.TotalFreight,
        reason: list.reason,
        FulfilledStatus: list.FulfilledStatus
      });
      addBorder(lost, row);
      row++;
    });
    //#endregion LOST

    setModalMessage('Export completed successfully!');
    setModalOpen(true);

    workbook.xlsx
      .writeBuffer()
      .then((buffer) =>
        FileSaver.saveAs(
          new Blob([buffer]),
          //`${searchParams.get("dateFrom")} - ${searchParams.get("dateTo")}.xlsx`
          `Delivery Report.xlsx`
        )
      )
      .catch((err) => console.log("Error writing excel export", err));
  };

  const addBorder = (sheet, row) => {
    sheet.getCell("A" + row).border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
    sheet.getCell("B" + row).border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
    sheet.getCell("C" + row).border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
    sheet.getCell("D" + row).border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
    sheet.getCell("E" + row).border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
    sheet.getCell("F" + row).border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
    sheet.getCell("G" + row).border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
    sheet.getCell("H" + row).border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
    sheet.getCell("I" + row).border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
    sheet.getCell("J" + row).border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
    sheet.getCell("K" + row).border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
    sheet.getCell("L" + row).border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
    sheet.getCell("M" + row).border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
    sheet.getCell("N" + row).border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
    sheet.getCell("O" + row).border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
  };

  React.useEffect(() => {}, [timeline]);

  return (
    <>
      <LBCModal
        description={toastDesc}
        isOpen={isToastOpen}
        onClose={() => setToastOpen(false)}
        title={toastTitle}
        toastKind={toastKind}
      />

      <Div className="w-full">
        <Spacer className="w-10 h-10" />
        <Div className="w-full border border-grey-400 rounded-lg shadow-lg">
          <FlexRow className="w-full items-center justify-between py-5">
            <FlexRow className="items-center">
              {/* <Div className="w-48 mx-2">
                <RawDropdown
                  icon={<div />}
                  width="w-full"
                  border="border phone:border-grey-100"
                  value={timeline}
                  options={RecurrenceOptionsDelivery}
                  onSelect={setTimeline}
                />
              </Div> */}
              {timeline === "Daily" ? (
                <FlexRow className="pl-2">
                  <input
                    className="text-sm w-40 mx-2 block rounded-lg"
                    type="date"
                    value={dateFrom}
                    onChange={(e) => setDateFrom(e.target.value)}
                  />
                  <input
                    className="text-sm w-40 mx-2 block rounded-lg"
                    type="date"
                    value={dateTo}
                    onChange={(e) => setDateTo(e.target.value)}
                  />
                </FlexRow>
              ) : (
                <></>
              )}

              <Button
                className="w-40 ml-2 h-10"
                isLoading={loading}
                onClick={() =>
                  GenerateDeliveryReport(shipperAcctNo, dateFrom, dateTo)
                }
              >
                Generate
              </Button>
            </FlexRow>
          </FlexRow>
          <Spacer className="w-10 h-10" />

          <FlexRow className="w-full items-center justify-between py-2 border border-grey-400">
            {/* {DeliveryReportColumns.map(column => (
              <Text className={`text-secondary-100 text-sm text-center font-semibold px-4 py-2 ${'w-1/4'}`}>
                {column} 
                {column == "DELIVERED" && (' ' + (deliveryReportList.reduce((n, {Delivered}) => n + Delivered, 0)).toLocaleString())}
                {column == "PENDING / FOR DISPOSITION" && (' ' + (deliveryReportList.reduce((n, {Pending}) => n + Pending, 0)).toLocaleString())}
                {column == "FOR UPDATE" && (' ' + (deliveryReportList.reduce((n, {ForUpdate}) => n + ForUpdate, 0)).toLocaleString())}
                {column == "RETURNED TO SHIPPER" && (' ' + (deliveryReportList.reduce((n, {RTS}) => n + RTS, 0)).toLocaleString())}
                {column == "LOST" && (' ' + (deliveryReportList.reduce((n, {Lost}) => n + Lost, 0)).toLocaleString())}
                {column == "TOTAL TRANSACTIONS" && (' ' + (deliveryReportList.reduce((n, {Total}) => n + Total, 0)).toLocaleString())}
              </Text>
            ))} */}

            {/* {loading ? (
              <Text className="text-red-400 text-center flex flex-row justify-center items-center my-10">
                <ImSpinner2 className="animate-spin mr-2 text-2xl desktop:text-3xl" />
              </Text>
            ) : (
              <Div className="w-full">
                {newList.map((item, key) => (
                  <DeliveryReportDataRow data={item} key={key} />
                ))}
              </Div>
            )} */}

            <ColumnHeader
              title={
                "DELIVERED " +
                deliveryReportList
                  .reduce((n, { Delivered }) => n + Delivered, 0)
                  .toLocaleString()
              }
              onClick={() => getDeliveryReport("Delivered")}
              containerClass="hover:bg-grey-400 rounded-full w-200px px-4 py-2"
              titleClassName=""
            />
            <ColumnHeader
              title={
                "PENDING / FOR DISPOSITION " +
                deliveryReportList
                  .reduce((n, { Pending }) => n + Pending, 0)
                  .toLocaleString()
              }
              onClick={() => getDeliveryReport("For Disposition")}
              containerClass="hover:bg-grey-400 rounded-full w-200px px-4 py-2"
              titleClassName=""
            />
            <ColumnHeader
              title={
                "FOR UPDATE " +
                deliveryReportList
                  .reduce((n, { ForUpdate }) => n + ForUpdate, 0)
                  .toLocaleString()
              }
              onClick={() => getDeliveryReport("In-transit")}
              containerClass="hover:bg-grey-400 rounded-full w-200px px-4 py-2"
              titleClassName=""
            />
            <ColumnHeader
              title={
                "RETURNED TO SHIPPER " +
                deliveryReportList
                  .reduce((n, { RTS }) => n + RTS, 0)
                  .toLocaleString()
              }
              onClick={() => getDeliveryReport("Returned")}
              containerClass="hover:bg-grey-400 rounded-full w-200px px-4 py-2"
              titleClassName=""
            />
            <ColumnHeader
              title={
                "LOST " +
                deliveryReportList
                  .reduce((n, { Lost }) => n + Lost, 0)
                  .toLocaleString()
              }
              onClick={() => getDeliveryReport("Others")}
              containerClass="hover:bg-grey-400 rounded-full w-200px px-4 py-2 mx-auto"
              titleClassName=""
            />
            <ColumnHeader
              title={
                "TOTAL TRANSACTIONS " +
                deliveryReportList
                  .reduce((n, { Total }) => n + Total, 0)
                  .toLocaleString()
              }
              onClick={() => getDeliveryReport("ViewAll")}
              containerClass="hover:bg-grey-400 rounded-full w-200px px-4 py-2"
              titleClassName=""
            />

            {reportGenerated && (
              <RawButton onClick={() => exportToCSV()}>
                <AiOutlineDownload className="text-secondary-200 text-xl" />
              </RawButton>
            )}

            <Modal 
              isOpen={modalOpen}
              onClose={() => setModalOpen(false)}
              message={modalMessage}
            />
            
          </FlexRow>

          <Spacer className="w-full h-10" />
          <FlexColumn className="w-full">
            <Div className="w-full overflow-x-scroll border border-grey-400 rounded-lg shadow-lg p-5">
              <FlexRow className="items-center justify-between w-2800px">
                <ColumnHeader
                  title="Tracking No."
                  //onClick={() => onSort(0)}
                  containerClass="flex flex-row items-center hover:bg-grey-300 rounded-full w-200px px-4 py-2"
                  titleClassName=""
                />
                <ColumnHeader
                  title="Encoded Date"
                  //onClick={() => onSort(1)}
                  containerClass="flex flex-row items-center hover:bg-grey-300 rounded-full w-200px px-4 py-2"
                  titleClassName=""
                />
                <ColumnHeader
                  title="Delivery Requirement"
                  //onClick={() => onSort(2)}
                  containerClass="hover:bg-grey-400 rounded-full w-200px px-4 py-2"
                  titleClassName=""
                />
                <ColumnHeader
                  title="Consignee Name"
                  //onClick={() => onSort(3)}
                  containerClass="hover:bg-grey-400 rounded-full w-200px px-4 py-2"
                  titleClassName=""
                />
                <ColumnHeader
                  title="Consignee Mobile"
                  //onClick={() => onSort(4)}
                  containerClass="hover:bg-grey-400 rounded-full w-200px px-4 py-2"
                  titleClassName=""
                />
                <Div className="w-400px">
                  <ColumnHeader
                    title="Consignee Address"
                    //onClick={() => onSort(5)}
                    containerClass="hover:bg-grey-400 rounded-full w-200px px-4 py-2 mx-auto"
                    titleClassName=""
                  />
                </Div>
                {/* <ColumnHeader
                  title="Consignee Mobile No."
                  //onClick={() => onSort(6)}
                  containerClass="hover:bg-grey-400 rounded-full w-200px px-4 py-2"
                  titleClassName=""
                /> */}
                <ColumnHeader
                  title="Acceptance Date"
                  //onClick={() => onSort(7)}
                  containerClass="hover:bg-grey-400 rounded-full w-200px px-4 py-2"
                  titleClassName=""
                />
                <ColumnHeader
                  title="Last Status"
                  //onClick={() => onSort(8)}
                  containerClass="hover:bg-grey-400 rounded-full w-200px px-4 py-2"
                  titleClassName=""
                />
                <ColumnHeader
                  title="Last Status Date"
                  //onClick={() => onSort(9)}
                  containerClass="hover:bg-grey-400 rounded-full w-200px px-4 py-2"
                  titleClassName=""
                />
                <ColumnHeader
                  title="Item Name"
                  //onClick={() => onSort(9)}
                  containerClass="hover:bg-grey-400 rounded-full w-200px px-4 py-2"
                  titleClassName=""
                />
                <ColumnHeader
                  title="Item Value"
                  //onClick={() => onSort(9)}
                  containerClass="hover:bg-grey-400 rounded-full w-200px px-4 py-2"
                  titleClassName=""
                />
                <ColumnHeader
                  title="COD Amount"
                  //onClick={() => onSort(9)}
                  containerClass="hover:bg-grey-400 rounded-full w-200px px-4 py-2"
                  titleClassName=""
                />
                <ColumnHeader
                  title="Freight Amount"
                  //onClick={() => onSort(9)}
                  containerClass="hover:bg-grey-400 rounded-full w-200px px-4 py-2"
                  titleClassName=""
                />
                <ColumnHeader
                  title="Reason"
                  //onClick={() => onSort(10)}
                  containerClass="hover:bg-grey-400 rounded-full w-200px px-4 py-2"
                  titleClassName=""
                />
                {deliveryStatusColumn === "For Disposition" ? <></> : <></>}
              </FlexRow>
              <Divider className="text-grey-100 my-2 w-2800px" />

              <LBCModal
                description={toastDesc}
                isOpen={isToastOpen}
                onClose={() => setToastOpen(false)}
                title={toastTitle}
                toastKind={toastKind}
              />

              {loading ? (
                <Text className="text-red-400 text-center flex flex-row justify-center items-center my-20">
                  <ImSpinner2 className="animate-spin mr-2 text-2xl desktop:text-3xl" />
                  Loading data ...
                </Text>
              ) : (
                <>
                  {newItems.map((list) => (
                    <ReportsDeliveryDataTableRow
                      data={list}
                      status={deliveryStatusColumn} //{searchParams.get("deliveryStatus")}
                      onDispoChange={onDispoChange}
                    />
                  ))}
                </>
              )}

              <FlexRow className="items-center justify-between w-2800px py-2 hover:bg-grey-400">
                <Text className="text-secondary-100 text-sm w-200px px-4">
                  {}
                </Text>
                <Text className="text-secondary-100 text-sm text-center w-200px px-4">
                  {}
                </Text>
                <Text className="text-secondary-100 text-sm text-center w-200px px-4">
                  {}
                </Text>
                <Text className="text-secondary-100 text-sm text-center w-200px px-4">
                  {}
                </Text>
                <Text className="text-secondary-100 text-sm text-center w-200px px-4">
                  {}
                </Text>
                <Text className="text-secondary-100 text-sm text-center w-400px px-4">
                  {}
                </Text>
                <Text className="text-secondary-100 text-sm text-center w-200px px-4">
                  {}
                </Text>
                <Text className="text-secondary-100 text-sm text-center w-200px px-4">
                  {}
                </Text>
                <Text className="text-secondary-100 text-sm text-center w-200px px-4">
                  {}
                </Text>
                <Text className="text-secondary-100 text-sm text-center w-200px px-4">
                  {}
                </Text>
                <Text className="text-red-400 font-bold text-sm text-center w-200px px-4">
                  Total
                </Text>
                <Text className="text-red-400 font-bold text-sm text-center w-200px px-4">
                  PHP{" "}
                  {newItems
                    .reduce(
                      (n, { DeclareValue }) =>
                        n + parseFloat(DeclareValue),
                      0
                    )
                    .toLocaleString()}
                </Text>
                <Text className="text-red-400 font-bold text-sm text-center w-200px px-4">
                  PHP{" "}
                  {newItems
                    .reduce(
                      (n, { collectedAmount }) =>
                        n + parseFloat(collectedAmount),
                      0
                    )
                    .toLocaleString()}
                </Text>
                <Text className="text-red-400 font-bold text-sm text-center w-200px px-4">
                  PHP{" "}
                  {newItems
                    .reduce(
                      (n, { TotalFreight }) =>
                        n + parseFloat(TotalFreight),
                      0
                    )
                    .toLocaleString()}
                </Text>
                <Text className="text-secondary-100 text-sm text-center w-200px px-4">
                  {}
                </Text>
              </FlexRow>
            </Div>
            
          </FlexColumn>
        </Div>
        <Text className="text-red-400 text-xs font-bold my-2">
              {`Disclaimer: Coverage is Twelve (12) months from encoded date.`}
            </Text>
            <Div className="w-full">
              <Pagination
                currentPage={currentPage}
                itemsPerPage={articlesPerPage}
                paginate={paginate}
                totalItems={sortList.length}
              />
            </Div>
        {/* <Spacer className="w-full h-10" />
        <Div className="w-full">
          <Pagination
            currentPage={currentPage}
            itemsPerPage={articlesPerPage}
            paginate={paginate}
            totalItems={deliveryReportList.length}
          />
        </Div> */}
      </Div>
    </>
  );
};
