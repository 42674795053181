import React, { useState, useEffect } from "react";
import { Div, FlexRow } from "../../core/Containers";
import { Text } from "../../core/Text";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaRegEdit } from "react-icons/fa";
import { RawButton } from "../../core/Buttons";
import { BookNowModal } from "../Modals/BookNowModal";
import { LBCModal, LBCModalTwoFunc } from "../Modals/LBCModal";
import { CheckBox } from "../../core/Forms";
import moment from "moment";
import BookNow from "../../services/BookNow.service";

export const ActivityDataRow = ({ data, onBookingNoClick }) => {
  const senderAddress = (data?.ShipperStBldg || "").split(",");
  const senderUnit = senderAddress[0] ? senderAddress[0].trim() : "";
  const senderStreet = senderAddress[1] ? senderAddress[1].trim() : "";
  const senderLandmark = senderAddress[2] ? senderAddress[2].trim() : "";

  const [isToastOpen, setToastOpen] = useState(false);
  const [toastKind, setToastKind] = useState("");
  const [toastTitle, setToastTitle] = useState("");
  const [toastDesc, setToastDesc] = useState("");

  const printWayBill = (bookingNo) => {
    BookNow.PrintWayBill(bookingNo)
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        if (response.Code === "200") {
          let pdfWindow = window.open("");
          pdfWindow.document.write(
            "<html<head><title>" +
              bookingNo +
              "</title><style>body{margin: 0px;}</style></head>"
          );
          pdfWindow.document.write(
            "<body><embed width='100%' height='100%' src='data:application/pdf;base64, " +
              encodeURI(response.Data) +
              "#toolbar=0&navpanes=0&scrollbar=0'></embed></body></html>"
          );
          pdfWindow.document.close();
        }
      })
      .catch((error) => {
        setToastKind("error");
        setToastTitle("Print WayBill Error!");
        setToastDesc(error.message);
        setTimeout(() => {
          setToastOpen(true);
        }, 1500);
        setTimeout(() => {
          setToastOpen(false);
        }, 5000);
      });
  };

  return (
    <>
      <LBCModal
        description={toastDesc}
        isOpen={isToastOpen}
        onClose={() => setToastOpen(false)}
        title={toastTitle}
        toastKind={toastKind}
      />
      <FlexRow className="items-start justify-between w-full py-2 border-l-8 border-l-white hover:border-l-red-400 hover:bg-grey-400">
        {/* <Text className="text-red-400 text-sm font-semibold text-center w-1/6">
          {data?.BookingNo || ""}
        </Text> */}
        <RawButton>
        {/* <RawButton onClick={() => onBookingNoClick([data.Id])}> */}
           {/* printWayBill(data?.BookingNo)}> */}
          <Text className="text-red-400 text-sm font-semibold text-left w-32 px-2">
            { (!data?.TrackingNo.startsWith('32') ? data?.TrackingNo : data?.BookingNo) }
          </Text>
        </RawButton>
        <Text className="text-secondary-100 text-xs text-left w-32 px-2">
          {data && data.TransactionDate !== ""
            ? moment(data.TransactionDate).format("MMM. D, YYYY")
            : ""}
        </Text>
        <Text className="text-secondary-100 text-xs text-left w-1/6 px-2">
          {data?.ContactPerson || ""}
        </Text>
        <Text className="text-secondary-100 text-xs text-left w-1/6 px-2">
          {`${senderUnit} ${senderStreet}, ${data?.ShipperBrgy || ""}, ${
            data?.ShipperCityMunicipality || ""
          }, ${data?.ShipperProvince || ""}`}
        </Text>
        <Text className="text-secondary-100 text-xs text-left w-32 px-2">
          {data?.ProductLine === "SUPPLIES" ? "Supplies" : "Shipment" || ""}
        </Text>
        <Text className="text-secondary-100 text-xs text-left w-32 px-2">
          {data && data.PickupDate !== ""
            ? moment(data.PickupDate).format("MMM. D, YYYY")
            : ""}
        </Text>
      </FlexRow>
    </>
  );
};

export const ActivityItemsDataRow = ({ data, onSelect }) => {
  const [isToastOpen, setToastOpen] = React.useState(false);
  const [isToastOpenA, setToastOpenA] = React.useState(false);

  return (
    <>
      {/* <BookNowModal
        isOpen={isToastOpenA}
        onClose={() => setToastOpenA(false)}
      />
      <LBCModalTwoFunc
        description="Are you sure you want to cancel this booking?"
        isOpen={isToastOpen}
        onClose={() => setToastOpen(false)}
        title="Cancel booking?"
        toastKind="info"
        buttonTextFirst="Close"
        buttonTextSecond="Continue"
      /> */}
      <FlexRow className="items-center justify-between w-full py-2 border-b border-grey-400 hover:bg-grey-400">
        <Div className="w-1/13 pl-5">
          <CheckBox
            className="phone:mx-0"
            containerClass="mx-auto"
            id={data.name}
            name={data.name}
            label=""
            onClick={() => onSelect(data.id)}
          />
        </Div>

        <Div className="w-1/10">
          <Text className="text-secondary-100 text-sm">{data.package}</Text>
          <Text className="text-secondary-100 text-sm">Item</Text>
        </Div>

        <Div className="w-1/6">
          <Text className="text-secondary-100 text-sm">{data.sender.name}</Text>
          <Text className="text-secondary-100 text-sm">
            {data.sender.contac}
          </Text>
          <Text className="text-secondary-100 text-sm">
            {data.sender.address}
          </Text>
          <Text className="text-secondary-100 text-sm">{data.sender.type}</Text>
          <Text className="text-secondary-100 text-sm">
            {data.sender.pickUpDate}
          </Text>
        </Div>

        <Div className="w-1/6">
          <Text className="text-secondary-100 text-sm">
            {data.receiver.name}
          </Text>
          <Text className="text-secondary-100 text-sm">
            {data.receiver.contac}
          </Text>
          <Text className="text-secondary-100 text-sm">
            {data.receiver.address}
          </Text>
          <Text className="text-secondary-100 text-sm">
            {data.receiver.type}
          </Text>
        </Div>

        <Div className="w-1/10">
          <Text className="text-secondary-100 text-sm">Pay:</Text>
          <Text className="text-secondary-100 text-sm">PHP 1,234.56</Text>
        </Div>

        <Div className="w-1/10">
          <Text className="text-secondary-100 text-sm">Collect:</Text>
          <Text className="text-secondary-100 text-sm">PHP 1,234.56</Text>
        </Div>

        <Div className="w-1/6">
          <RawButton onClick={() => setToastOpenA(true)}>
            <Text className="text-red-400 text-sm underline font-semibold mx-1">
              View
            </Text>
          </RawButton>
          <RawButton onClick={() => setToastOpenA(true)}>
            <Text className="text-red-400 text-sm underline font-semibold mx-1">
              Edit
            </Text>
          </RawButton>
          <RawButton onClick={() => setToastOpen(true)}>
            <Text className="text-red-400 text-sm underline font-semibold mx-1">
              Cancel
            </Text>
          </RawButton>
        </Div>
      </FlexRow>
    </>
  );
};

export const DraftDataRow = ({ data, onDeleteClick, onEditClick }) => {
  return (
    <>
      <FlexRow className="items-start justify-between w-full py-2 border-l-8 border-l-white hover:border-l-red-400 hover:bg-grey-400">
        <Text className="text-secondary-100 text-xs text-left w-32 px-2">
          {moment(data.CreatedDate).format("MM/DD/YYYY")}
        </Text>
        <Text className="text-secondary-100 text-xs text-left w-40 px-2">
          {data.Description !== "" ? data.Description : "(No item name)"}
        </Text>
        <Text className="text-secondary-100 text-xs text-left w-40 px-2">
          {data.ProductName}
          {/* <br />
          {data.LexaProdType} */}
        </Text>
        <Text className="text-secondary-100 text-xs text-left w-40 px-2">
          {`Php ${Number(data.DeclaredValue)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`}
        </Text>
        <Text className="text-secondary-100 text-xs text-left w-1/6 px-2">
          <strong>{data.Shipper}</strong>
          <br />
          {`${data.ShipperCityMunicipality}, ${data.ShipperProvince}`}
          <br />
          {data.ReferenceNoThree}
        </Text>
        <Text className="text-secondary-100 text-xs text-left w-1/6 px-2">
          <strong>{data.Consignee}</strong>
          <br />
          {`${data.ConsigneeCityMunicipality}, ${data.ConsigneeProvince}`}
          <br />
          {data.ReferenceNoFour}
        </Text>
        <FlexRow className="items-center justify-center w-32">
          <RawButton
            onClick={() => (onDeleteClick ? onDeleteClick(data) : true)}
          >
            <RiDeleteBin6Line className="text-red-400 text-xl mr-2" />
          </RawButton>
          <RawButton onClick={() => (onEditClick ? onEditClick(data) : true)}>
            <FaRegEdit className="text-red-400 text-xl ml-2" />
          </RawButton>
        </FlexRow>
      </FlexRow>
    </>
  );
};
