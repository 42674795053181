import React from "react";
import { Cookies } from "react-cookie";

const DownloadExcelModal = ({ isOpen, onClose, message }) => {
    if (!isOpen) return null;

    const overlayStyle = {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1000,
      };
    
      const modalStyle = {
        background: 'white',
        padding: '20px',
        borderRadius: '5px',
        textAlign: 'center',
      };
    
      const buttonStyle = {
        marginTop: '10px',
        padding: '10px 20px',
        border: 'none',
        borderRadius: '5px',
        backgroundColor: '#007bff',
        color: 'white',
        cursor: 'pointer',
      };
    
      const buttonHoverStyle = {
        backgroundColor: '#0056b3',
      };

    return (
        <div style={overlayStyle}>
            <div style={modalStyle}>
                <h2>Export Status</h2>
                <p>{message}</p>
                <button 
                style={buttonStyle} 
                onMouseOver={(e) => (e.target.style.backgroundColor = buttonHoverStyle.backgroundColor)}
                onMouseOut={(e) => (e.target.style.backgroundColor = buttonStyle.backgroundColor)}
                onClick={onClose}
                >
                Close
                </button>
            </div>
        </div>
    );
};
export default DownloadExcelModal;