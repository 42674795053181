import axios from "axios";
import { appConfig } from "../helpers/common";

const axiosTrackingApi = axios.create({
  baseURL: appConfig.api.trackingUrl,
  headers: {
    "Content-type": "text/xml",
    lbcOAkey: appConfig.api.trackingLBCOAkey,
  },
});

export default axiosTrackingApi;
