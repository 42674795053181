import axios from "axios";
import { appConfig } from "../helpers/common";

const axiosCAPApi = axios.create({
  baseURL: appConfig.api.baseUrl,
  headers: {
    "Content-Type": "application/json",
    lbcOAkey: appConfig.api.lbcOAkey,
  },
  withCredentials: true,
});

export default axiosCAPApi;
