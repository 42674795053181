import React, { useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FlexRow, FlexColumn, Spacer, Div } from "../../core/Containers";
import { Text, Span } from "../../core/Text";
import { Image } from "../../core/Image";
import { RawInput } from "../../core/Forms";
import { LBCModal } from "../../components/Modals/LBCModal";
import { Loading } from "../Window/Loading";
import { Images } from "../../assets/images/images";
import { isNumber, isValidPassword } from "../../helpers/common";
import { Button } from "../../core/Buttons";
import { HiCheckCircle } from "react-icons/hi";
import axiosCAPApi from "../../api/axiosCAPApi";
import axiosCAPApiPrivate from "../../api/axiosCAPApiPrivate";

export const ForgotPassword = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const dataFetchedRef = useRef(false);

  const [loading, setLoading] = React.useState(false);

  const [isToastOpen, setToastOpen] = React.useState(false);
  const [toastKind, setToastKind] = React.useState("");
  const [toastTitle, setToastTitle] = React.useState("Account Created");
  const [toastDesc, setToastDesc] = React.useState(
    "Redirecting to onboarding."
  );
  const [otpNo1, setOTPNo1] = React.useState("");
  const [otpNo2, setOTPNo2] = React.useState("");
  const [otpNo3, setOTPNo3] = React.useState("");
  const [otpNo4, setOTPNo4] = React.useState("");
  const [otpNo5, setOTPNo5] = React.useState("");
  const [otpNo6, setOTPNo6] = React.useState("");
  const [userID, setUserID] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [currentOTP, setCurrentOTP] = React.useState("");
  const [activeSection, setActiveSection] = React.useState(0);
  const [newPassword, setNewPassword] = React.useState("");
  const [newPasswordErr, setNewPasswordErr] = React.useState("");
  const [confirmNewPassword, setConfirmNewPassword] = React.useState("");
  const [confirmNewPasswordErr, setConfirmNewPasswordErr] = React.useState("");
  const [showNewPass, setShowNewPass] = React.useState(false);
  const [showConfirmNewPass, setConfirmShowNewPass] = React.useState(false);

  React.useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    // onSendOTP();
    const _otpNo1 = document.getElementById("otpNo1");
    if (_otpNo1 !== null) _otpNo1.focus();
  }, []);

  React.useEffect(() => {
    const _otp = query.get("otp");
    const _userID = query.get("userID");
    const _email = query.get("email");

    if (!_otp) {
      navigate("/");
      return;
    }

    setCurrentOTP(_otp);
    setUserID(_userID);
    setEmail(_email);
    navigate("/forgot-password");
  }, [navigate]);

  React.useEffect(() => {
    if (otpNo1 !== "") document.getElementById("otpNo2").focus();
  }, [otpNo1]);

  React.useEffect(() => {
    if (otpNo2 !== "") document.getElementById("otpNo3").focus();
  }, [otpNo2]);

  React.useEffect(() => {
    if (otpNo3 !== "") document.getElementById("otpNo4").focus();
  }, [otpNo3]);

  React.useEffect(() => {
    if (otpNo4 !== "") document.getElementById("otpNo5").focus();
  }, [otpNo4]);

  React.useEffect(() => {
    if (otpNo5 !== "") document.getElementById("otpNo6").focus();
  }, [otpNo5]);

  React.useEffect(() => {
    if (otpNo6 !== "") onSubmit();
  }, [otpNo6]);

  const onSendOTP = async () => {
    setLoading(true);

    const generatedOTP = Math.floor(100000 + Math.random() * 900000);
    setCurrentOTP(generatedOTP);

    try {
      const response = await axiosCAPApiPrivate.post("/api/SendEmail", {
        fromEmail: "customercare@lbcexpress.com",
        toEmail: email,
        subject: `Corporate Accounts Portal Verification Code: ${generatedOTP}`,
        html: true,
        body: `<div>${generatedOTP} is your Corporate Accounts Portal verification code.</div><br><br><div>Best regards,</div><div>Corporate Accounts Portal Support Team</div><br><br>%Unsubscribe_Link%`,
      });

      const data = response.data;

      if (data.Code === "200") {
        setToastKind("success");
        setToastTitle("OTP sent to your email address.");
        setToastDesc("Please check your email address.");
        setTimeout(() => {
          setLoading(false);
        }, 1000);
        setTimeout(() => {
          setToastOpen(true);
        }, 1500);
        setTimeout(() => {
          setToastOpen(false);
          const _otpNo1 = document.getElementById("otpNo1");
          if (_otpNo1 !== null) _otpNo1.focus();
        }, 5000);
      } else {
        setToastKind("error");
        setToastTitle("OTP Generation Failed!");
        setToastDesc(data.Message);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
        setTimeout(() => {
          setToastOpen(true);
        }, 1500);
        setTimeout(() => {
          setToastOpen(false);
        }, 5000);
      }
    } catch (error) {
      setToastKind("error");
      setToastTitle("OTP Generation Failed!");
      setToastDesc(
        "Unable to generate OTP. Please contact Corporate Accounts Portal Support Team."
      );
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setTimeout(() => {
        setToastOpen(true);
      }, 1500);
      setTimeout(() => {
        setToastOpen(false);
      }, 5000);
    }
  };

  const updateUserPassword = async () => {
    setLoading(true);

    try {
      const response = await axiosCAPApi.post(
        "/api/UpdateUserPassword",
        {
          userID,
          newPassword,
        }
      );

      const data = response.data;

      if (data.Code === "200") {
        setActiveSection(2);
        setLoading(false);
      } else {
        setToastKind("error");
        setToastTitle("Update Password Failed!");
        setToastDesc(data.Message);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
        setTimeout(() => {
          setToastOpen(true);
        }, 1500);
        setTimeout(() => {
          setToastOpen(false);
        }, 5000);
      }
    } catch (error) {
      setToastKind("error");
      setToastTitle("Update Password Failed!");
      setToastDesc(error.message);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setTimeout(() => {
        setToastOpen(true);
      }, 1500);
      setTimeout(() => {
        setToastOpen(false);
      }, 5000);
    }
  };

  const onSubmit = (e) => {
    e && e.preventDefault();

    if (
      otpNo1 !== "" &&
      otpNo2 !== "" &&
      otpNo3 !== "" &&
      otpNo4 !== "" &&
      otpNo5 !== "" &&
      otpNo6 !== ""
    ) {
      setLoading(true);
      setTimeout(() => {
        const inputedOTP = `${otpNo1}${otpNo2}${otpNo3}${otpNo4}${otpNo5}${otpNo6}`;
        if (Number(inputedOTP) === Number(currentOTP)) {
          setTimeout(() => {
            setLoading(false);
            setActiveSection(1);
          }, 1000);
        } else {
          setToastKind("error");
          setToastTitle("OTP Verification");
          setToastDesc("Incorrect OTP. Please try again.");
          setTimeout(() => {
            setLoading(false);
          }, 1000);
          setTimeout(() => {
            setToastOpen(true);
          }, 1500);
          setTimeout(() => {
            setToastOpen(false);
            const _otpNo1 = document.getElementById("otpNo1");
            if (_otpNo1 !== null) _otpNo1.focus();
          }, 5000);
        }
      }, 2000);
    }
  };

  const onSubmitChangePass = () => {
    let validData = true;
    setNewPasswordErr("");
    setConfirmNewPasswordErr("");

    if (newPassword === "") {
      setNewPasswordErr("Password is required.");
      validData = false;
    } else if (!isValidPassword(newPassword)) {
      setNewPasswordErr("Not a valid Password.");
      validData = false;
    }

    if (confirmNewPassword === "") {
      setConfirmNewPasswordErr("Confirm Password is required.");
      validData = false;
    } else if (confirmNewPassword !== newPassword) {
      setConfirmNewPasswordErr("Password and Confirm Password does not match.");
      validData = false;
    }

    if (validData) {
      updateUserPassword();
    }
  };

  return (
    <>
      {loading ? <Loading /> : <></>}

      <LBCModal
        description={toastDesc}
        isOpen={isToastOpen}
        onClose={() => setToastOpen(false)}
        title={toastTitle}
        toastKind={toastKind}
      />

      <FlexRow className="justify-center items-center h-full w-full">
        <FlexColumn className="justify-center items-center w-500px rounded-lg shadow-xl border border-grey-400 desktop:w-600px py-7">
          <Image
            className="h-32 w-36 desktop:h-32 desktop:w-36"
            alt="LBC logo"
            src={Images.LBClogo}
          />

          <Spacer className="h-2" />

          {activeSection === 0 && (
            <>
              <FlexRow className="flex-row justify-center items-center w-full">
                <svg
                  className="w-4 h-4 text-gray-800 dark:text-white cursor-pointer"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 10"
                  onClick={() => navigate(`/`)}
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M13 5H1m0 0 4 4M1 5l4-4"
                  />
                </svg>
                <Text className="text-secondary-100 text-lg font-bold text-center desktop:text-base ml-3">
                  Verification
                </Text>
              </FlexRow>

              <Spacer className="h-5" />

              <Div className="mx-auto flex w-full max-w-md flex-col">
                <Div className="flex flex-col items-center justify-center text-center space-y-2">
                  <Div className="font-semibold text-2xl">
                    <p>Checking if it's you</p>
                  </Div>
                  <Div className="flex flex-row text-sm font-medium text-gray-400 text-grey-1000">
                    <p>Enter the 6-digit code sent to your mailing address</p>
                  </Div>
                </Div>

                <Div>
                  <form action="" method="post" onSubmit={(e) => onSubmit(e)}>
                    <input type="submit" hidden />
                    <Div className="flex flex-col">
                      <Div
                        id="otp"
                        className="flex flex-row justify-center text-center px-2 mt-5"
                      >
                        <RawInput
                          className="w-full h-full border text-xl text-center form-control rounded p-1"
                          containerClass="m-2 h-16 w-15"
                          type="text"
                          name="otpNo1"
                          maxlength="1"
                          autoComplete="off"
                          value={otpNo1}
                          onChange={(value) =>
                            isNumber(value) && setOTPNo1(value)
                          }
                        />
                        <RawInput
                          className="w-full h-full border text-xl text-center form-control rounded p-1"
                          containerClass="m-2 h-16 w-15"
                          type="text"
                          name="otpNo2"
                          maxlength="1"
                          autoComplete="off"
                          value={otpNo2}
                          onChange={(value) =>
                            isNumber(value) && setOTPNo2(value)
                          }
                        />
                        <RawInput
                          className="w-full h-full border text-xl text-center form-control rounded p-1"
                          containerClass="m-2 h-16 w-15"
                          type="text"
                          name="otpNo3"
                          maxlength="1"
                          autoComplete="off"
                          value={otpNo3}
                          onChange={(value) =>
                            isNumber(value) && setOTPNo3(value)
                          }
                        />
                        <RawInput
                          className="w-full h-full border text-xl text-center form-control rounded p-1"
                          containerClass="m-2 h-16 w-15"
                          type="text"
                          name="otpNo4"
                          maxlength="1"
                          autoComplete="off"
                          value={otpNo4}
                          onChange={(value) =>
                            isNumber(value) && setOTPNo4(value)
                          }
                        />
                        <RawInput
                          className="w-full h-full border text-xl text-center form-control rounded p-1"
                          containerClass="m-2 h-16 w-15"
                          type="text"
                          name="otpNo5"
                          maxlength="1"
                          autoComplete="off"
                          value={otpNo5}
                          onChange={(value) =>
                            isNumber(value) && setOTPNo5(value)
                          }
                        />
                        <RawInput
                          className="w-full h-full border text-xl text-center form-control rounded p-1"
                          containerClass="m-2 h-16 w-15"
                          type="text"
                          name="otpNo6"
                          maxlength="1"
                          autoComplete="off"
                          value={otpNo6}
                          onChange={(value) =>
                            isNumber(value) && setOTPNo6(value)
                          }
                        />
                      </Div>

                      <Spacer className="h-4" />

                      <FlexRow className="text-xs italic text-center justify-center desktop:text-sm w-full">
                        <Span className="text-secondary-200 italic mr-2">
                          Didn't receive code?
                        </Span>
                        <Text
                          className="text-red-400 font-bold cursor-pointer"
                          onClick={onSendOTP}
                        >
                          Resend
                        </Text>
                      </FlexRow>
                    </Div>
                  </form>
                </Div>
              </Div>
            </>
          )}

          {activeSection === 1 && (
            <>
              <FlexRow className="flex-row justify-center items-center w-full">
                <svg
                  className="w-4 h-4 text-gray-800 dark:text-white cursor-pointer"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 10"
                  onClick={() => navigate(`/`)}
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M13 5H1m0 0 4 4M1 5l4-4"
                  />
                </svg>
                <Text className="text-secondary-100 text-lg font-bold text-center desktop:text-base ml-3">
                  Create New Password
                </Text>
              </FlexRow>

              <Spacer className="h-5" />

              <Div className="mx-auto flex items-center justify-center w-full max-w-md flex-col">
                <Div className="flex flex-col items-center justify-center text-center space-y-2">
                  <Div className="flex flex-row text-sm font-medium text-gray-400 text-grey-1000">
                    <p>Set your new password</p>
                  </Div>
                </Div>

                <Spacer className="h-5" />

                <Div>
                  <form
                    className="flex flex-col items-center justify-center"
                    onSubmit={(e) => e.preventDefault()}
                  >
                    <Div className="relative w-300px">
                      <div
                        className="absolute inset-y-0 right-0 flex items-center px-3 h-10 cursor-pointer"
                        onClick={() => setShowNewPass(!showNewPass)}
                      >
                        {showNewPass ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-5 h-5"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-5 h-5"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                            />
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                          </svg>
                        )}
                      </div>
                      <RawInput
                        className="text-secondary-200"
                        containerClass="w-full"
                        name="newPassword"
                        placeholder="Password"
                        onChange={setNewPassword}
                        type={showNewPass ? "text" : "password"}
                        value={newPassword}
                        maxlength={20}
                        validation={newPasswordErr}
                      />
                    </Div>

                    <Spacer className="h-4" />

                    <Div className="flex flex-row text-sm font-medium text-gray-400 text-grey-1000 text-left w-300px">
                      <p>
                        Password must be at least 8 characters, has uppercase
                        and lowercase letters, contains a number and a special
                        character.
                      </p>
                    </Div>

                    <Spacer className="h-4" />

                    <Div className="relative w-300px">
                      <div
                        className="absolute inset-y-0 right-0 flex items-center px-3 h-10 cursor-pointer"
                        onClick={() =>
                          setConfirmShowNewPass(!showConfirmNewPass)
                        }
                      >
                        {showConfirmNewPass ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-5 h-5"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-5 h-5"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                            />
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                          </svg>
                        )}
                      </div>
                      <RawInput
                        className="text-secondary-200"
                        containerClass="w-full"
                        name="confirmNewPassword"
                        placeholder="Confirm Password"
                        onChange={setConfirmNewPassword}
                        type={showConfirmNewPass ? "text" : "password"}
                        value={confirmNewPassword}
                        maxlength={20}
                        validation={confirmNewPasswordErr}
                      />
                    </Div>

                    <Spacer className="h-5" />

                    <Button
                      className="w-36 ml-2 h-10"
                      type="button"
                      onClick={onSubmitChangePass}
                    >
                      Submit
                    </Button>
                  </form>
                </Div>
              </Div>
            </>
          )}

          {activeSection === 2 && (
            <Div className="w-250px">
              <FlexRow className="flex-col justify-center items-center w-full">
                <HiCheckCircle className="text-green-100 text-4xl h-20 w-20" />
                <Text className="text-secondary-100 text-lg font-bold text-center desktop:text-base ml-3">
                  You've successfully set your new password!
                </Text>
              </FlexRow>

              <Spacer className="h-5" />

              <Div className="mx-auto flex items-center justify-center w-full max-w-md flex-col">
                <Div className="flex flex-col items-center justify-center text-center space-y-2">
                  <Div className="flex flex-row text-sm font-medium text-gray-400 text-grey-1000">
                    <p>You may now login with your new password.</p>
                  </Div>
                </Div>

                <Spacer className="h-10" />

                <Div className="flex flex-col items-center justify-center">
                  <Button
                    className="w-36 ml-2 h-10"
                    type="button"
                    onClick={() => navigate("/")}
                  >
                    Login
                  </Button>
                </Div>
              </Div>
            </Div>
          )}
        </FlexColumn>
      </FlexRow>
    </>
  );
};
