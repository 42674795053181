import React from "react";
import { Cookies } from "react-cookie";
import { Div, Divider, FlexColumn, FlexRow } from "../../core/Containers";
import { NavbarMain } from "../../components/Navigation/NavbarMain";
import { TopNavBar } from "../../components/Navigation/TopNavBar";
import { useLocation, useNavigate } from "react-router-dom";
import { Tab } from "@headlessui/react";
import { classNames } from "../../helpers/ClassNames";
import {
  StatusReport,
  ProofOfDelivery,
  SuppliesMonitoring,
  DeliveryReport,
  // RemittanceReport,
  Remittance,
  RemittanceHistoryReport,
} from "./ReportsTabs";

const cookies = new Cookies();

export const Reports = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const actionQuery = query.get("option");

  const [selectedIndex, setSelectedIndex] = React.useState(actionQuery || 0);
  const [reportType, setReportType] = React.useState(
    query.get("option")
      ? query.get("option") === 1
        ? "Delivery Report"
        : query.get("option") === 2
        ? "Remittance Report"
        : ""
      : ""
  );
  const [filter, setFilter] = React.useState(
    query.get("filter") ? query.get("filter").split(",") : []
  );
  const [dateFrom, setDateFrom] = React.useState(query.get("dateFrom") || "");
  const [dateTo, setDateTo] = React.useState(query.get("dateTo") || "");

  const tabs = [
    {
      id: "reportsStatReport",
      name: "Status Report",
    },
    {
      id: "reportsdelReport",
      name: "Delivery Report",
    },
    {
      id: "reportsRemitReport",
      name: "Remittance Report",
    },
    {
      id: "reportsPOD",
      name: "Proof of Delivery",
    },
    {
      id: "reportsRemitHistory",
      name: "Remittance History",
    },
  ];

  const userInfo = cookies.get("userInfo", { path: "/" });

  React.useEffect(() => {
    if (actionQuery === "0") {
      setReportType("Status Report");
      setSelectedIndex(0);
      navigate("/reports");
    } else if (actionQuery === "1") {
      setReportType("Delivery Report");
      setSelectedIndex(1);
      navigate("/reports");
    } else if (actionQuery === "2") {
      setReportType("Remittance Report");
      setSelectedIndex(2);
      navigate("/reports");
    }
  }, [actionQuery, navigate]);

  return (
    <FlexRow className="w-full h-full items-center justify-between">
      {/* Side Navigation */}
      <NavbarMain route="Reports" />

      {/* Main Dashboard */}
      <FlexColumn className="w-11/12 h-full">
        {/* Top Navigation */}
        <TopNavBar showMenu={true} showNotifBell={true} showSearchBar={true} />

        {/* Body */}
        <Div className="w-full h-full overflow-y-auto p-10 bg-white">
          <FlexRow className="w-full">
            <Tab.Group
              selectedIndex={selectedIndex}
              onChange={setSelectedIndex}
            >
              <FlexColumn className="w-full">
                <Tab.List className="tabletWide:space-x-4">
                  {tabs.map(
                    (tab, i) =>
                      userInfo?.accessModules?.includes(tab.id) && (
                        <Tab
                          className={({ selected }) =>
                            classNames(
                              "p-2 font-semibold w-auto focus:border-b-4 focus:border-orange-400 ring:border-orange-400 outline-none",
                              selected
                                ? "text-orange-400 border-b-4 border-orange-400"
                                : "text-secondary-200  hover:text-secondary-100"
                            )
                          }
                          key={i}
                        >
                          {tab.name}
                        </Tab>
                      )
                  )}
                </Tab.List>
                <Divider className="text-grey-400" />
                <Tab.Panels className="w-full">
                  <Tab.Panel className={`w-full`}>
                    {selectedIndex === 0 && <StatusReport />}
                  </Tab.Panel>
                  {/* <Tab.Panel className={`w-full`}>
                    <ProofOfDelivery />
                  </Tab.Panel>
                  <Tab.Panel className={`w-full`}>
                    <SuppliesMonitoring />
                  </Tab.Panel> */}
                  <Tab.Panel className={`w-full`}>
                    {selectedIndex === 1 && (
                      <DeliveryReport
                        reportFilter={
                          reportType === "Delivery Report" ? filter : []
                        }
                        reportDateFrom={
                          reportType === "Delivery Report" ? dateFrom : ""
                        }
                        reportDateTo={
                          reportType === "Delivery Report" ? dateTo : ""
                        }
                      />
                    )}
                  </Tab.Panel>
                  <Tab.Panel className={`w-full`}>
                    {selectedIndex === 2 && (
                      <Remittance
                        reportFilter={
                          reportType === "Remittance Report" ? filter : []
                        }
                        reportDateFrom={
                          reportType === "Remittance Report" ? dateFrom : ""
                        }
                        reportDateTo={
                          reportType === "Remittance Report" ? dateTo : ""
                        }
                      />
                    )}
                  </Tab.Panel>
                  <Tab.Panel className={`w-full`}>
                    {selectedIndex === 3 && (
                      <ProofOfDelivery
                        reportFilter={
                          reportType === "Proof of Delivery" ? filter : []
                        }
                        reportDateFrom={
                          reportType === "Proof of Delivery" ? dateFrom : ""
                        }
                        reportDateTo={
                          reportType === "Proof of Delivery" ? dateTo : ""
                        }
                      />
                    )}
                  </Tab.Panel>
                  <Tab.Panel className={`w-full`}>
                    {selectedIndex === 4 && <RemittanceHistoryReport />}
                  </Tab.Panel>
                </Tab.Panels>
              </FlexColumn>
            </Tab.Group>
          </FlexRow>
        </Div>
      </FlexColumn>
    </FlexRow>
  );
};
